/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from './Utils'
import { Config } from 'evr'

export default function Pointer(settings) {
  let _canvas = Utils.$({element: 'canvas'}),
    _ctx = _canvas.getContext('2d'),
    _texture,
    _material,
    _geometry,
    _size = 64,
    _ratio = 8,
    _mesh,
    _meshContainer = new THREE.Group(),
    _that = this,
    _config = {
      animationTime: 800,
      renderOrder: 70
    };

  // Private methods

  function _remove() {
    settings.scene.remove(_meshContainer);
    _geometry.dispose();
    _texture.dispose();
    _material.dispose();
    _canvas.width = 0;
    _canvas.height = 0;
    _canvas = null;
  }

  function _updateCanvas(progress) {
    var cx, cy,
      innerRadius,
      outerRadius,
      size = _size * _ratio;

    if (!progress) {
      progress = 0;
    }

    cx = cy = size / 2;
    outerRadius = Math.round(size / 2 * progress);
    innerRadius = Math.round(size / (3 - progress) * progress);

    _ctx.clearRect(0, 0, size, size);
    _ctx.beginPath();
    _ctx.arc(cx, cx, outerRadius, 0, 2 * Math.PI * 2, false);
    _ctx.arc(cy, cy, innerRadius, 0, Math.PI * 2, true);
    _ctx.fillStyle = 'rgba(255, 255, 255,' + (1 - progress) + ')';
    _ctx.fill();
  }

  // Initialize

  _canvas.width = _canvas.height = _size * _ratio;
  _updateCanvas();
  _geometry = new THREE.PlaneGeometry(_size, _size, 60, 60);
  _texture = new THREE.Texture(_canvas);
  _texture.needsUpdate = true;
  _material = new THREE.MeshBasicMaterial({
    map: _texture,
    transparent: true,
    opacity: 1,
    depthTest: false,
    depthWrite: false,
  });

  _mesh = new THREE.Mesh(_geometry, _material);
  _mesh.name = 'Pointer';
  _mesh.renderOrder = Config.player.renderOrder.pointer;
  _mesh.position.set(0, 0, -90);
  _mesh.lookAt(new THREE.Vector3(0, 0, 0));
  _meshContainer.add(_mesh);
  _meshContainer.name = 'PointerContainer';

  //_meshContainer.setRotationFromEuler(new THREE.Euler(-rotation.x, rotation.y, rotation.z));
  _meshContainer.setRotationFromQuaternion(new THREE.Quaternion(0, 0, 0, 1));
  _meshContainer.rotateY(settings.rotation.y);
  _meshContainer.rotateX(-settings.rotation.x);
  _meshContainer.rotateZ(settings.rotation.z);

  _meshContainer.updateMatrixWorld(true);
  // _meshContainer.rotation.set(settings.rotation.z, settings.rotation.y, 0);

  settings.scene.add(_meshContainer);

  // Public methods

  this.animate = function () {
    var progress = 0;
    return function (delta) {
      progress += delta;
      if (progress >= _config.animationTime) {
        _remove();
        _that.animate = undefined;
      } else {
        _updateCanvas(Utils.easeInOutCubic(+(progress / _config.animationTime).toFixed(2)));
        _texture.needsUpdate = true;
      }
    }
  }();
  this.remove = _remove;
}

