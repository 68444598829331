/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import VRDisplay from './VRDisplay';
import { debounce } from 'lodash';

// How much to rotate per key stroke.
const KEY_SPEED = 0.15,
  KEY_ANIMATION_DURATION = 80,
  // How much to rotate for mouse events.
  MOUSE_SPEED_X = 0.5,
  MOUSE_SPEED_Y = 0.3,
  INERTIA_SPEED_RATE = 0.005,
  INERTIA_SLOW_RATE = 1.15;

const setFromEulerYXZ = (x, y, z) => {
  let c1 = Math.cos(x / 2),
    c2 = Math.cos(y / 2),
    c3 = Math.cos(z / 2),
    s1 = Math.sin(x / 2),
    s2 = Math.sin(y / 2),
    s3 = Math.sin(z / 2),
    x2 = s1 * c2 * c3 + c1 * s2 * s3,
    y2 = c1 * s2 * c3 - s1 * c2 * s3,
    z2 = c1 * c2 * s3 - s1 * s2 * c3,
    w2 = c1 * c2 * c3 + s1 * s2 * s3;

  return new THREE.Quaternion(x2,y2,z2,w2);
};

/**
 * VRDisplay based on mouse and keyboard input. Designed for desktops/laptops
 * where orientation events aren't supported. Cannot present.
 */
export default class MouseKeyboardVRDisplay extends VRDisplay {
  constructor(element) {
    super();

    this.displayName = 'Mouse and Keyboard VRDisplay (webvr-polyfill)';
    this.controlsEnabled = true;

    this.capabilities.hasOrientation = true;

    if (!element) {
      element = window;
    }

    element.addEventListener('keydown', this.onKeyDown_.bind(this));
    // const delay = 50;
    // this.lazyOnMouseMove = debounce(this.onMouseMove_.bind(this), delay, { leading: true });
    //element.addEventListener('mousemove', this.lazyOnMouseMove);
    element.addEventListener('mousemove', this.onMouseMove_.bind(this));
    element.addEventListener('mouseleave', this.onMouseUp_.bind(this));
    element.addEventListener('mousedown', this.onMouseDown_.bind(this));
    element.addEventListener('mouseup', this.onMouseUp_.bind(this));

    element.addEventListener('touchstart', this.onTouchStart_.bind(this));
    element.addEventListener('touchmove', this.onTouchMove_.bind(this));
    element.addEventListener('touchend', this.onTouchEnd_.bind(this));

    // prevent browser back/forward trackpad gestures, when dragging sphere on MacOS
    element.addEventListener('mousewheel', event => {
      if (event.deltaX !== 0) {
        event.preventDefault();
      }
    }, { passive: false });

    // "Private" members.
    this.phi_ = 0;
    this.theta_ = 0;

    // Variables for keyboard-based rotation animation.
    this.targetAngle_ = null;
    this.angleAnimation_ = null;

    // State variables for calculations.
    this.orientation_ = new THREE.Quaternion();

    // Variables for mouse-based rotation.
    this.rotateStart_ = new THREE.Vector2();
    this.rotateEnd_ = new THREE.Vector2();
    this.rotateDelta_ = new THREE.Vector2();
    this.isMouseDown_ = false;
    this.isDragging_ = false;

    this.orientationOut_ = new Float32Array(4);
  }
  disable() {
    this.controlsEnabled = false;
  }

  enable() {
    this.controlsEnabled = true;
  }

  getImmediatePose() {
    this.orientation_ = setFromEulerYXZ(this.phi_, this.theta_, 0);

    this.orientationOut_[0] = this.orientation_.x;
    this.orientationOut_[1] = this.orientation_.y;
    this.orientationOut_[2] = this.orientation_.z;
    this.orientationOut_[3] = this.orientation_.w;

    return {
      position: null,
      orientation: this.orientationOut_,
      linearVelocity: null,
      linearAcceleration: null,
      angularVelocity: null,
      angularAcceleration: null
    };
  }

  onKeyDown_(e) {
    if (this.controlsEnabled) {
      // Track WASD and arrow keys.
      if (e.keyCode == 38) { // Up key.
        this.animatePhi_(this.phi_ + KEY_SPEED);
      } else if (e.keyCode == 39) { // Right key.
        this.animateTheta_(this.theta_ - KEY_SPEED);
      } else if (e.keyCode == 40) { // Down key.
        this.animatePhi_(this.phi_ - KEY_SPEED);
      } else if (e.keyCode == 37) { // Left key.
        this.animateTheta_(this.theta_ + KEY_SPEED);
      }
    }
  }

  animateTheta_(targetAngle) {
    this.animateKeyTransitions_('theta_', targetAngle);
  }

  animatePhi_(targetAngle) {
    this.animateKeyTransitions_('phi_', targetAngle);
  }

  /**
   * Start an animation to transition an angle from one value to another.
   */
  animateKeyTransitions_(angleName, targetAngle) {
    // If an animation is currently running, cancel it.
    if (this.angleAnimation_) {
      clearInterval(this.angleAnimation_);
    }
    var startAngle = this[angleName];
    var startTime = new Date();
    // Set up an interval timer to perform the animation.
    this.angleAnimation_ = setInterval(function () {
      // Once we're finished the animation, we're done.
      var elapsed = new Date() - startTime;
      if (elapsed >= KEY_ANIMATION_DURATION) {
        this[angleName] = targetAngle;
        clearInterval(this.angleAnimation_);
        return;
      }
      // Linearly interpolate the angle some amount.
      var percent = elapsed / KEY_ANIMATION_DURATION;
      const angle = startAngle + (targetAngle - startAngle) * percent;

      if (angleName === 'phi_') {
        this.setPhi_(angle);
      } else {
        this[angleName] = angle;
      }
    }.bind(this), 1000 / 60);
  }

  onMouseDown_(e) {
    if (this.controlsEnabled) {
      this.rotateStart_.set(e.clientX, e.clientY);
      this.isMouseDown_ = true;
    }
  }

  // Very similar to https://gist.github.com/mrflix/8351020
  onMouseMove_(e) {
    if (this.controlsEnabled) {
      if (!this.isMouseDown_ && !this.isPointerLocked_()) {
        return;
      }
      this.isDragging_ = true;
      // Support pointer lock API.
      if (this.isPointerLocked_()) {
        var movementX = e.movementX || e.mozMovementX || 0;
        var movementY = e.movementY || e.mozMovementY || 0;
        this.rotateEnd_.set(this.rotateStart_.x - movementX, this.rotateStart_.y - movementY);
      } else {
        this.rotateEnd_.set(e.clientX, e.clientY);
      }
      // Calculate how much we moved in mouse space.
      this.rotateDelta_.subVectors(this.rotateEnd_, this.rotateStart_);
      this.rotateStart_.copy(this.rotateEnd_);

      // Keep track of the cumulative euler angles.
      this.setPhi_(this.phi_ + 2 * Math.PI * this.rotateDelta_.y / screen.height * MOUSE_SPEED_Y);
      this.theta_ += 1.25 * Math.PI * this.rotateDelta_.x / screen.width * MOUSE_SPEED_X;
    }
  }

  onMouseUp_() {
    if (this.controlsEnabled) {
      if (this.isDragging_) {
        this.startInertia_();
      }

      this.isMouseDown_ = false;
      this.isDragging_ = false;
    }
  }

  onTouchStart_(event) {
    const [{ clientX, clientY }] = event.touches;

    if (event.cancelable) {
      event.preventDefault();
    }

    this.onMouseDown_({ clientX, clientY });

    return false;
  }

  onTouchMove_(event) {
    const [{ clientX, clientY }] = event.touches;
    const { timeStamp } = event;

    if (event.cancelable) {
      event.preventDefault();
    }

    this.onMouseMove_({ clientX, clientY, timeStamp });

    return false;
  }

  onTouchEnd_(event) {
    if (event.cancelable) {
      event.preventDefault();
    }

    this.onMouseUp_();

    return false;
  }

  rotateCamera(rotation) {
    this.phi_ = -rotation.x;
    this.theta_ = rotation.y;
  }

  isPointerLocked_() {
    var el = document.pointerLockElement || document.mozPointerLockElement ||
      document.webkitPointerLockElement;
    return el !== undefined;
  }

  setPhi_(targetAngle) {
    // Prevent looking too far up or down.
    this.phi_ = THREE.Math.clamp(targetAngle, -Math.PI / 2, Math.PI / 2);
  }

  startInertia_() {
    window.cancelAnimationFrame(this.interiaAnimationFrameId_);

    if (this.rotateDelta_.x === 0 && this.rotateDelta_.y === 0) {
      return;
    }

    const inertiaXStartSpeed = this.rotateDelta_.x * INERTIA_SPEED_RATE;
    const inertiaYStartSpeed = this.rotateDelta_.y * INERTIA_SPEED_RATE;

    let inertiaXSpeed = inertiaXStartSpeed;
    let inertiaYSpeed = inertiaYStartSpeed;

    const inert = () => {
      if (Math.abs(inertiaXSpeed) <= 0.0001) {
        this.stopInertia_();
        return;
      }


      this.theta_ = this.theta_ + inertiaXSpeed;
      this.setPhi_(this.phi_ + inertiaYSpeed);

      inertiaXSpeed /= INERTIA_SLOW_RATE;
      inertiaYSpeed /= INERTIA_SLOW_RATE;

      this.interiaAnimationFrameId_ = window.requestAnimationFrame(inert);
    };

    inert();
  }

  stopInertia_() {
    window.cancelAnimationFrame(this.interiaAnimationFrameId_);
    this.rotateDelta_.set(0, 0);
  }
}

