/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget'
import { Config } from 'evr'

export default class TransitionWidget extends Widget {
  constructor(props) {
    super(props);

    this.animated = true;
    this.renderOrder = Config.player.renderOrder.transition + props.getRenderOrderInc();

    this._state.isReady = Promise.resolve();
    this.data = props.data;

  }
  intersectObject(options) {
    const { action, ...intersection } = Widget.prototype.intersectObject.call(this, options);
    return intersection;
  }
  animate(options) {
    this.setIconOpacity();
    if (this._state.initialized) {
      if (this._state.opening || this._state.closing) {
        let delta = options.delta,
          progress = this._state.animationProgress || 0,
          totalAnimationTime = Config.player.widgets.animationTime,
          normalizedProgress;

        if (this._state.opening) {
          progress += delta;
        } else {
          progress -= delta;
        }

        progress = (progress < 0 ? 0 : (progress > totalAnimationTime ? totalAnimationTime : progress));
        normalizedProgress = (+(progress / totalAnimationTime)).toFixed(2);

        if (normalizedProgress >= 1) {
          this._state.closed = false;
          this._state.opening = false;
          this._state.opened = true;
          progress = totalAnimationTime;
        } else if (normalizedProgress <= 0) {
          this._state.closed = true;
          this._state.opened = false;
          this._state.closing = false;
          progress = 0;
        }

        this._icon.drawIcon(normalizedProgress);
        this._state.animationProgress = progress;

        if (normalizedProgress >= 1) {
          const { action } = this._data;
          const { destination } = this._data.details;
          if (action) {
            this.runAction(action);
            this.close();
          } else if (destination) {
            if (destination.sceneId) {
              this.runAction({
                type: 'changeScene',
                ...destination,
              });
            } else if (destination.projectHash) {
              this.runAction({
                type: 'openProject',
                ...destination,
              });
            }
          }
        }
      }
    }
  }
  setIconOpacity() {
    if (this.data.details.alwaysHighlighted) {
      this._icon.$.getObjectByName('WidgetIconBackground').material.opacity = .5;
    }
  }
}

