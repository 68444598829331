/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';
import VideoObject from './VideoObject';
import Link from './Link';

function truncateColor(value) {
  if (value < 0) {
    value = 0;
  } else if (value > 255) {
    value = 255;
  }

  return value;
}

function useShadersOnImgData(data, config) {
  const { brightness = 0, contrast = 1, gamma = 1, saturation = 1 } = config;
  const luminance = [0.3086, 0.6094, 0.082];
  for (var i = 0; i < data.length; i+= 4) {
    const red = truncateColor(((Math.pow(data[i]/255, gamma) * 255) + 255 * brightness - 128) * contrast + 128);
    const green = truncateColor(((Math.pow(data[i+1]/255, gamma) * 255) + 255 * brightness - 128) * contrast + 128);
    const blue = truncateColor(((Math.pow(data[i+2]/255, gamma) * 255) + 255 * brightness - 128) * contrast + 128);

    data[i] = truncateColor((red * (luminance[0] * (1 - saturation) + saturation)) +
      (green * luminance[1] * (1 - saturation)) +
      (blue * luminance[2] * (1 - saturation)));
    data[i+1] = truncateColor((red * luminance[0] * (1 - saturation)) +
      (green * (luminance[1] * (1 - saturation) + saturation)) +
      (blue * luminance[2] * (1 - saturation)));
    data[i+2] = truncateColor((red * luminance[0] * (1 - saturation)) +
      (green * luminance[1] * (1 - saturation)) +
      (blue * (luminance[2] * (1 - saturation) + saturation)));
  }
}

export default class View extends EventsEmitter {
  constructor(props) {
    super(props);

    this._disposable = [];

    let style = Utils.extend({
      font: Config.player.style.font,
      fontSize: Config.player.style.fontSize,
      margin: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        betweenItems: 0
      },
      borderRadius: 0,
    },(props.style || {}));

    this._scaleFactor = Config.player.scaleFactor.view;

    this.$l = new Logger('View');
    this._canvas = Utils.$({element: 'canvas'});
    this._ctx = this._canvas.getContext('2d');
    this._fontFamily = style.font || Config.player.style.font;
    this._fontSize = (style.fontSize || Config.player.style.fontSize);
    this._videoProgress = -1; // -1 if it wasn't draw, 0-1 progress of the loader

    style.margin.top *= this._scaleFactor;
    style.margin.right *= this._scaleFactor;
    style.margin.left *= this._scaleFactor;
    style.margin.bottom *= this._scaleFactor;
    style.margin.betweenItems *= this._scaleFactor;
    style.borderRadius *= this._scaleFactor;

    //Prepare for drawning
    let drawText = (text, fontSize, y, color) => {
        let params = [],
          underlined,
          c = color || style.color || '#ffffff',
          fSize = (fontSize || this._fontSize) * this._scaleFactor,
          lineHeight;

        fSize *= Config.player.ultimateMagicScaleFactor;

        lineHeight = fSize * 1.2;

        this._ctx.textBaseline = "top";
        this._ctx.textAlign = style.alignement || 'center';

        if (style.decorations && style.decorations.indexOf('italic') > -1) {
          params.push('italic');
        }
        if (style.decorations && style.decorations.indexOf('bold') > -1) {
          params.push('bold');
        }
        underlined = !!(style.decorations && style.decorations.indexOf('underlined') > -1);

        params.push(fSize + 'px');
        params.push(this._fontFamily);
        this._ctx.font = params.join(' ');

        var x = 0;
        switch (this._ctx.textAlign) {
        case 'center':
          x = this._canvas.width / 2;
          break;
        case 'left':
          x = style.margin.left;
          break;
        case 'right':
          x = this._canvas.width - style.margin.right;
          break;
        }

        return Utils.Canvas.wrapText({
          context: this._ctx,
          text: text,
          color: c,
          x: x,
          y: y,
          align: this._ctx.textAlign,
          maxWidth: this._canvas.width - style.margin.right - style.margin.left,
          lineHeight: lineHeight,
          underlined: underlined
        });
      },
      calcHeightAndDraw = () => {
        let height = style.margin.top;

        if (props.elements) {
          for(let i = 0; i < props.elements.length; i++) {
            let el = props.elements[i];

            if (el.type === 'text') {
              height += drawText(
                el.text,
                el.fontSize,
                height,
                el.color || style.color
              );
            } else if (el.type === 'image') {
              let imageWidth = this._canvas.width - style.margin.right - style.margin.left,
                imageHeight,
                ratio = el.ratio || (el.width / (el.height || 1));

              imageHeight = imageWidth / ratio;

              this._ctx.drawImage(el.image, 0, 0, el.image.width, el.image.height, style.margin.left, height, imageWidth, imageHeight);
              if (el.correction) {
                let imageData = this._ctx.getImageData(style.margin.left, Math.round(height), Math.round(imageWidth), Math.round(imageHeight));
                useShadersOnImgData(imageData.data, el.correction);
                this._ctx.putImageData(imageData, style.margin.left, Math.round(height));
              }
              height += imageHeight;
            } else if (el.type === 'video') {
              const videoRatio = el.ratio || (el.width / (el.height || 1)),
                videoWidth = this._canvas.width - style.margin.right - style.margin.left,
                videoHeight = videoWidth / videoRatio;

              this.startVideoFromHeight = height;

              height += videoHeight + 4;
            } else if (el.type === 'link') {
              // Don't create link twice
              if (this._link) {
                this._link.draw();
              } else {
                this._link = new Link({
                  canvas: this._canvas,
                  y: height,
                  right: style.margin.right,
                  text: el.text,
                  url: el.url,
                  font: this._fontFamily,
                  fontSize: this._fontSize,
                  color: el.color,
                  secondaryColor: el.secondaryColor,
                  backgroundColor: style.background,
                });
              }
              height += this._link.height;
            } else {
              this.$l.error('Unknown element type: ' + el.type);
            }

            height += style.margin.betweenItems;
          }
        }

        //Remove last item style.margin
        height -= style.margin.betweenItems;

        height += style.margin.bottom;

        return height;
      },
      toPowerOfTwoTextureSize = (value) => {
        var power = Math.log(value) / Math.log(2);
        return Math.max(1,
          Math.min(Math.pow(2, Math.round(power + 0.2)),
            4096));
      },
      nearestPowerOfTwo = ( value ) => {
        return Math.pow( 2, Math.round( Math.log( value ) / Math.LN2 ) );
      };

    let width = Math.max(props.width || 2000, 1) * Config.player.ultimateMagicScaleFactor;
    this._width = width * 0.2 * this._scaleFactor + style.margin.left + style.margin.right;
    //this._width = nearestPowerOfTwo(this._width);
    this._canvas.width = this._width;
    let height = calcHeightAndDraw();
    this._height = Math.max(height, 1);
    //this._height = nearestPowerOfTwo(this._height);
    this._canvas.height = this._height;
    // this._ctx.scale(this._canvas.width / this._width, this._canvas.height / this._height);

    // Draw elements
    if (style.background) {
      this._ctx.fillStyle = style.background;
      Utils.Canvas.roundedPath(
        this._ctx,
        0, // x
        0, // y
        this._canvas.width, // width
        this._canvas.height, // height
        style.borderRadius, // r
      );
      this._ctx.fill();
    }

    calcHeightAndDraw();

    this._geometry = new THREE.PlaneGeometry(this._width / this._scaleFactor, this._height / this._scaleFactor);
    this._texture = new THREE.Texture(this._canvas);
    this._material = new THREE.MeshBasicMaterial({
      map: this._texture,
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false,
      side: THREE.DoubleSide,
    });
    this._disposable.push(this._geometry);
    this._disposable.push(this._texture);
    this._disposable.push(this._material);

    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.name = "View";
    this._texture.needsUpdate = true;

    const videoElement = props.elements.find(el => el.type === 'video');

    if (videoElement) {
      const videoRatio = videoElement.ratio || (videoElement.width / (videoElement.height || 1)),
        videoWidth = this._canvas.width - style.margin.right - style.margin.left,
        videoHeight = videoWidth / videoRatio;

      this.videoObject = new VideoObject(videoElement.video, videoWidth / this._scaleFactor, videoHeight / this._scaleFactor, videoElement.color);

      const videoYOffset = -((videoHeight / 2) - (this._height / 2) + this.startVideoFromHeight) / this._scaleFactor;
      this.videoObject.$.position.set(0, videoYOffset, 0);

      this._mesh.add(this.videoObject.$);
    }
    if (this._link) {
      let x = (this._width / 2 - this._link.width / 2 - style.margin.right) / this._scaleFactor;
      let y = -(this._height / 2 - this._link.height / 2 - style.margin.bottom) / this._scaleFactor;
      this._link.$.position.set(x, y, 0);
      this._mesh.add(this._link.$);
    }
  }
  stopVideo () {
    if (!this.videoObject) return;
    this.videoObject.stop();
  }
  drawLink (progress) {
    if (this._link) {
      this._link.draw(progress);
      this._texture.needsUpdate = true;
    }
  }
  remove() {
    while (this._disposable.length) {
      let el = this._disposable.shift();

      el.dispose();
    }

    if (this.videoObject) {
      this.videoObject.remove();
      delete this.videoObject;
    }
    if (this._link) {
      this._link.remove();
    }

    this._canvas.width = 0;
    this._canvas.height = 0;
    delete this._canvas;
  }
}

