/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import { Config } from 'evr';
import EventsEmitter from './../Utils/EventsEmitter';
import Utils from '../Utils/Utils';
import './style.scss';

export default class FullscreenVideo extends EventsEmitter {
  constructor({src, currentTime, onClose}) {
    super();

    this.containerParent = document.body;

    this.$ = Utils.$({
      cssClass: 'evr-fullscreenVideo'
    });
    this.containerParent.appendChild(this.$);

    this.$video = Utils.$({
      element: 'video',
      cssClass: 'evr-video'
    });
    this.$video.src = src;
    this.$video.autoplay = true;
    this.$video.controls = true;
    this.$video.currentTime = currentTime || 0;
    this.$.appendChild(this.$video);

    this.$close = Utils.$({
      element: 'img',
      cssClass: 'evr-videoClose'
    });
    this.$close.src = `${Config.baseUrl}${Config.context}/icons/x.png`;
    this.$close.addEventListener('click', () => {
      this.$video.pause();
      if (onClose) {
        onClose(this.$video.currentTime);
      } else {
        this.remove();
      }
    });
    this.$.appendChild(this.$close);
  }
  remove() {
    this.containerParent.removeChild(this.$);
  }
}

