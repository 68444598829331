/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

const Config = __CONFIG__;
const DefaultProject = {
  initialState: {},
  resources: {
    // Icons
    "arrow-bottom": {id: "arrow-bottom", type: "@ContentIcon", name: "arrow-bottom", path: 'arrow-bottom.png'},
    "arrow-bottom-left": {id: "arrow-bottom-left", type: "@ContentIcon", name: 'arrow-bottom-left', path: 'arrow-bottom-left.png'},
    "arrow-bottom-right": {id: "arrow-bottom-right", type: "@ContentIcon", name: 'arrow-bottom-right', path: 'arrow-bottom-right.png'},
    "arrow-circle-left": {id: "arrow-circle-left", type: "@ContentIcon", name: 'arrow-circle-left', path: 'arrow-circle-left.png'},
    "arrow-circle-right": {id: "arrow-circle-right", type: "@ContentIcon", name: 'arrow-circle-right', path: 'arrow-circle-right.png'},
    "arrow-left":  {id: "arrow-left", type: "@ContentIcon", name: 'arrow-left', path: 'arrow-left.png'},
    "arrow-right": {id: "arrow-right", type: "@ContentIcon", name: 'arrow-right', path: 'arrow-right.png'},
    "arrow-top": {id: "arrow-top", type: "@ContentIcon", name: 'arrow-top', path: 'arrow-top.png'},
    "arrow-top-left": {id: "arrow-top-left", type: "@ContentIcon", name: 'arrow-top-left', path: 'arrow-top-left.png'},
    "arrow-top-right": {id: "arrow-top-right", type: "@ContentIcon", name: 'arrow-top-right', path: 'arrow-top-right.png'},
    "audio": {id: "audio", type: "@ContentIcon", name: 'audio', path: 'audio.png'},
    "audio-text": {id: "audio-text", type: "@ContentIcon", name: 'audio-text', path: 'audio-text.png'},
    "blank":  {id: "blank", type: "@ContentIcon", name: 'blank', path: 'blank.png'},
    "eye":  {id: "eye", type: "@ContentIcon", name: 'eye', path: 'eye.png'},
    "floorplan": {id: "floorplan", type: "@ContentIcon", name: 'floorplan', path: 'floorplan.png'},
    "image-text": {id: "image-text", type: "@ContentIcon", name: 'image-text', path: 'image-text.png'},
    "landscape": {id: "landscape", type: "@ContentIcon", name: 'landscape', path: 'landscape.png'},
    "panorama": {id: "panorama", type: "@ContentIcon", name: 'panorama', path: 'panorama.png'},
    "plus": {id: "plus", type: "@ContentIcon", name: 'plus', path: 'plus.png'},
    "ruler": {id: "ruler", type: "@ContentIcon", name: 'ruler', path: 'ruler.png'},
    "size": {id: "size", type: "@ContentIcon", name: 'size', path: 'size.png'},
    "text": {id: "text", type: "@ContentIcon", name: 'text', path: 'text.png'},
    "x": {id: "x", type: "@ContentIcon", name: 'x', path: 'x.png'},
    "num-1": {id: "num-1", type: "@ContentIcon", name: 'num-1', path: 'num-1.png'},
    "num-2": {id: "num-2", type: "@ContentIcon", name: 'num-2', path: 'num-2.png'},
    "num-3": {id: "num-3", type: "@ContentIcon", name: 'num-3', path: 'num-3.png'},
    "num-4": {id: "num-4", type: "@ContentIcon", name: 'num-4', path: 'num-4.png'},
    "num-5": {id: "num-5", type: "@ContentIcon", name: 'num-5', path: 'num-5.png'},
    "num-6": {id: "num-6", type: "@ContentIcon", name: 'num-6', path: 'num-6.png'},
    "num-7": {id: "num-7", type: "@ContentIcon", name: 'num-7', path: 'num-7.png'},
    "num-8": {id: "num-8", type: "@ContentIcon", name: 'num-8', path: 'num-8.png'},
    "num-9": {id: "num-9", type: "@ContentIcon", name: 'num-9', path: 'num-9.png'},
    "num-10": {id: "num-10", type: "@ContentIcon", name: 'num-10', path: 'num-10.png'},
    "video": {id: "video", type: "@ContentIcon", name: 'video', path: 'video.png'},
    "video-text": {id: "video-text", type: "@ContentIcon", name: 'video-text', path: 'video-text.png'},
    "jll_pin_blue": {id: "jll_pin_blue", type: "@ContentIcon", name: 'jll_pin_blue', path: 'jll_pin_blue.png'},
    "jll_pin_blue_active": {id: "jll_pin_blue_active", type: "@ContentIcon", name: 'jll_pin_blue_active', path: 'jll_pin_blue_active.png'},
    "jll_pin_red": {id: "jll_pin_red", type: "@ContentIcon", name: 'jll_pin_red', path: 'jll_pin_red.png'},
    "jll_pin_red_active": {id: "jll_pin_red_active", type: "@ContentIcon", name: 'jll_pin_red_active', path: 'jll_pin_red_active.png'},
    // Icons (new)
    "location": { id: "location", type: "@ContentIcon", name: "location", path: "location.png" },
    "tv": { id: "tv", type: "@ContentIcon", name: "tv", path: "tv.png" },
    "laptop": { id: "laptop", type: "@ContentIcon", name: "laptop", path: "laptop.png" },
    "star-full": { id: "star-full", type: "@ContentIcon", name: "star-full", path: "star-full.png" },
    "check": { id: "check", type: "@ContentIcon", name: "check", path: "check.png" },
    "fire": { id: "fire", type: "@ContentIcon", name: "fire", path: "fire.png" },
    "reaction-sad": { id: "reaction-sad", type: "@ContentIcon", name: "reaction-sad", path: "reaction-sad.png" },
    "healing": { id: "healing", type: "@ContentIcon", name: "healing", path: "healing.png" },
    "living_room": { id: "living_room", type: "@ContentIcon", name: "living_room", path: "living_room.png" },
    "check-circle": { id: "check-circle", type: "@ContentIcon", name: "check-circle", path: "check-circle.png" },
    "volunteer": { id: "volunteer", type: "@ContentIcon", name: "volunteer", path: "volunteer.png" },
    "new": { id: "new", type: "@ContentIcon", name: "new", path: "new.png" },
    "gym": { id: "gym", type: "@ContentIcon", name: "gym", path: "gym.png" },
    "schedule": { id: "schedule", type: "@ContentIcon", name: "schedule", path: "schedule.png" },
    "home": { id: "home", type: "@ContentIcon", name: "home", path: "home.png" },
    "plus-circle": { id: "plus-circle", type: "@ContentIcon", name: "plus-circle", path: "plus-circle.png" },
    "kitchen": { id: "kitchen", type: "@ContentIcon", name: "kitchen", path: "kitchen.png" },
    "city": { id: "city", type: "@ContentIcon", name: "city", path: "city.png" },
    "pool": { id: "pool", type: "@ContentIcon", name: "pool", path: "pool.png" },
    "location-unknown": { id: "location-unknown", type: "@ContentIcon", name: "location-unknown", path: "location-unknown.png" },
    "chevron-top": { id: "chevron-top", type: "@ContentIcon", name: "chevron-top", path: "chevron-top.png" },
    "chevron-circle-left": { id: "chevron-circle-left", type: "@ContentIcon", name: "chevron-circle-left", path: "chevron-circle-left.png" },
    "chevron-circle-right": { id: "chevron-circle-right", type: "@ContentIcon", name: "chevron-circle-right", path: "chevron-circle-right.png" },
    "thumb-down": { id: "thumb-down", type: "@ContentIcon", name: "thumb-down", path: "thumb-down.png" },
    "science": { id: "science", type: "@ContentIcon", name: "science", path: "science.png" },
    "biotech": { id: "biotech", type: "@ContentIcon", name: "biotech", path: "biotech.png" },
    "accessibility": { id: "accessibility", type: "@ContentIcon", name: "accessibility", path: "accessibility.png" },
    "event": { id: "event", type: "@ContentIcon", name: "event", path: "event.png" },
    "chevron-bottom": { id: "chevron-bottom", type: "@ContentIcon", name: "chevron-bottom", path: "chevron-bottom.png" },
    "transfer": { id: "transfer", type: "@ContentIcon", name: "transfer", path: "transfer.png" },
    "chevron-right": { id: "chevron-right", type: "@ContentIcon", name: "chevron-right", path: "chevron-right.png" },
    "speaker": { id: "speaker", type: "@ContentIcon", name: "speaker", path: "speaker.png" },
    "cafeteria": { id: "cafeteria", type: "@ContentIcon", name: "cafeteria", path: "cafeteria.png" },
    "block": { id: "block", type: "@ContentIcon", name: "block", path: "block.png" },
    "dining-room": { id: "dining-room", type: "@ContentIcon", name: "dining-room", path: "dining-room.png" },
    "info-circle": { id: "info-circle", type: "@ContentIcon", name: "info-circle", path: "info-circle.png" },
    "check-double": { id: "check-double", type: "@ContentIcon", name: "check-double", path: "check-double.png" },
    "terrace": { id: "terrace", type: "@ContentIcon", name: "terrace", path: "terrace.png" },
    "headphones": { id: "headphones", type: "@ContentIcon", name: "headphones", path: "headphones.png" },
    "arrow-drop-left": { id: "arrow-drop-left", type: "@ContentIcon", name: "arrow-drop-left", path: "arrow-drop-left.png" },
    "sleeping-room": { id: "sleeping-room", type: "@ContentIcon", name: "sleeping-room", path: "sleeping-room.png" },
    "bloodtype": { id: "bloodtype", type: "@ContentIcon", name: "bloodtype", path: "bloodtype.png" },
    "water": { id: "water", type: "@ContentIcon", name: "water", path: "water.png" },
    "money": { id: "money", type: "@ContentIcon", name: "money", path: "money.png" },
    "store": { id: "store", type: "@ContentIcon", name: "store", path: "store.png" },
    "link": { id: "link", type: "@ContentIcon", name: "link", path: "link.png" },
    "entrance": { id: "entrance", type: "@ContentIcon", name: "entrance", path: "entrance.png" },
    "wardrobe2": { id: "wardrobe2", type: "@ContentIcon", name: "wardrobe2", path: "wardrobe2.png" },
    "pets": { id: "pets", type: "@ContentIcon", name: "pets", path: "pets.png" },
    "hardware": { id: "hardware", type: "@ContentIcon", name: "hardware", path: "hardware.png" },
    "location-bad": { id: "location-bad", type: "@ContentIcon", name: "location-bad", path: "location-bad.png" },
    "shopping-bag": { id: "shopping-bag", type: "@ContentIcon", name: "shopping-bag", path: "shopping-bag.png" },
    "teenage-room": { id: "teenage-room", type: "@ContentIcon", name: "teenage-room", path: "teenage-room.png" },
    "info": { id: "info", type: "@ContentIcon", name: "info", path: "info.png" },
    "exclamation-circle": { id: "exclamation-circle", type: "@ContentIcon", name: "exclamation-circle", path: "exclamation-circle.png" },
    "manage-accounts": { id: "manage-accounts", type: "@ContentIcon", name: "manage-accounts", path: "manage-accounts.png" },
    "medication": { id: "medication", type: "@ContentIcon", name: "medication", path: "medication.png" },
    "chart-up": { id: "chart-up", type: "@ContentIcon", name: "chart-up", path: "chart-up.png" },
    "power": { id: "power", type: "@ContentIcon", name: "power", path: "power.png" },
    "sport-field": { id: "sport-field", type: "@ContentIcon", name: "sport-field", path: "sport-field.png" },
    "education": { id: "education", type: "@ContentIcon", name: "education", path: "education.png" },
    "tips": { id: "tips", type: "@ContentIcon", name: "tips", path: "tips.png" },
    "heart": { id: "heart", type: "@ContentIcon", name: "heart", path: "heart.png" },
    "arrow-drop-up": { id: "arrow-drop-up", type: "@ContentIcon", name: "arrow-drop-up", path: "arrow-drop-up.png" },
    "handyman": { id: "handyman", type: "@ContentIcon", name: "handyman", path: "handyman.png" },
    "description": { id: "description", type: "@ContentIcon", name: "description", path: "description.png" },
    "garden": { id: "garden", type: "@ContentIcon", name: "garden", path: "garden.png" },
    "shower": { id: "shower", type: "@ContentIcon", name: "shower", path: "shower.png" },
    "library": { id: "library", type: "@ContentIcon", name: "library", path: "library.png" },
    "paragraph": { id: "paragraph", type: "@ContentIcon", name: "paragraph", path: "paragraph.png" },
    "medical-services": { id: "medical-services", type: "@ContentIcon", name: "medical-services", path: "medical-services.png" },
    "bolt": { id: "bolt", type: "@ContentIcon", name: "bolt", path: "bolt.png" },
    "chart-down": { id: "chart-down", type: "@ContentIcon", name: "chart-down", path: "chart-down.png" },
    "minus": { id: "minus", type: "@ContentIcon", name: "minus", path: "minus.png" },
    "height": { id: "height", type: "@ContentIcon", name: "height", path: "height.png" },
    "support-agent": { id: "support-agent", type: "@ContentIcon", name: "support-agent", path: "support-agent.png" },
    "chevron-left": { id: "chevron-left", type: "@ContentIcon", name: "chevron-left", path: "chevron-left.png" },
    "pan": { id: "pan", type: "@ContentIcon", name: "pan", path: "pan.png" },
    "thumb-up": { id: "thumb-up", type: "@ContentIcon", name: "thumb-up", path: "thumb-up.png" },
    "ac-unit": { id: "ac-unit", type: "@ContentIcon", name: "ac-unit", path: "ac-unit.png" },
    "settings": { id: "settings", type: "@ContentIcon", name: "settings", path: "settings.png" },
    "wardrobe1": { id: "wardrobe1", type: "@ContentIcon", name: "wardrobe1", path: "wardrobe1.png" },
    "reaction-happy1": { id: "reaction-happy1", type: "@ContentIcon", name: "reaction-happy1", path: "reaction-happy1.png" },
    "chart-pie": { id: "chart-pie", type: "@ContentIcon", name: "chart-pie", path: "chart-pie.png" },
    "face": { id: "face", type: "@ContentIcon", name: "face", path: "face.png" },
    "parking": { id: "parking", type: "@ContentIcon", name: "parking", path: "parking.png" },
    "accessible-wheelchair": { id: "accessible-wheelchair", type: "@ContentIcon", name: "accessible-wheelchair", path: "accessible-wheelchair.png" },
    "search": { id: "search", type: "@ContentIcon", name: "search", path: "search.png" },
    "question": { id: "question", type: "@ContentIcon", name: "question", path: "question.png" },
    "arrow-drop-down": { id: "arrow-drop-down", type: "@ContentIcon", name: "arrow-drop-down", path: "arrow-drop-down.png" },
    "shopping-cart": { id: "shopping-cart", type: "@ContentIcon", name: "shopping-cart", path: "shopping-cart.png" },
    "park": { id: "park", type: "@ContentIcon", name: "park", path: "park.png" },
    "carpenter": { id: "carpenter", type: "@ContentIcon", name: "carpenter", path: "carpenter.png" },
    "warning": { id: "warning", type: "@ContentIcon", name: "warning", path: "warning.png" },
    "child-room": { id: "child-room", type: "@ContentIcon", name: "child-room", path: "child-room.png" },
    "check-circle1": { id: "check-circle1", type: "@ContentIcon", name: "check-circle1", path: "check-circle1.png" },
    "thermostat_black_24dp": { id: "thermostat_black_24dp", type: "@ContentIcon", name: "thermostat_black_24dp", path: "thermostat_black_24dp.png" },
    "health-safety": { id: "health-safety", type: "@ContentIcon", name: "health-safety", path: "health-safety.png" },
    "exclamation": { id: "exclamation", type: "@ContentIcon", name: "exclamation", path: "exclamation.png" },
    "luggage": { id: "luggage", type: "@ContentIcon", name: "luggage", path: "luggage.png" },
    "autorenew": { id: "autorenew", type: "@ContentIcon", name: "autorenew", path: "autorenew.png" },
    "garage": { id: "garage", type: "@ContentIcon", name: "garage", path: "garage.png" },
    "arrow-drop-right": { id: "arrow-drop-right", type: "@ContentIcon", name: "arrow-drop-right", path: "arrow-drop-right.png" },
    "location-checked": { id: "location-checked", type: "@ContentIcon", name: "location-checked", path: "location-checked.png" },
    "no-access": { id: "no-access", type: "@ContentIcon", name: "no-access", path: "no-access.png" },
    "electrical-services": { id: "electrical-services", type: "@ContentIcon", name: "electrical-services", path: "electrical-services.png" },
    "chart-bar": { id: "chart-bar", type: "@ContentIcon", name: "chart-bar", path: "chart-bar.png" },
    "pets-small": { id: "pets-small", type: "@ContentIcon", name: "pets-small", path: "pets-small.png" },
    "mask": { id: "mask", type: "@ContentIcon", name: "mask", path: "mask.png" },
    "coronavirus": { id: "coronavirus", type: "@ContentIcon", name: "coronavirus", path: "coronavirus.png" },
    "question-circle": { id: "question-circle", type: "@ContentIcon", name: "question-circle", path: "question-circle.png" },
    "x-circle": { id: "x-circle", type: "@ContentIcon", name: "x-circle", path: "x-circle.png" },
    "hospital": { id: "hospital", type: "@ContentIcon", name: "hospital", path: "hospital.png" },
    "manufacturing": { id: "manufacturing", type: "@ContentIcon", name: "manufacturing", path: "manufacturing.png" },
    "work": { id: "work", type: "@ContentIcon", name: "work", path: "work.png" },
    "minus-circle": { id: "minus-circle", type: "@ContentIcon", name: "minus-circle", path: "minus-circle.png" },
    "toilet": { id: "toilet", type: "@ContentIcon", name: "toilet", path: "toilet.png" },
    "reaction-happy2": { id: "reaction-happy2", type: "@ContentIcon", name: "reaction-happy2", path: "reaction-happy2.png" },
    "desktop": { id: "desktop", type: "@ContentIcon", name: "desktop", path: "desktop.png" },
    "chart-table": { id: "chart-table", type: "@ContentIcon", name: "chart-table", path: "chart-table.png" },
    "language": { id: "language", type: "@ContentIcon", name: "language", path: "language.png" },
    "spa": { id: "spa", type: "@ContentIcon", name: "spa", path: "spa.png" },
    "emergency": { id: "emergency", type: "@ContentIcon", name: "emergency", path: "emergency.png" },
    "battery-charging": { id: "battery-charging", type: "@ContentIcon", name: "battery-charging", path: "battery-charging.png" },
    "heart-full": { id: "heart-full", type: "@ContentIcon", name: "heart-full", path: "heart-full.png" },
    "star": { id: "star", type: "@ContentIcon", name: "star", path: "star.png" },
    "bathroom": { id: "bathroom", type: "@ContentIcon", name: "bathroom", path: "bathroom.png" },
    "wifi": { id: "wifi", type: "@ContentIcon", name: "wifi", path: "wifi.png" },
    "map": { id: "map", type: "@ContentIcon", name: "map", path: "map.png" },
    // Placeholders
    "image-add": {id: "image-add", type: "@ContentImage", path: Config.baseUrl + Config.context + '/image-add.png'},
    "image-placeholder": {id: "image-placeholder", type: "@ContentImage", path: Config.baseUrl + Config.context + '/image-placeholder.png'},
    "image-broken": {id: "image-broken", type: "@ContentImage", path: Config.baseUrl + Config.context + '/image-broken.png'},
    "video-broken": {id: "video-broken", type: "@ContentImage", path: Config.baseUrl + Config.context + '/video-broken.png'},
    // Fonts
    "fonts": [
      {id: 'font1', type: "@Font", name: 'Arvo', types: ['400', '400i', '700', '700i']},
      {id: 'font2', type: "@Font", name: 'Bangers', types: ['400']},
      {id: 'font3', type: "@Font", name: 'Dancing Script', types: ['400', '700']},
      {id: 'font4', type: "@Font", name: 'Lato', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font5', type: "@Font", name: 'Limelight', types: ['400']},
      {id: 'font6', type: "@Font", name: 'Montserrat', types: ['400', '700']},
      {id: 'font7', type: "@Font", name: 'Roboto', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font8', type: "@Font", name: 'Rubik', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font9', type: "@Font", name: 'Source Sans Pro', types: ['200', '300', '400', '600', '700']},
      {id: 'font10', type: "@Font", name: 'Ubuntu', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font11', type: "@Font", name: 'Cormorant', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font12', type: "@Font", name: 'Fira Sans', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font13', type: "@Font", name: 'Josefin Sans', types: ['400', '400i']},
      {id: 'font14', type: "@Font", name: 'Krona One', types: ['400']},
      {id: 'font15', type: "@Font", name: 'Lora', types: ['400', '400i']},
      {id: 'font16', type: "@Font", name: 'Mulish', types: ['400', '400i']},
      {id: 'font17', type: "@Font", name: 'Open Sans', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font18', type: "@Font", name: 'Oswald', types: ['400']},
      {id: 'font19', type: "@Font", name: 'Playfair Display', types: ['400', '400i']},
      {id: 'font20', type: "@Font", name: 'Poppins', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font21', type: "@Font", name: 'Prompt', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font22', type: "@Font", name: 'PT Sans', types: ['400', '400i', '700', '700i']},
      {id: 'font23', type: "@Font", name: 'Rubik', types: ['400', '400i']},
      {id: 'font24', type: "@Font", name: 'Source Serif Pro', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font25', type: "@Font", name: 'Space Mono', types: ['400', '400i', '700', '700i']},
      {id: 'font26', type: "@Font", name: 'Spectral', types: ['300', '300i', '400', '400i', '700', '700i']},
      {id: 'font27', type: "@Font", name: 'Abril Fatface', types: ['400']},
      {id: 'font11', type: "@Font", name: 'Futura', types: ['300', '300i', '400', '400i', '500', '500i', '700', '700i'], selfHosted: true},
    ],
    "spheres": [
      {
        "id": 'spherePlaceholder',
        "type": '@ContentSphere',
        "path": Config.baseUrl + Config.context + '/sphere-placeholder.png',
        "pathThumbnail": Config.baseUrl + Config.context + '/sphere-placeholder.png'
      },
      {
        "id": 'sphereBroken',
        "type": '@ContentSphere',
        "path": Config.baseUrl + Config.context + '/sphere-broken.png',
        "pathThumbnail": Config.baseUrl + Config.context + '/sphere-broken.png'
      },
    ],
  },
  scenes: [],
  context: '',
  style: {
    theme: 'DARK'
  },
  spaceplans: []
};

export default DefaultProject;

