/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

export function getNextScene(scenes, currentSceneId, loop = false) {
  try {
    let nextSceneId,
      currentSceneIndex;

    currentSceneIndex = scenes.findIndex((scene) => scene.id === currentSceneId );

    if (currentSceneIndex > -1) {
      nextSceneId = getNextVisibleSceneId(scenes, currentSceneIndex);

      if (loop && !nextSceneId) {
        nextSceneId = getNextVisibleSceneId(scenes);
      }
    }

    return nextSceneId;
  } catch(e) {}
}

export function getNextVisibleSceneId(scenes, sceneIndex) {
  let j;
  let nextSceneId;

  if (typeof sceneIndex !== 'undefined') {
    // start looking from next scene
    j = sceneIndex + 1;
  } else {
    // start looking from first scene
    j = 0;
  }

  while(j > -1 && j < scenes.length) {
    if (scenes[j] && scenes[j].isVisible !== false) {
      nextSceneId = scenes[j].id;
      break;
    }
  }

  return nextSceneId;
}

