/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils.js'

export default class Overlay {
  constructor(containerParent) {
    this.$ = Utils.$({cssClass: 'ui-overlay'});
    containerParent.appendChild(this.$);
  }
  show() {
    this.$.classList.remove('isHidden');
  }
  hide() {
    this.$.classList.add('isHidden');
  }
}

