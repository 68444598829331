/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import _cloneDeep from 'lodash/cloneDeep';
import DefaultProject from './DefaultProject';
import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';

const _logger = new Logger('Parser');

class Parser {
  static parseWidget(widget) {
    const widgetData = _cloneDeep(widget);

    widgetData.details = widgetData.details || {};
    widgetData.details.color = widgetData.details.color || 'red';

    const hasRotation = !!widgetData.rotation;
    const hasDestinationRotation =
      widgetData.details.destination && widgetData.details.destination.rotation,
      hasAnimationType = widgetData.details.animationType;

    if (hasRotation) {
      widgetData.rotation = Utils.parseXYZToRadians(widgetData.rotation);
    }

    if (hasDestinationRotation) {
      widgetData.details.destination.rotation = Utils.parseXYZToRadians(
        widgetData.details.destination.rotation
      );
    }

    if (hasAnimationType) {
      widgetData.details.animationType = widget.details.animationType.toUpperCase();
    }

    widgetData.details.background = !!widgetData.details.background;

    if (!widget.details.tilt) {
      widgetData.details.tilt = { x: 0, y: 0, z: 0 };
    }

    return widgetData;
  }

  static parseObjectIds(object) {
    const isAnObject = Utils.Types.isObject(object);

    if (!isAnObject) { return; }

    const hasId = typeof object.id === 'number';

    if (hasId) { object.id = `${object.id}`; }

    Object.values(object)
      .forEach(property => { Parser.parseObjectIds(property); });
  }

  static parseScene(scene) {
    const DEFAULT_SPHERES_SETUP = [{
      id: '1',
      resourceId: 'spherePlaceholder',
      rotation: { x: 0, y: 0, z: 0 }
    }];

    const hasNoSpheres = !scene.spheres;

    // set default sphere if there are none, otherwise convert existing ones
    scene.spheres = hasNoSpheres ? DEFAULT_SPHERES_SETUP : scene.spheres.map(
      sphere => !sphere.rotation ? sphere : {
        ...sphere,
        resourceId: '' + sphere.resourceId,
        rotation: Utils.parseXYZToRadians(sphere.rotation)
      }
    );

    if (scene.rotation) {
      scene.rotation = Utils.parseXYZToRadians(scene.rotation);
    }

    if (scene.widgets) {
      scene.widgets = scene.widgets.map(widget => Parser.parseWidget(widget));
    }

    if (typeof scene.initialSphereId === 'number') {
      scene.initialSphereId = '' + scene.initialSphereId;
    }

    if (scene.theme) {
      scene.theme = scene.theme.toUpperCase();
    }

    Parser.parseObjectIds(scene);
  }

  static parseProject(data) {
    let deepCopy = _cloneDeep(data);
    const isDataWrapped = data &&
        data.publicContent &&
        data.publicContent.content;

    // list all known resource group with appropriate types
    const resourceGroupTypes = {
      audios: '@Audio',
      spheres: '@ContentSphere',
      videosSpherical: '@ContentVideoSphere',
      images: '@ContentImage',
      videos: '@ContentVideo',
      fonts: '@Font'
    };

    if (isDataWrapped) {
      deepCopy = deepCopy.publicContent.content;
      _logger.warn("Data is wrapped with metatags. Probably invalid json.");
    }

    const newData = Utils.extend(DefaultProject, deepCopy);

    // list resource group keys
    const resourceGroupTypeKeys = Object.keys(resourceGroupTypes);

    // convert array to key: value assignment (with resource id as key)
    // additionally, add 'type' field to each object (needed by ResourceLoader)
    const parseResourceGroup = ({ data, type }) =>
      data.reduce((parsedResources, newResource) => ({
        ...parsedResources,
        [newResource.id]: { ...newResource, type }
      }), {});

    // take all known resource types and convert them using above function
    const parsedResourceGroups = resourceGroupTypeKeys
      .map(resourceGroupTypeKey => ({
        type: resourceGroupTypes[resourceGroupTypeKey],
        data: newData.resources[resourceGroupTypeKey]
      }))
      .filter(resourceGroup => Array.isArray(resourceGroup.data))
      .map(parseResourceGroup);

    // add all parsed resource groups to 'resources' object
    parsedResourceGroups.forEach(parsedResourceGroup => Object.assign(
      newData.resources, parsedResourceGroup
    ));

    // remove already parsed resource groups
    resourceGroupTypeKeys.forEach(resourceGroupTypeKey => {
      delete newData.resources[resourceGroupTypeKey];
    });

    // if there's no scenes, create scene placeholder
    if (!newData.scenes.length) {
      newData.scenes.push({
        id: "scenePlaceholder",
        spheres: [{
          id: "spherePlaceholder",
          resourceId: 'spherePlaceholder'
        }],
        widgets: []
      });
    }

    // parse scenes
    newData.scenes.forEach(Parser.parseScene);

    // set initial state with scene id, if missing
    newData.initialState =
      newData.initialState && newData.initialState.sceneId ?
        newData.initialState :
        { ...newData.initialState, sceneId: newData.scenes[0].id };

    newData.initialState.rotation = Utils.parseXYZToRadians(
      newData.initialState.rotation || { x: 0, y: 0, z: 0 }
    );

    // passing through all object properties recursively, parsing ids to strings
    Parser.parseObjectIds(newData);

    // Parse enums to uppercase
    if (newData.style.theme) {
      newData.style.theme = newData.style.theme.toUpperCase();
    }

    return newData;
  }
};

export default Parser;

