/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Loader from './Loader';
import Overlay from './Overlay';
import EventsEmitter from './../Utils/EventsEmitter';
import Utils from '../Utils/Utils';
import './style.scss';
import Spaceplans from "./Spaceplans";

export default class Ui extends EventsEmitter {
  constructor({selector, element, stats, project}) {
    super();

    if (element && element instanceof HTMLElement) {
      this.containerParent = element;
    } else {
      this.containerParent = document.querySelector(selector) || document.body;
    }

    this.$ = Utils.$({
      // add padding for mobile Safari to emulate fullscreen
      // TODO Test iPhone6/6s and Android
      cssClass: `evr${(Utils.Browser.mobile && Utils.Browser.safari())?' iphoneEmulateFS':''}`
    });
    this.containerParent.appendChild(this.$);

    this._project = project;
    this.$fontsContainer = Utils.$({
      cssClass: 'evr-fontsContainer'
    });
    this.$.appendChild(this.$fontsContainer);

    this.$log = Utils.$({
      cssClass: 'evr-logs'
    });
    this.$.appendChild(this.$log);
    this.$ui = Utils.$({
      cssClass: 'evr-ui'
    });
    this.$.appendChild(this.$ui);

    if (stats) {
      stats.dom.classList.add('evr-stats');
      this.$ui.appendChild(stats.dom);
    }
    
    this._overlay = new Overlay(this.$ui);
    this._cssLoader = new Loader({parent: this.$ui});

    this._brand = null;
    this.handleResize = this.handleResize.bind(this);
    this.createSpaceplans = this.createSpaceplans.bind(this);
    
    window.addEventListener('resize', this.handleResize);
    this.createSpaceplans();
  }
  handleResize() {
    this.emit('resize');
  }
  requestFullscreen() {
    if(this.$.requestFullscreen) {
      this.$.requestFullscreen();
    } else if(this.$.mozRequestFullScreen) {
      this.$.mozRequestFullScreen();
    } else if(this.$.webkitRequestFullscreen) {
      this.$.webkitRequestFullscreen();
    } else if(this.$.msRequestFullscreen) {
      this.$.msRequestFullscreen();
    }
  }
  createSpaceplans() {
    this._spaceplans = new Spaceplans(this, this.$ui);
    this._spaceplans.hide();
    this._spaceplans.on('changeScene', (e) => {
      this.emit('changeScene', e);
    });
  }
  updateSpaceplans(spaceplans, resources, sceneId, sceneName) {
    if (!spaceplans || !spaceplans.length) return;

    if (!this._spaceplans) this.createSpaceplans();
    this._spaceplans.update(spaceplans, resources, sceneId, sceneName);
    this._spaceplans.hide();
  }
  showSpaceplans() {
    if (!this._spaceplans) return;
    
    this._spaceplans.show();
  }
  setSpaceplanScene(sceneId, sceneName) {
    if (!this._spaceplans) return;
  
    this._spaceplans.setCurrentScene(sceneId, sceneName);
    this.hideSpaceplans();
  }
  hideSpaceplans() {
    if (!this._spaceplans) return;
    
    this._spaceplans.hide();
  }
  removeSpaceplans() {
    if (!this._spaceplans) return;
    
    this._spaceplans.off('changeScene');
    this._spaceplans.remove();
    this._spaceplans = null;
  }
  loading({progress = -1, error}) {
    this._overlay.show();
    this._cssLoader.set({progress, error});
  }
  stopLoading() {
    this._cssLoader.hide();
    this._overlay.hide();
  }
  log(msg) {
    this.$log.innerHTML = msg;
  }
  setBrand(brandName) {
    if (this._brand !== brandName) {
      this._brand = brandName;
      if (this._cssLoader) {
        this.$ui.removeChild(this._cssLoader.$);
      }

      this._cssLoader = new Loader({parent: this.$ui, brand: this._brand});
    }
  }
  remove() {
    window.removeEventListener('resize', this.handleResize);
    this.containerParent.removeChild(this.$);
  }
}

