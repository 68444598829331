/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter';
import EventsEmitter from './../Utils/EventsEmitter';
import io from 'socket.io-client';
import Utils from 'Utils/Utils';
import { Config } from 'evr';

var count = 0;

function createP2PConnection(cfg, on) {
  cfg = cfg || {};
  on = on || {};
  cfg.servers = cfg.servers || null;
  return new Promise(function (resolve, reject) {
    if (!window.RTCPeerConnection) {
      reject(Error('WebRTC unsupported'));
    }
    cfg.servers.bundlePolicy = "max-compat";
    cfg.servers.iceTransportPolicy = "all";
    cfg.servers.iceCandidatePoolSize = 0;
    var connection = {
        active: false,
        name: cfg.name || 'rtc#' + (++count),
        localCandidates: [],
        localDescription: null,
        localStreams: [],
        remoteCandidates: [],
        remoteDescription: null,
        remoteStream: new MediaStream(),
        closed: false
      },
      pc = new RTCPeerConnection(cfg.servers, null);
    connection.connection = pc;
    connection.close = function () {
      pc.close();
      connection.closed = true;
    };
    connection.addCandidate = function (candidate) {
      candidate = new RTCIceCandidate(candidate);
      return pc.addIceCandidate(candidate).then(function () {
        cfg.debug && console.info(connection.name, 'ice candidate attached', candidate);
        connection.remoteCandidates.push(candidate);
      }, function (e) {
        cfg.debug && console.info(connection.name, 'ice candidate rejected (', e, ')', candidate);
      });
    };
    connection.answer = function (offer, emitEvent) {
      connection.answering = true;
      return pc.setRemoteDescription(offer).then(function () {
        connection.remoteDescription = offer;
        cfg.debug && console.info(connection.name, 'rtc remote description set', offer);
        return pc.createAnswer().then(function (answer) {
          cfg.debug && console.info(connection.name, 'rtc answer created', answer);
          return pc.setLocalDescription(answer).then(function () {
            connection.localDescription = answer;
            connection.answering = false;
            cfg.debug && console.info(connection.name, 'rtc local description set', answer);
            if (emitEvent && on.answer) {
              on.answer({
                connection: connection,
                answer: answer
              });
            }
            return answer;
          });
        });
      });
    };
    connection.offer = function (emitEvent) {
      return pc.createOffer({offerToReceiveAudio: true}).then(function (offer) {
        cfg.debug && console.info(connection.name, 'rtc offer created', offer);
        return pc.setLocalDescription(offer).then(function () {
          connection.localDescription = offer;
          cfg.debug && console.info(connection.name, 'rtc local description set', offer);
          if (emitEvent && on.offer) {
            on.offer({
              connection: connection,
              offer: offer
            });
          }
          return offer;
        });
      });
    };
    connection.finalize = function (description) {
      description = new RTCSessionDescription(description);
      pc.setRemoteDescription(description).then(function () {
        cfg.debug && console.info(connection.name, 'rtc remote description set', description);
        connection.remoteDescription = description;
      });
    };
    var media = connection.localMedia = {
      stream: null,
      tracks: [],
      audio: [],
      video: [],
      active: false,
      start: function (constrains) {
        if (connection.active && !media.active) {
          return new Promise(function (resolve, reject) {
            navigator.mediaDevices.getUserMedia(constrains)
              .then((stream) => {
                connection.localStreams.push(stream);
                stream.getTracks().forEach(function(track) {
                  pc.addTrack(track, stream);
                });
                media.stream = stream;
                media.tracks = stream.getTracks();
                media.audio = stream.getAudioTracks();
                media.video = stream.getVideoTracks();
                media.active = true;
                resolve(media);
              })
              .catch(reject);
          });
        } else {
          return Promise.resolve(media);
        }
      },
      stop: function () {
        if (media.active) {
          media.tracks.forEach(function (track) {
            track.stop();
          });
          const stream = media.stream;
          var streams = connection.localStreams,
            index = streams.indexOf(stream);
          streams.splice(index, 1);
          if (pc.iceConnectionState !== 'closed') {
            stream.getTracks().forEach(track => {
              pc.removeTrack(
                pc.getSenders().find((sender) => sender.track == track)
              );
            });
          }
          media.active = false;
        }
      },
      enable: function (target, enabled) {
        if (Array.isArray(media[target])) {
          media[target].forEach(function (track) {
            track.enabled = enabled;
          });
          media[target].disabled = !enabled;
        } else {
          throw Error('Wrong target');
        }
      }
    };
    if (cfg.data) {
      connection.sendChannel = pc.createDataChannel('dataChannel', cfg.data);
      connection.sendChannel.onopen = function (e) {
        connection.send = function (data) {
          if (typeof(data) === 'object') {
            data = JSON.stringify(data);
          }
          connection.sendChannel.send(data);
        };
        if (on.sendChannel) {
          on.sendChannel({
            connection: connection,
            channel: connection.sendChannel,
            event: e
          });
        }
      };
    }
    pc.onerror = function (e) {
      console.error(connection.name, 'rtc connection error', e);
      if (on.error) {
        on.error({
          connection: connection,
          event: e
        });
      }
    };
    pc.onnegotiationneeded = function (e) {
      if (!connection.answering) {
        cfg.debug && console.info(connection.name, 'rtc negotiation needed');
        if ((!cfg.offer || connection.localDescription && connection.localDescription.type === 'offer') && on.offer) {
          connection.offer(true);
        } else if (on.offerRequest) {
          on.offerRequest({
            connection: connection,
            event: e
          });
        }
      }
    };
    pc.oniceconnectionstatechange = function () {
      cfg.debug && console.info(connection.name, 'ice connection state change:', pc.iceConnectionState);
      if (['connected', 'completed'].includes(pc.iceConnectionState)) {
        if (!connection.active) {
          connection.active = true;
          if (on.connection) {
            on.connection(connection);
          }
        }
        /**
         * This statement used to check for "disconnected" ICE state as well.
         * Turned out, when host connects audio in Webkit browser,
         * ICE gets into "disconnected" state for a moment, but then recovers connection.
         * This caused instant viewing disconnection.
         *
         * From the docs (https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState):
         * "This is a less stringent test than "failed" and may trigger intermittently and resolve
         * just as spontaneously on less reliable networks, or during temporary disconnections.
         * When the problem resolves, the connection may return to the "connected" state."
         *
         * PeerJS library fixed same issue:
         * https://github.com/peers/peerjs/commit/72e7c176c8f3e43f5524cef4988d71753249c4c0
         */
      } else if (['closed', 'failed'].includes(pc.iceConnectionState)) {
        if (connection.active) {
          connection.active = false;
          connection.closed = true;
          if (on.disconnection) {
            on.disconnection(connection);
          }
        }
      }
    };
    pc.ontrack = function (e) {
      cfg.debug && console.info(connection.name, 'rtc track added', e);
      const stream = e.streams[0];
      connection.remoteStream.addTrack(e.track, stream);

      stream.onremovetrack = function (e) {
        cfg.debug && console.info(connection.name, 'rtc track removed', e);
        connection.remoteStream.removeTrack(e.track);
        if (on.removeStream) {
          on.removeStream({
            connection: connection,
            stream: stream,
            event: e
          });
        }
      };

      if (on.addStream) {
        on.addStream({
          connection: connection,
          stream: stream,
          event: e
        });
      }
    };
    pc.ondatachannel = function (e) {
      cfg.debug && console.info(connection.name, 'rtc data channel active', e.channel);
      connection.receiveChannel = e.channel;
      connection.active = true;
      if (on.receiveChannel) {
        on.receiveChannel({
          connection: connection,
          channel: e.channel,
          event: e
        });
      }
      if (on.message) {
        e.channel.onmessage = function (e) {
          var data = e.data;
          try {
            data = JSON.parse(data);
          } catch (e) { /* noop */
          }
          cfg.debug && console.info(connection.name, 'rtc data channel message', data);
          on.message({
            connection: connection,
            data: data,
            event: e
          });
        };
      }
    };
    pc.onicecandidate = function (e) {
      if (e.candidate) {
        cfg.debug && console.info(connection.name, 'ice candidate emitted', e.candidate);
        connection.localCandidates.push(e.candidate);
        if (on.candidate) {
          on.candidate({
            connection: connection,
            candidate: e.candidate,
            event: e
          });
        }
      }
    };
    //connection init
    if (cfg.offer) {
      var offer = new RTCSessionDescription(cfg.offer);
      connection.answer(offer, true).then(function () {
        resolve(connection);
      }, reject);
    } else {
      resolve(connection);
    }
  });
}

/**
 * Low-level class responsible for connecting to viewing-server (sockets) and viewing-client (p2p).
 *
 * @extends EventsEmitter
 *
 * @emits 'connection' {connected, active, p2pActive, clients} - connected to the socket server
 * @emits 'connected' - fired after connection when peer is already connected
 * @emits 'disconnection' {connected, active, p2pActive, clients} - disconnected from the socket server
 * @emits 'disconnected' - connected peer disconnects
 * @emits 'data' - client receives data from peer
 * @emits 'peerUpgraded' - peer donnected
 * @emits 'peerDisconnected' - peer disconnected from socket server
 * @emits 'peerDowngraded' - peer disconnected from p2p connection
 * @emits 'streamAdded' - rtc stream added
 */
export class Controller extends EventsEmitter {
  /**
   * Creates controller instance
   *
   * @param {Object} settings
   * @param {Object} settings.socket
   * @param {String} settings.socket.server - address of socket server
   * @param {String} settings.socket.path - name of the path to capture
   * @param {String} settings.hash - viewing hash
   * @param {String} settings.role - viewing role - possible values: 'client', 'host'
   */
  constructor(settings) {
    super(settings);

    var _options = Utils.extend({
        role: 'client',
        p2p: {
          auto: true,
          disabled: false,
          data: {reliable: true},
          servers: {
            iceServers: Config.iceServers
          },
          debug: false
        },
        socket: {
          server: Config.socketServer.host,
          path: ''
        }
      }, settings),
      _state = {
        connected: false,
        active: false,
        p2pActive: false,
        clients: []
      },
      _on = {
        connection: (connection) => {
          updateP2pState();
          this.emit('peerUpgraded', _getClient(connection.name));
        },
        disconnection: (connection) => {
          updateP2pState();
          this.emit('peerDowngraded', _getClient(connection.name));
        },
        offer: (e) => {
          _negotiate(e.connection.name, {offer: e.offer});
        },
        offerRequest: (e) => {
          _negotiate(e.connection.name, {offerRequest: true});
        },
        answer: (e) => {
          _negotiate(e.connection.name, {answer: e.answer});
        },
        candidate: (e) => {
          _negotiate(e.connection.name, {candidate: e.candidate});
        },
        message: (e) => {
          this.emit('data', {origin: e.connection.name, data: e.data});
        },
        addStream: (e) => {
          this.emit('streamAdded', {client: _getClient(e.connection.name), stream: e.stream});
        },
        removeStream: (e) => {
          this.emit('streamRemoved', {client: _getClient(e.connection.name), stream: e.stream});
        },
      };

    function updateP2pState() {
      _state.p2pActive = !!_state.clients.find((c) => {
        return c.connection && c.connection.active;
      });
    }

    if (!_options.hash) {
      throw Error('hash unknown');
    }

    if (!_options.role) {
      throw Error('role unknown');
    }

    this.socket = io(_options.socket.server, {path: _options.socket.path});
    this.role = _options.role;
    this.hash = _options.hash;
    this.p2pSupported = !!window.RTCPeerConnection && !_options.p2p.disabled;

    // create audio element for audio calls
    this.$audio = Utils.$({
      element: 'audio',
      id: 'audio',
    });
    this.$audio.setAttribute('autoplay', true);

    this.socket.on('connected', (data) => {
      Object.keys(data).forEach((key) => {
        _state[key] = data[key];
      });
      _state.connected = true;
      this.emit('connection', _state);
      if (_isActive()) {
        _state.active = true;
        this.emit('connected');
      }
      _state.clients.filter((c) => {
        return c.p2pSupported && c.socketId !== _state.socketId;
      }).forEach((peer) => {
        if (this.p2pSupported && _options.p2p.auto && this.role === 'host') {
          _connectTo({socketId: peer.socketId});
        } else {
          this.emit('peerAvailable', peer);
        }
      });
    });

    this.socket.on('peerConnected', (peer) => {
      this.emit('peerConnected', peer);
      _state.clients.push(peer);
      if (!_state.active && _isActive()) {
        _state.active = true;
        this.emit('connected');
      }
      if (this.p2pSupported && peer.p2pSupported) {
        if (_options.p2p.auto && this.role === 'host') {
          _connectTo({socketId: peer.socketId});
        } else {
          this.emit('peerAvailable', peer);
        }
      }
    });

    this.socket.on('peerDisconnected', (peer) => {
      if (!peer) return;

      var peers = _state.clients,
        dcPeer = peers.find((p) => {
          return p.socketId === peer.socketId;
        }),
        index = peers.indexOf(dcPeer);

      peers.splice(index, 1);
      this.emit('peerDisconnected', dcPeer);
      if (_state.active && !_isActive()) {
        _state.active = false;
        this.emit('disconnected');
      }
    });

    this.socket.on('data', (data) => {
      this.emit('data', data);
    });

    this.socket.on('p2pNegotiation', (e) => {
      _options.p2p.debug && console.info(e.origin, 'p2p negotiation received', e.data);
      var socketId = e.origin,
        client = _getClient(socketId),
        data = e.data;

      if (!client) return;

      if (data.offer) {
        if (!client.connection) {
          createP2PConnection({
            name: socketId,
            offer: data.offer,
            data: _options.p2p.data,
            streams: _options.p2p.streams,
            servers: _options.p2p.servers,
            debug: _options.p2p.debug
          }, _on).then(function (connection) {
            client.connection = connection;
            window.connection = connection;
          });
        } else {
          client.connection.answer(data.offer, true);
        }
      } else if (data.answer) {
        client.connection.finalize(data.answer);
      } else if (data.candidate && client.connection) {
        client.connection.addCandidate(data.candidate);
      } else if (data.offerRequest && client.connection) {
        client.connection.offer(true);
      }
    });

    this.socket.on('peerRemoved', (event) => {
      this.emit('peerRemoved', event);
    });

    var _negotiate = (socketId, data) => {
      var message = {
        target: socketId,
        data: {}
      };
      Object.keys(data).forEach((key) => {
        message.data[key] = data[key];
      });
      this.socket.emit('p2pNegotiation', message);
      _options.p2p.debug && console.info(message.target, 'p2p negotiation sent', message.data);
    };

    var _isActive = () => {
      return _state.clients.find((c) => {
        return c.role === 'host';
      }) && _state.clients.find((c) => {
        return c.role === 'client';
      });
    };

    var _send = (data) => {
      if (!this.p2pSupported || !_state.p2pActive) {
        this.socket.emit('data', data);
      } else {
        _state.clients.filter((c) => {
          return c.connection && c.connection.active && !c.connection.closed;
        }).forEach((c) => {
          try {
            if (c.connection.send) {
              c.connection.send(data);
            }
          } catch(error) {
            console.error(error);
          }
        });
        _state.clients.filter((c) => {
          return !c.connection || !c.connection.active;
        }).forEach((c) => {
          this.socket.emit('dataForTarget', {
            target: c.socketId,
            data: data
          });
        });
      }
    };

    var _getClient = (socketId) => {
      return _state.clients.find((c) => {
        return c.socketId === socketId;
      });
    };

    var _connectTo = (cfg) => {
      if (!this.p2pSupported) {
        return Promise.reject(Error('p2p not supported'));
      }
      var socketId = cfg.socketId,
        client = _getClient(socketId);
      if (!client.connection || client.connection.closed) {
        return createP2PConnection({
          name: socketId,
          data: cfg.data || _options.p2p.data,
          streams: cfg.streams || _options.p2p.streams,
          servers: _options.p2p.servers,
          debug: _options.p2p.debug
        }, _on).then((connection) => {
          client.connection = connection;
          window.connection = connection;
        });
      } else {
        return Promise.resolve(client.connection);
      }
    };

    var _disconnect = () => {
      this.socket.disconnect();
      _state.connected = false;
      _state.clients.forEach((c) => {
        if (c.connection && !c.connection.closed) {
          c.connection.close();
        }
      });
      this.emit('disconnection', _state);
    };

    var _unpackViewportData = (data) => {
      var fields = ['lt', 'lb', 'rt', 'rb', 'c'];
      var valueKeys = ['x', 'y', 'z'];
      var arr = data.split(';');
      var obj = {};
      for (var i = 0; i < fields.length; i++) {
        obj[fields[i]] = {};
        for (var j = 0; j < valueKeys.length; j++) {
          var mappedValue = this.unpackNormFloat(arr[i * valueKeys.length + j]);
          obj[fields[i]][valueKeys[j]] = mappedValue;
        }
      }
      return obj;
    };

    var _packViewportData = (viewportData) => {
      var fields = ['lt', 'lb', 'rt', 'rb', 'c'];
      var valueKeys = ['x', 'y', 'z'];
      var arr = [];
      for(var i = 0; i < fields.length; i++){
        for(var j = 0; j < valueKeys.length; j++){
          var value = viewportData[fields[i]][valueKeys[j]];
          arr.push(this.packNormFloat(value));
        }
      }
      return arr.join(';');
    };

    this.commands = {
      reload: 0,
      changeScene: 1,
      rotateCamera: 2,
      openWidget: 3,
      closeWidget: 4,
      cameraViewportChanged: 5,
      showPlumker: 6,
      pointerPosition: 7,
      closePointer: 8,
      addLineSegment: 9,
      closeLine: 10,
      clearAllLines: 11,
      changeMode: 12,
      setDrawings: 13,
      showMicMuted: 14,
      setWatching: 15,
      openLinkStart: 16,
      openLinkEnd: 17,
    };

    this.modes = {
      guide: 'guide',
      follow: 'follow',
    };

    this.send = _send;
    this.packViewportData = _packViewportData;
    this.unpackViewportData = _unpackViewportData;
    this.state = _state;
    this.connectTo = _connectTo;
    this.getClient = _getClient;
    this.disconnect = _disconnect;
  }
  /**
   * Initializes connection to socket server.
   */
  connect() {
    this.socket.emit('createStream', {streamId: this.hash, role: this.role, p2pSupported: this.p2pSupported});
  }
  /**
   * Unpack normalized float
   *
   * @param string
   * @returns {number}
   */
  unpackNormFloat(string) {
    return (2.0 * (parseInt(string, 16) / parseInt('FFFF', 16))) - 1.0;
  }
  /**
   * Pack normalized float
   * @returns {string}
   */
  packNormFloat(value) {
    return (Math.floor(Math.min(1.0, Math.max(0.0, (value + 1.0) / 2.0)) * parseInt('FFFF', 16))).toString(16);
  }
}

