/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import DesktopControls from "./DesktopControls";
import EventsEmitter from '../Utils/EventsEmitter';
import Logger from 'Utils/Logger';
import { DeviceOrientationControls } from './DeviceOrientation.js';
import VRControls from './VRControls';

const DOUBLECLICKDELAY = 300;

export default class Controls extends EventsEmitter {
  constructor({scene, camera, renderer, raycaster, elementContainer = window, forceDesktopControls = false, enableGyroscope = false }) {
    super();
    this.$l = new Logger('Controls');
    this._camera = camera;
    this._scene = scene;
    this._renderer = renderer;
    this._raycaster = raycaster;
    this._enabledEvents = true;
    this._enabledGyroscope = enableGyroscope;
    this._elementContainer = elementContainer;
    this.$ = new THREE.Object3D();
    this.$.name = 'CameraContainer';
    this._initialized = false;
    this._locked = false;
    this._cameraRotation = new THREE.Vector3(0,0,0);
    this.$.add(this._camera);
    this.leftButtonDown = false;
    this.clickTimeout = null;
    this.forceDesktopControls = forceDesktopControls;
    // Sometimes Chrome emits mousemove right after mousedown/click events, check timestamp to prevent
    this.clickTimestamp = 0;
    this.clickCoords = null;

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onRightClick = this.onRightClick.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.init = this.init.bind(this);
    this.update = this.update.bind(this);
    this.onVRSessionStart = this.onVRSessionStart.bind(this);
    this.onVRSessionEnd = this.onVRSessionEnd.bind(this);
    this.onControllerDown = this.onControllerDown.bind(this);
    this.onControllerUp = this.onControllerUp.bind(this);

    this.initPromise = new Promise((resolve) => {
      this._initPromiseResolve = resolve;
    });
  }
  init({ vrEnabled = false }) {
    if (!this._initialized) {
      this._vrEnabled = !this.forceDesktopControls && vrEnabled;

      this._elementContainer.addEventListener('touchstart', this.onTouchStart, {passive: false});
      this._elementContainer.addEventListener('touchend', this.onTouchEnd, {passive: false});
      this._elementContainer.addEventListener('touchmove', this.onTouchMove, {passive: false});
      this._elementContainer.addEventListener('mousedown', this.onMouseDown);
      this._elementContainer.addEventListener('mouseup', this.onMouseUp);
      this._elementContainer.addEventListener('mousemove', this.onMouseMove);
      this._elementContainer.addEventListener('mouseleave', this.onMouseLeave);
      this._elementContainer.addEventListener('contextmenu', this.onRightClick);
      this._elementContainer.addEventListener('drop', this.onDrop);

      // For oculus browser touchmove is fired together with mousemove when button is squeezed.
      // Detect this situation and remove mousemove event listener.
      this.mousePos = { x: null, y: null, };
      this.touchPos = { x: null, y: null, };

      this._rotateStart = new THREE.Vector2();
      this._rotateEnd = new THREE.Vector2();
      this._rotateDelta = new THREE.Vector2();

      if (this._vrEnabled || this._enabledGyroscope) {
        this._deviceOrientation = new DeviceOrientationControls();
        // this._enabledGyroscope = true;
      }

      this._vrControls = new VRControls({ scene: this._scene, renderer: this._renderer });
      this._vrControls.on('controllerDown', this.onControllerDown);
      this._vrControls.on('controllerUp', this.onControllerUp);

      this._controls = new DesktopControls({camera: this._camera, element: this._elementContainer});

      this._initialized = true;
      this._initPromiseResolve();
    }
  }
  onControllerDown(params) {
    this.emit('controllerDown', params);
  }
  onControllerUp(params) {
    this.emit('controllerUp', params);
  }
  onRightClick(e) {
    e.preventDefault();
  }
  xyToRotation(coords) {
    this._raycaster.setFromCamera(coords, this._camera);

    let dir = this._raycaster.ray.direction,
      rotation = new THREE.Vector3(-Math.asin(dir.y),
        Math.atan2(-dir.x, -dir.z),
        0);

    return rotation;
  }

  xyToCoords({x, y}) {
    let boundingRect = this._elementContainer.getBoundingClientRect();
    let top = boundingRect.top,
      left = boundingRect.left,
      coords = {
        x: ((x - left) / this._elementContainer.offsetWidth) * 2 - 1,
        y: -((y - top) / this._elementContainer.offsetHeight) * 2 + 1
      };

    return coords;
  }

  clearClick() {
    if (this.clickTimeout) {
      clearInterval(this.clickTimeout);
      this.clickTimeout = null;
      this.clickCoords = null;
    }
  }

  onMouseLeave() {
    this.leftButtonDown = false;
    this.emit('mouseLeave', {});
  }

  onMouseDown(e) {
    let coords = this.xyToCoords({x: e.clientX, y: e.clientY});
    this.leftButtonDown = true;
    this.clickTimestamp = e.timeStamp;

    if (this._enabledEvents) {
      let rotation = this.xyToRotation(coords);
      this.emit('mouseDown', coords );
      if (this.clickTimeout) {
        this.emit('click', coords );
        if(!this._enabledGyroscope) {
          this.emit('doubleClick', {coords, rotation});
        }
      } else {
        this.clickCoords = coords;
        this.clickTimeout = setTimeout(() => {
          this.clickTimeout = null;
          this.clickCoords = null;
          this.emit('click', coords );
        }, DOUBLECLICKDELAY);
      }
    }
  }

  onMouseUp() {
    this.leftButtonDown = false;

    if (this._enabledEvents) {
      this.emit('mouseUp');
    }
  }

  onMouseMove(e) {
    if (this._enabledEvents && !this.clickTimestamp || (e.timeStamp - this.clickTimestamp > 100)) {
      if (this.leftButtonDown) {
        if (this.clickTimestamp && (e.timeStamp - this.clickTimestamp > 100)) {
          this.clearClick();
        }
        this.emit('drag', this.xyToCoords({x: e.clientX, y: e.clientY}));
      }

      if (e.origin !== 'touch') {
        if (
          (this.mousePos.x && this.touchPos.x) &&
            ((this.mousePos.x === this.touchPos.x && this.mousePos.y === this.touchPos.y) ||
            (e.clientX === this.touchPos.x && e.clientY === this.touchPos.y)
            )
        ) {
          this._elementContainer.removeEventListener('mousemove', this.onMouseMove);
        }
        this.mousePos.x = e.clientX;
        this.mousePos.y = e.clientY;
      }

      this.emit('mouseMove', this.xyToCoords({x: e.clientX, y: e.clientY}));
    }
  }

  onTouchStart(e) {
    if (this._controls.controlsEnabled()) {
      this._rotateStart.set(e.touches[0].clientX, e.touches[0].clientY);
    }

    this.onMouseDown({clientX: e.touches[0].clientX, clientY: e.touches[0].clientY, timeStamp: e.timeStamp});

    if (e.cancelable) {
      e.preventDefault();
    }
    return true;
  }
  onTouchMove(e) {
    if (this._controls.controlsEnabled()) {
      this._rotateEnd.set(e.touches[0].clientX, e.touches[0].clientY);
      this._rotateDelta.subVectors(this._rotateEnd, this._rotateStart);
      this._rotateStart.copy(this._rotateEnd);
      this.rotateY(1.25 * Math.PI * this._rotateDelta.x / screen.width * 0.5);
    }

    this.touchPos.x = e.touches[0].clientX;
    this.touchPos.y = e.touches[0].clientY;

    this.onMouseMove({
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
      timeStamp: e.timeStamp,
      origin: 'touch',
    });

    if (e.cancelable) {
      e.preventDefault();
    }
    return true;
  }

  onTouchEnd(e) {
    this.onMouseUp({timeStamp: e.timeStamp});

    if (e.cancelable) {
      e.preventDefault();
    }
    return true;
  }

  onDrop(e) {
    let coords = this.xyToCoords({x: e.clientX, y: e.clientY});

    e.coords = coords;
    this.emit('drop', e);
  }

  rotateY(radY) {
    this.$.rotation.y += radY || 0;
  }

  getCameraYRotation() {
    const cameraDirection = new THREE.Vector3();
    this._camera.getWorldDirection(cameraDirection);
    this.$.worldToLocal(cameraDirection);
    let angleY = Math.atan2(-cameraDirection.x, -cameraDirection.z);

    return angleY;
  }

  rotateTo(rotation) {
    if (this._vrEnabled) {
      this.$.rotation.set(0, (rotation.y || 0) - this.getCameraYRotation() , 0);
    } else {
      this.$.rotation.set(0,0,0);
      this._controls.rotateCamera({ x: rotation.x || 0, y: rotation.y || 0 });
    }
  }

  getCameraRotation() {
    let $r = this.$.rotation,
      _camr = this._camera.rotation;

    return {x: $r.x + _camr.x, y: $r.y + _camr.y, z: $r.z + _camr.z};
  }

  update() {
    if (!this._initialized) return;

    let newCameraRotation = this.getCameraRotation();
    let pose;

    if (this._enabledGyroscope) {
      if (!this._deviceOrientation) {
        this._deviceOrientation = new DeviceOrientationControls();
      }
      pose = this._deviceOrientation.getPose();
    }

    this._controls.update(pose);

    if (newCameraRotation.x !== this._cameraRotation.x ||
      newCameraRotation.y !== this._cameraRotation.y ||
      newCameraRotation.z !== this._cameraRotation.z ) {
      this.emit('cameraRotate', newCameraRotation);
      this._cameraRotation = newCameraRotation;
    }
  }
  enable() {
    this._enabledEvents = true;
    this.enableControls();
  }
  disable() {
    this._enabledEvents = false;
    this.disableControls();
  }
  enableGyroscope() {
    this._enabledGyroscope = true;
  }
  disableGyroscope() {
    this._enabledGyroscope = false;
  }
  // Stage uses enableControls, disableControls to handle mouse events (for moving widget) and block camera motion
  setLocked(locked) {
    this._locked = locked;
  }
  enableControls() {
    if (this._controls && !this._locked) {
      this._controls.enableControls();
    }
  }
  disableControls() {
    if (this._controls && !this._locked) {
      this._controls.disableControls();
    }
  }
  remove() {
    this._elementContainer.removeEventListener('touchstart', this.onTouchStart);
    this._elementContainer.removeEventListener('touchend', this.onTouchEnd);
    this._elementContainer.removeEventListener('touchmove', this.onTouchMove);
    this._elementContainer.removeEventListener('mousedown', this.onMouseDown);
    this._elementContainer.removeEventListener('mouseup', this.onMouseUp);
    this._elementContainer.removeEventListener('mousemove', this.onMouseMove);
    this._elementContainer.removeEventListener('mouseleave', this.onMouseLeave);
    this._elementContainer.removeEventListener('contextmenu', this.onRightClick);
  }
  onVRSessionStart() {
    this.$.position.setY(-this._camera.position.y);
    this.$.add(this._vrControls.$);
  }
  onVRSessionEnd() {
    this.$.position.setY(0);
    this.$.remove(this._vrControls.$);
  }
}

