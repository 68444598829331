/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

/**
 * Class that provides events functionality.
 * To use it, inherit from it.
 */
class EventsEmitter {
  constructor() {
    this._events = {};
  }
  /**
   * Register event with name and callback
   *
   * @param eventName {String} - event name
   * @param callback {Function} - event callback
   */
  on(eventName, callback) {
    if (!this._events[eventName]) {
      this._events[eventName] = {on: [], once: []};
    }
    this._events[eventName].on.push(callback);
  }
  /**
   * The same as .on() method, but event is removed after first call.
   *
   * @param eventName {String} - event name
   * @param callback {Function} - event callback
   */
  once(eventName, callback) {
    if (!this._events[eventName]) {
      this._events[eventName] = {on: [], once: []};
    }
    this._events[eventName].once.push(callback);
  }
  /**
   * Unregister event
   *
   * @param eventName {String} - event name
   * @param callback {Function} - event callback
   */
  off(eventName, callback) {
    if (this._events[eventName]) {
      for(let i = this._events[eventName].on.length - 1; i >= 0; i--) {
        if(this._events[eventName].on[i]==callback) {
          this._events[eventName].on.splice(i, 1);
        }
      }
      for(let i = this._events[eventName].once.length - 1; i >= 0; i--) {
        if(this._events[eventName].once[i]==callback) {
          this._events[eventName].once.splice(i, 1);
        }
      }
    }
  }
  /**
   * Emit event. Used from ancestor class.
   *
   * @param eventName {String} - event name
   * @param params {Object} - event data
   */
  emit(eventName, params) {
    if (this._events[eventName]) {
      for(let i = this._events[eventName].on.length - 1; i >= 0; i--) {
        this._events[eventName].on[i](params);
      }
      for(let i = this._events[eventName].once.length - 1; i >= 0; i--) {
        let foo = this._events[eventName].once[i];
        this._events[eventName].once.splice(i, 1);
        foo(params);
      }
    }
  }
}

export default EventsEmitter;

