/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import ResourceLoader from './ResourceLoader';
import EventsEmitter from './../Utils/EventsEmitter';
import Utils from './../Utils/Utils';
import Logger from './../Utils/Logger';
import { getNextScene } from '../Utils/ProjectUtils';

export default class Project extends EventsEmitter {
  constructor({ player, ui, hqSpheresEnabled }) {
    super();
    this.$l = new Logger('Project');
    this._resourceLoader = new ResourceLoader({
      player,
      ui,
      hqSpheresEnabled,
    });
    this._resourceLoader.on('load', (e) => {
      this.emit('load', e);
    });

    this._data = {resources: {}};
    this._player = player;
  }
  setMaxAnisotropy(maxAnisotropy) {
    this._resourceLoader.setMaxAnisotropy(maxAnisotropy);
  }
  setMaxTextureSize(maxTextureSize) {
    this._resourceLoader.setMaxTextureSize(maxTextureSize);
  }
  getScene({sceneId}) {
    for(let i = 0; i < this._data.scenes.length; i++) {
      if (this._data.scenes[i].id === sceneId) {
        return this._data.scenes[i];
      }
    }
  }
  getNextScene(currentSceneId, loop) {
    return getNextScene(this._data.scenes, currentSceneId, loop)
  }
  getPreviousScene(currentSceneId) {
    try {
      let currentScenes = this._data.scenes,
        previousSceneId,
        currentSceneIndex;

      currentSceneIndex = currentScenes.findIndex((scene) => {
        return scene.id === currentSceneId;
      });

      if (currentSceneIndex > -1) {
        let j = currentSceneIndex;

        while(j > -1 && j < currentScenes.length) {
          j--;

          if (currentScenes[j].isVisible !== false) {
            previousSceneId = currentScenes[j].id;
            break;
          }
        }
      }

      return previousSceneId;
    } catch(e) {}
  }
  getWidget(widgetId, scene) {
    let getWidgetFromScene = (scene, widgetId) => {
        let foundWidget;

        if (scene && scene.widgets) {
          for(let i = 0; i < scene.widgets.length; i++) {
            if (scene.widgets[i].id == widgetId) {
              foundWidget = scene.widgets[i];
            }
          }
        }

        return foundWidget;
      },
      foundWidget,
      returnWidget;

    if (scene) {
      foundWidget = getWidgetFromScene(scene, widgetId);
    } else {
      for(let i = 0; i < this._data.scenes.length; i++) {
        foundWidget = getWidgetFromScene(this._data.scenes[i], widgetId);
        scene = this._data.scenes[i];
        if (foundWidget) break;
      }
    }

    if (foundWidget) {
      returnWidget = { ...foundWidget};
      let theme = foundWidget.details.theme || scene.theme || this._data.style.theme || 'DARK';

      returnWidget.details.theme = theme;
    }

    return returnWidget;
  }

  getSpheres({ sceneId }) {
    const scene = sceneId && this.getScene({ sceneId });
    const hasScene = !!scene;

    if (!hasScene) {
      this.$l.warn('Couldn\'t find scene: ' + sceneId);

      return;
    }

    let sphere;
    if (scene.initialSphereId) {
      sphere = scene.spheres.find(findSphere => findSphere.id === scene.initialSphereId);
    }

    if (!sphere) {
      sphere = scene.spheres[0];
    }
    const { resourceId, videoResourceId, stereoResourceId } = sphere;

    const { resources } = this._data;

    const resource = resources[videoResourceId] || resources[resourceId];
    const stereoResource = resources[stereoResourceId];

    const hasResource = !!resource;
    const hasStereoResource = !!stereoResource;

    if (!hasResource) {
      this.$l.warn('Couldn\'t find sphere for scene: ' + sceneId);
      if (resources.sphereBroken) {
        return [resources.sphereBroken];
      } else {
        return;
      }
    }

    const spheres = hasStereoResource ? [resource, stereoResource] : [resource];

    return spheres;
  }
  loadFonts() {
    var customText = "AĄaą";

    if (!document.getElementById('player-fonts')){
      var link = document.createElement('link');
      var testContainer = document.createElement('div');
      testContainer.id = 'player-fonts';

      link.rel = 'stylesheet';
      link.href = 'https://fonts.googleapis.com/css?family=';
      for (var i = 0; i < _resources.fonts.length; i++) {
        link.href += _resources.fonts[i].name;

        if (_resources.fonts[i].types && _resources.fonts[i].types.length) {
          link.href += ':' + _resources.fonts[i].types.join(',');
        }

        if (i < (_resources.fonts.length - 1)) {
          link.href += '|';
        }

        var testSpan = document.createElement('span');
        testSpan.style.fontSize = '70px';
        testSpan.style.fontFamily = _resources.fonts[i].name;
        testSpan.innerHTML = customText;
        testContainer.appendChild(testSpan);
      }
      console.log(link);
      document.body.appendChild(testContainer);
      document.head.appendChild(link);
    }

    var promises = [];
    var weightPattern = /(\d+)(i?)/;

    for (var i = 0; i < _resources.fonts.length; i++) {
      var fontFamily = _resources.fonts[i].name;
      // promises.push(new FontFaceObserver(fontFamily)
      //   .load(customText));
      if (_resources.fonts[i].types && _resources.fonts[i].types.length) {
        for (var j = 0; j < _resources.fonts[i].types.length; j++) {
          var weigth;
          if ((weigth = weightPattern.exec(_resources.fonts[i].types[j])) !== null) {
            var options = {};
            options.weight = +(weigth[1]);
            if (weigth[2] === 'i') {
              options.style = 'italic';
            }
            promises.push(new FontFaceObserver(fontFamily, options)
              .load(customText));
          }
        }
      }
    }
    return new Promise(function (resolve) {
      Promise.all(promises).then(function() {
        if (Utils.Browser.isIOS()) {
          setTimeout(function() {
            resolve();
          }, 1000);
        } else {
          resolve();
        }
      }, resolve);
    });
  }
  loadProject(projectHash) {
    this.emit('loading');
    this._resourceLoader.loadProject(projectHash).then((projectData) => {
      this.load(projectData);
    }, (err) => {
      console.$l.error(err);
    });
  }
  removeResource(resource) {
    if (!resource) {
      return;
    }

    this._disposeTexture(resource, 'thumbnail');
    this._disposeTexture(resource, 'fullsize');

    if (resource.data) {
      resource.data = null;
    }
  }
  removeResources(resourceIds) {
    for(let i in resourceIds) {
      const resId = resourceIds[i];
      this.removeResource(this._data.resources[resId]);
      delete this._data.resources[resId];
    }
  }
  remove() {
    for(let resourceId in this._data.resources) {
      let resource = this._data.resources[resourceId];
      this.removeResource(resource);
    }
    this._data.resources = {};
  }
  load(newData, targetScene, loadFonts) {
    // Remove unused resrouces
    let removeResources = [],
      addResources = {},
      oldResources = this._data.resources;

    if (oldResources) {
      for (let resId in oldResources) {
        if (!newData.resources[resId]) {
          removeResources.push(resId);
        }
      }

      this.removeResources(removeResources);
    }

    for(let resId in newData.resources) {
      if (!oldResources[resId]) {
        addResources[resId] = newData.resources[resId];
      }
    }

    this._data = newData;
    this._data.resources = oldResources;

    let loadPromise = this._resourceLoader.load(addResources, loadFonts).then((newResources) => {
      for(let resId in newResources) {
        this._data.resources[resId] = newResources[resId];
      }
      this.emit('loaded', targetScene);
    }, (err) => {
      this.$l.warn('Couldnt load resources');
    });

    return loadPromise;
  }
  removeWidget(widgetId) {
    for(let i = 0; i < this._data.scenes.length; i++) {
      for(let j = 0; j < this._data.scenes[i].widgets.length; j++) {
        let widget = this._data.scenes[i].widgets[j];

        if (widget.id === widgetId) {
          this._data.scenes[i].widgets.splice(j, 1);
          break;
        }
      }
    }
  }
  getResource(...resources) { // TODO: check calls why not catching errors
    let getRes = ({id, size}) => {
      return new Promise((resolve, reject) => {
        let res = this._data.resources[id];

        if (size) {
          if (!res[size] && size === 'fullsize') {
            res[size] = {
              loadPromise: this._resourceLoader.loadFullsizeSphere({
                resource: res, id: id
              })
            };
          }

          res = res[size];
        }

        if (res) {
          if (res.data) {
            resolve(res.data);
          } else {
            if (!res.loadPromise) reject(id);
            res.loadPromise.then(resolve, () => {
              reject(id);
            });
          }
        } else {
          reject(id);
        }
      });
    };

    if (resources.length == 1) {
      return getRes(resources[0]);
    } else {
      return new Promise(function(resolve, reject) {
        getRes(resources[0]).then(
          resolve,
          function() {
            getRes(resources[1]).then(resolve, reject);
          }
        );
      });
    }
  }
  addResource({scope, resource}) {
    return new Promise((resolve, reject) => {
      if (this._data.resources[resource.id]) {
        resolve();
      } else {
        this._data.resources[resource.id] = resource;
        this._resourceLoader.loadResource({scope, resource}).then(resolve, reject);
      }
    });
  }
  addWidget(sceneId, widgetData) {
    let scene = this.getScene({sceneId});

    if (scene && !this.getWidget(widgetData.id, scene)) {
      scene.widgets.push(widgetData);
    }
  }
  updateWidget(widgetData) {
    for(let i = 0; i < this._data.scenes.length; i++) {
      let scene = this._data.scenes[i];
      if (scene.widgets) {
        for(let j = 0; j < scene.widgets.length; j++) {
          if (scene.widgets[j].id == widgetData.id) {
            scene.widgets[j] = widgetData;
            break;
          }
        }
      }
    }
  }
  _disposeTexture(resource, size) {
    if (resource[size] && resource[size].data && resource[size].data.dispose) {
      resource[size].data.dispose();
      resource[size].data = null;
      resource[size].loadPromise = null;
    }
  }
  addScene(scene) {
    this._data.scenes.push({ ...scene });
  }
  updateScene(scene) {
    const foundScene = this._data.scenes.findIndex(findScene => findScene.id === scene.id);

    if (foundScene === -1) {
      return;
    }

    this._data.scenes[foundScene] = { ...scene };
  }
  removeScene(sceneId) {
    this._data.scenes = this._data.scenes.filter(scene => scene.id !== sceneId);
  }
}

