/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

const Easings = {
  inOutCubic: (t) => {
    let value = t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    return (value < 0 ? 0 : (value > 1 ? 1 : value));
  },
  inQuad: (t) => {
    return t * t;
  },
};

class Animation {
  constructor() {
    this._animations = [];
    this._promises = [];
    this._lastId = 0;
  }
  create({id = this._lastId, length, update, ease}) {
    let resolveFun = null,
      rejectFun = null,
	    newAnimation = {
        id,
        length,
	      update,
	      ease,
        timestamp: Date.now()
      };

    this._lastId++;

    this._animations.push(newAnimation);

    var promise = new Promise(function (resolve, reject) {
      resolveFun = resolve;
      rejectFun = reject;
    });

    this._promises.push({promise: promise, resolve: resolveFun, reject: rejectFun});
    newAnimation.promise = promise;

    return newAnimation;
  }
  tick() {
    let now = Date.now();

    for (var i = this._animations.length - 1; i > -1; i--) {
      let progress = (now - this._animations[i].timestamp) / this._animations[i].length;

      if (this._animations[i].ease) {
        progress = this._animations[i].ease(progress);
      }

      progress = Math.min(1, Math.max(0, progress));

      this._animations[i].update(progress);

      if (progress === 1) {
        this._promises[i].resolve();
        this._promises.splice(i, 1);
        this._animations.splice(i, 1);
      }
    }
  }
  stopAll() {
    for (var i = this._animations.length - 1; i > -1; i--) {
      this._promises[i].resolve();
    }

    this._promises = [];
    this._animations = [];
  }
  stop(id) {
    for (var i = this._animations.length - 1; i > -1; i--) {
      if (this._animations[i].id == id) {
        this._promises[i].resolve();
        this._promises.splice(i, 1);
        this._animations.splice(i, 1);
      }
    }
  }
  // same as stop, but rejects promise
  break(id) {
    this._animations.forEach((animation, index) => {
      if (animation.id == id) {
        this._promises[index].reject();
        this._promises.splice(index, 1);
        this._animations.splice(index, 1);
      }
    });
  }
}

export { Animation as default, Easings };

