/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Utils from 'Utils/Utils.js';

export default class Loader {
  constructor({ parent, brand = null}) {
    this.$ = Utils.$({
      cssClass: 'evr-loader'
    });

    let loaderProgress = Utils.$({
      cssClass: 'loader-progress'
    });

    var strVar="";
    
    if (brand && brand.toUpperCase() === "JLL") {
      strVar += '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-1 -1 359.2 179.9" xml:space="preserve">' +
        '<defs><style>.st0{fill:none;stroke:#4d4d4d;stroke-width:4px} .st1{fill:none;stroke:#ffffff;stroke-width:4px}</style></defs>' +
        '<path class="st0" d="M43.1,12.6c-4,0-7.7,0.8-11.1,2.3s-6.5,3.7-9.2,6.7s-4.5,6.3-5.6,9.8c-0.8,2.7-1.3,5.6-1.4,8.5h-6 ' +
        'c-2.7,0-5.2,1.1-7,2.9c-1.8,1.8-2.9,4.3-2.9,7v49.4c0,2.7,1.1,5.2,2.9,7s4.3,2.9,7,2.9h8l1.4,43.8c0,7.1,3.2,13.3,8.1,17.7 ' +
        'c5.1,4.6,12,7.4,19.2,7.4h93.3c5.2,0,10.1-1.4,14.2-3.9c4.2-2.5,7.6-6.2,10-10.8l0.6-1.1c1.2-2.3,2.9-4.1,5-5.4 ' +
        'c2.1-1.2,4.5-1.9,7.1-1.9h5c2.6,0,5,0.7,7.1,1.9s3.8,3.1,5,5.4l0.6,1.1c2.4,4.6,5.9,8.3,10,10.8c4.2,2.5,9,3.9,14.2,3.9H313 ' +
        'c6.8-0.1,13.4-2.5,18.4-6.7c5.1-4.3,8.5-10.3,8.5-17.3l0.9-45h7.3c2.7,0,5.2-1.1,7-2.9s2.9-4.3,2.9-7V49.8c0-2.7-1.1-5.2-2.9-7 ' +
        's-4.3-2.9-7-2.9h-6c-0.1-2.7-0.5-5.2-1.1-7.7c-1-3.7-2.6-7.1-5.3-10.2c-2.7-3.1-5.8-5.4-9.3-7s-7.3-2.4-11.4-2.4H213.5V9.9 ' +
        'c0-2.7-1.1-5.2-2.9-7c-1.8-1.8-4.3-2.9-7-2.9h-44.4c-2.7,0-5.2,1.1-7,2.9s-2.9,4.3-2.9,7v2.7L43.1,12.6L43.1,12.6z"/> ' +
        '<path class="st1" d="M43.1,12.6c-4,0-7.7,0.8-11.1,2.3s-6.5,3.7-9.2,6.7s-4.5,6.3-5.6,9.8c-0.8,2.7-1.3,5.6-1.4,8.5h-6 ' +
        'c-2.7,0-5.2,1.1-7,2.9c-1.8,1.8-2.9,4.3-2.9,7v49.4c0,2.7,1.1,5.2,2.9,7s4.3,2.9,7,2.9h8l1.4,43.8c0,7.1,3.2,13.3,8.1,17.7 ' +
        'c5.1,4.6,12,7.4,19.2,7.4h93.3c5.2,0,10.1-1.4,14.2-3.9c4.2-2.5,7.6-6.2,10-10.8l0.6-1.1c1.2-2.3,2.9-4.1,5-5.4 ' +
        'c2.1-1.2,4.5-1.9,7.1-1.9h5c2.6,0,5,0.7,7.1,1.9s3.8,3.1,5,5.4l0.6,1.1c2.4,4.6,5.9,8.3,10,10.8c4.2,2.5,9,3.9,14.2,3.9H313 ' +
        'c6.8-0.1,13.4-2.5,18.4-6.7c5.1-4.3,8.5-10.3,8.5-17.3l0.9-45h7.3c2.7,0,5.2-1.1,7-2.9s2.9-4.3,2.9-7V49.8c0-2.7-1.1-5.2-2.9-7 ' +
        's-4.3-2.9-7-2.9h-6c-0.1-2.7-0.5-5.2-1.1-7.7c-1-3.7-2.6-7.1-5.3-10.2c-2.7-3.1-5.8-5.4-9.3-7s-7.3-2.4-11.4-2.4H213.5V9.9 ' +
        'c0-2.7-1.1-5.2-2.9-7c-1.8-1.8-4.3-2.9-7-2.9h-44.4c-2.7,0-5.2,1.1-7,2.9s-2.9,4.3-2.9,7v2.7L43.1,12.6L43.1,12.6z"/>' +
        '</svg>';
    } else if(brand && brand.toUpperCase() === "FABB"){
      strVar += '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 248.15 753.93" xml:space="preserve">' +
        '<defs><style>.st1{fill:none;stroke:#ffffff;stroke-width:8px;stroke-miterlimit:10}</style></defs>' +
        '<path class="st1" d="M243.52,8.45c.55,4.45,.86,104.42,.41,116.1-.03,.76-.2,1.52-.35,2.6-1.9,.08-3.62,.2-5.33,.2-10.39,.02-20.79-.02-31.18,.02-8.15,.03-16.31,.26-24.38,1.53-5.52,.87-10.89,2.29-16.09,4.46-5.35,2.23-8.89,6.07-11.19,11.24-2.23,5.02-3.45,10.31-3.78,15.76-.35,5.9-.51,11.81-.57,17.72-.09,8.79-.04,17.58,0,26.37,0,1.56-.37,3.2,.84,4.98h91.6c.18,1.18,.44,2.07,.44,2.96,0,11.51,.04,23.02-.12,34.52-.13,9.05-2.3,17.7-5.82,26.02-8.45,19.94-30.26,37.33-51.11,41.15-4.89,.9-9.8,1.39-14.76,1.46-5.44,.08-10.87,0-16.31,.03-1.41,0-2.81,.14-4.32,.22-.18,1.05-.39,1.81-.42,2.59-.06,1.76-.03,3.52-.03,5.27,0,139.7,0,279.41-.02,419.11,0,2.2,.44,4.47-.54,6.77-.73,.13-1.5,.39-2.27,.39-47.49,0-94.99-.04-142.48-.09-.43,0-.86-.35-1.44-.6-.08-.75-.23-1.52-.25-2.29-.04-1.6-.02-3.2-.02-4.8,0-192.77,0-385.54-.02-578.32,0-12.16,.33-24.29,1.79-36.36,2.16-17.86,6.41-35.16,14.18-51.49,12.87-27.03,33.01-46.32,60.95-57.33,13.27-5.23,26.99-8.77,41.1-10.78,7.11-1.01,14.23-2.08,21.39-2.63,8.44-.65,16.92-.81,25.39-1.11,9.76-.35,19.55-.07,29.21,1.1,10.67,1.29,21.37,1.26,32.05,1.78,4.44,.22,8.85,.79,13.45,1.43Z"/>' +
        '</svg>';
	
    } else {
      strVar += '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 99 113" xml:space="preserve">' +
        '<defs><style>.st1{fill:none;stroke:#fcfa51;stroke-width:4px}</style></defs>' +
        '<path class="st1" d="M81,12.6c-5.4,0-9.6,4.3-9.6,9.7V46c0,3-1.5,5.5-4,6.5c-2.5,1-5.4,0.3-7.6-1.9L28,15.4 '+
        'c-3.6-3.6-9.8-3.7-13.5-0.2c-3.8,3.6-2.9,9.4,0.1,13L53,70.6l0,0.1c1.1,1.1,1.5,2.7,1,3.9c-0.5,1.3-1.8,2-3.5,2H24.2 '+
        'c-5.7,0-9.1,4.6-9.1,9.1c0,5.6,3.8,9.7,9.1,9.7H81c5.2,0,9.1-4.2,9.1-9.7V22.3C90.1,17,86,12.6,81,12.6z"/>'+
        '</svg>';
    }
    
    loaderProgress.innerHTML = strVar;

    this.$.appendChild(loaderProgress);

    if (parent) {
      parent.appendChild(this.$);
    }
  }
  set({progress = -1, error}) {
    if (!this.isHidden()) {
      if (progress > -1) {
        this.setPercentage(progress);
      }
      
      if (error === 1) {
        this.$.classList.add('isError');
      } else {
        this.$.classList.remove('isError');
      }
    } else {
      this.show();
    }
  }
  setPercentage(progress) {
    let path = this.$.querySelector('path.st1'),
      length = path.getTotalLength();
    // artificially add 10% to the progress because usually the progress cannot reach the last 10%
    progress += 10;
    if(progress > 100){
	  progress = 100;
    }
    path.style.strokeDashoffset = 5000 - length * progress /100; 
    //5000 is a value hardcoded in the stylesheet, so it must match here. we assume that any svg will not have a longer path than 5000
  }
  isHidden() {
    return this.$.className.match(/\bisHidden\b/);
  }
  show() {
    this.$.classList.remove('isHidden');
    this.$.classList.remove('isError');
  }
  hide() {
    this.setPercentage(0);
    this.$.classList.add('isHidden');
  }
}

