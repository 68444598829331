/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget';
import View from './View';
import { Config } from 'evr';
import retry from 'Utils/Retry';

// reuse geometries for better performance
let lineMaterial;

export default class VideoWidget extends Widget {
  constructor(props) {
    super(props);

    this._video = null;
    this._image = null; // displays broken video image
    this._meshContent;
    // TODO: should it have different renderOrder?
    this.renderOrder = Config.player.renderOrder.picture + props.getRenderOrderInc();

    this._state.isReady = this.createContent().then(() => {
      this.createHighlightMesh();
    });
  }

  createHighlightMesh() {
    const canCreateMesh = this.getHighlightBounds && this._meshContent;

    if (!canCreateMesh) { return; }

    let visible = false;

    if (this.highlightMesh) {
      visible = this.highlightMesh.visible;
      this._mesh.remove(this.highlightMesh);
    }

    this.highlightMesh = new THREE.Group();
    this.highlightMesh.name = 'HighlightContainer';

    const bounds = this.getHighlightBounds();

    this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

    if (!lineMaterial) {
      lineMaterial = new THREE.LineBasicMaterial({
        color: Config.player.widgets.highlightColor,
        linewidth: 2,
        transparent: true,
        depthTest: false
      });
    }

    const vertices = new Float32Array([
      bounds.min.x, bounds.min.y, 0,
      bounds.max.x, bounds.min.y, 0,
      bounds.max.x, bounds.max.y, 0,
      bounds.max.x, bounds.max.y, 0,
      bounds.min.x, bounds.max.y, 0,
      bounds.min.x, bounds.min.y, 0,
    ]);
    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

    const line = new THREE.Line(lineGeometry, lineMaterial);
    line.renderOrder = Config.player.renderOrder.highlight;

    this.highlightMesh.add(line);
    this.highlightMesh.visible = visible;
    this._mesh.add(this.highlightMesh);
  }

  getHighlightBounds() {
    this._meshContent.geometry.computeBoundingBox();
    return this._meshContent.geometry.boundingBox;
  }

  createContent() {
    const create = () => {
      const width = this._data.details.size.width;
      const height = this._data.details.size.height;
      const elements = [];

      if (this.video) {
        elements.push({
          type: 'video',
          video: this.video,
          width,
          height
        });
      }

      if (this.img) {
        elements.push({
          type: 'image',
          image: this.img,
          width,
          height
        });
      }

      if (this._meshContent) {
        this._mesh.remove(this._meshContent);
      }

      this._view = new View({ elements, width, height });
      this._view._mesh.renderOrder = this.renderOrder;

      this._meshContent = this._view._mesh;
      this._intersectArray.push(this._meshContent);
      this._mesh.add(this._meshContent);
    };

    return new Promise((resolve) => {
      const id = this._data.details.resourceId;
      const onError = () => {
        this._project.getResource({ id: 'video-broken' }).then(
          (resource) => {
            this.img = resource;
            create();
            resolve();
          }, () => {
            this.$l.warn('Video-broken not found.');
            resolve();
          }
        );
      };

      this._project.getResource({ id, size: 'thumbnail'}).then(
        (resource) => {
          this.video = resource;
          this.video.loop = true;
          retry(() => this.video.play(), 10).then(()=> {
            create();
            resolve();
          }, () => {
            delete this.video;
            this.$l.warn(`Video resource with id ${id} is broken. Requesting video-broken.`);
            onError();
          });
        }, () => {
          this.$l.warn(`Video resource with id ${id} not found. Requesting video-broken.`);
          onError();
        });
    });
  }

  remove() {
    super.remove();

    if (this._view) {
      this._view.stopVideo();
      this._view.remove();
    }
  }
}

