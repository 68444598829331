/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget';
import View from './View';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import FullscreenVideo from "../Ui/FullscreenVideo";
import retry from 'Utils/Retry';

// reuse material for better performance
let lineMaterial;

export default class InfopointWidget extends Widget {
  constructor(props) {
    super(props);

    this._meshContent; // This will be assigned in the createContent method
    this._state.totalAnimationTime = Config.player.widgets.infopointAnimationTime;
    this.animated = true;
    this.renderOrder = Config.player.renderOrder.infopoint;
    this.getRenderOrderInc = props.getRenderOrderInc;
    this.audio = null;
    this.isAudioPlaying = false;
    this.audioEnabled = props.audioEnabled;
    this.linksEnabled = props.linksEnabled;
    this.linksVisible = true;
    this.linkOpenAnimation = null;
    this.linkCloseAnimation = null;
    this.linkOpenAnimationProgress = 0;

    this._state.isReady = this.createContent();
  }
  animate(options) {
    if (this._state.initialized) {
      if (this._state.opening || this._state.closing) {
        let delta = options.delta,
          scaledProgress,
          progress = this._state.animationProgress,
          totalAnimationTime = this._state.totalAnimationTime;

        let setProgress = (progress) => {
          if (!this._data.details.animationType
            || this._data.details.animationType === "SCALE"
            || this._data.details.animationType === "NONE") {
            this._meshContent.scale.set(progress, progress, progress);
            this._mesh.getObjectByName('WidgetIcon').scale.set(1 - progress, 1 - progress, 1 - progress);

            // reset icon opacity, because it may have been opened with different animation type
            // See https://freamdev.atlassian.net/browse/WEBVR-4045
            this._icon.iconMesh.material.opacity = 1 - progress;
          } else if (this._data.details.animationType === "OPACITY") {
            this._meshContent.material.opacity = progress;
            if (this._icon) {
              this._icon.iconMesh.material.opacity = 1 - progress;

              // reset icon scale, because it may have been opened with different animation type
              // See https://freamdev.atlassian.net/browse/WEBVR-4045
              this._mesh.getObjectByName('WidgetIcon').scale.set(1, 1, 1);
            }

            if (progress < 0.05) {
              this._meshContent.scale.set(0.001, 0.001, 0.001);
            } else {
              this._meshContent.scale.set(1, 1, 1);
            }
          }
        };

        if (this._data.details.animationType === "NONE") {
          delta = Config.player.widgets.infopointAnimationTime;
        }

        if (this._state.opening) {
          progress += delta;
        } else {
          progress -= delta;
        }

        progress = (progress < 0 ? 0 : (progress > totalAnimationTime ? totalAnimationTime : progress));
        scaledProgress = Utils.easeInOutCubic(+(progress / totalAnimationTime).toFixed(2));

        if (scaledProgress === 0) {
          this._state.closed = true;
          this._state.opened = false;
          this._state.closing = false;
          scaledProgress = 0.001;
        }
        if (scaledProgress === 1) {
          this._state.closed = false;
          this._state.opened = true;
          this._state.opening = false;
          scaledProgress = 0.999;
        }
        setProgress(scaledProgress);
        this._state.animationProgress = progress;
      }
    }
  }
  open(animate) {
    super.open(animate);
    if (this._data.details && this._data.details.accessibilityText) {
      this._speaker && this._speaker.speak(this._data.details.accessibilityText);
    }

    if (this.video) {
      retry(() => this.video.play(), 10).catch((err) => {
        if(!err || err.code !== 20) {
          let error = "Video resource with id " + this._data.details.resourceId + " is broken. Requesting video-broken";
          this.$l.warn(error);
          let now = Date.now(),
            then;

          this._state.updating = true;
          this._view.videoObject.remove();
          delete this._view.videoObject;
          this._data.details.type = 'image';
          this._data.details.resourceId = 'video-broken';
          delete this.video;
          this._state.updating = false;
          this.update(['details.resourceId', 'details.type']);
          then = Date.now();
          this._state.animationProgress += (then - now);
        }
      });
    }

    if (this.audio && !this.isAudioPlaying && this.audioEnabled) {
      this.audio.play();
      this.isAudioPlaying = true;
    }
  }
  close(animate) {
    super.close(animate);
    if (this._data.details && this._data.details.accessibilityText) {
      this._speaker && this._speaker.cancel();
    }

    if (this.video) {
      this._view.stopVideo();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.isAudioPlaying = false;
    }
  }
  createHighlightMesh() {
    if (this.getHighlightBounds) {
      let visible = false;

      if (this.highlightMesh) {
        visible = this.highlightMesh.visible;
        this._mesh.remove(this.highlightMesh);
      }

      this.highlightMesh = new THREE.Group();
      this.highlightMesh.name = 'HighlightContainer';
      var bounds = this.getHighlightBounds();

      this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

      if (!lineMaterial) {
        lineMaterial = new THREE.LineBasicMaterial({
          color: Config.player.widgets.highlightColor,
          linewidth: 2,
          transparent: true,
          depthTest: false
        });
      }

      const vertices = new Float32Array([
        bounds.min.x, bounds.min.y, 0,
        bounds.max.x, bounds.min.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.min.x, bounds.max.y, 0,
        bounds.min.x, bounds.min.y, 0,
      ]);
      const lineGeometry = new THREE.BufferGeometry();
      lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

      this._disposable.push(lineGeometry);
      this._disposable.push(lineMaterial);

      var line = new THREE.Line(lineGeometry, lineMaterial);
      line.renderOrder = Config.player.renderOrder.highlight;
      this.highlightMesh.add(line);
      this.highlightMesh.visible = visible;
      this._mesh.add(this.highlightMesh);
    }
  }
  getHighlightBounds() {
    if (this._state.opening || this._state.opened) {
      this._meshContent.geometry.computeBoundingBox();
      return this._meshContent.geometry.boundingBox;
    } else if (this._icon && this._icon.backgroundMesh) {
      this._icon.backgroundMesh.geometry.computeBoundingBox();
      return this._icon.backgroundMesh.geometry.boundingBox;
    }
  }
  updateHighlightMesh() {
    this.createHighlightMesh();
  }
  createContent() {
    let create = () => {
      let elements = [];
      const backgroundColor = (this._data.details.background &&
        (this._data.details.backgroundColor || Config.player.widgets.themes[this._data.details.theme].background)
      );
      const margin = {
        top: 2,
        left: 2,
        right: 2,
        bottom: 2,
        betweenItems: 2,
        ...this._data.details.margin,
      };

      if (this._meshContent) {
        this._mesh.remove(this._meshContent);
      }

      if (this._data.details.title) {
        elements.push({
          type: 'text',
          text: this._data.details.title,
          fontSize: 6,
          color: this._data.details.fontColor || Config.player.widgets.themes[this._data.details.theme].color || '#fff'
        });
      }

      if (this.img) {
        elements.push({
          type: 'image',
          image: this.img,
          width: (this._data.details.size?this._data.details.size.width:0) || 0,
          height: (this._data.details.size?this._data.details.size.height:0) || 0,
          ratio: this._data.details.ratio,
          correction: this._data.details.correction,
        });
      }

      if (this.video) {
        elements.push({
          type: 'video',
          video: this.video,
          height: (this._data.details.size?this._data.details.size.height:0) || 0,
          ratio: this._data.details.ratio,
          color: this._data.details.color || '#ff0000'
        });
      }

      if (this._data.details.text) {
        elements.push({
          type: 'text',
          color: this._data.details.fontColor || Config.player.widgets.themes[this._data.details.theme].color || '#fff',
          text: this._data.details.text
        });
      }

      const { action, link } = this._data.details;
      const url = link && link.url;
      const text = (action && action.text) || (link && link.text);
      if ((action || url) && this.linksVisible) {
        elements.push({
          type: 'link',
          text: text,
          color: this._data.details.color || '#0000ff',
          secondaryColor: backgroundColor,
        });
      }

      const audio = this._data.details.audio;
      if (audio && audio.resourceId) {
        this._project.getResource({id: audio.resourceId})
          .then(audioUrl => {
            this.audio = new Audio(audioUrl);
          }, () => {
            this.$l.warn(`Couldn\'t find audio with resource id: ${audio.resourceId}`);
          });
      }

      this._view = new View({
        style: {
          background: backgroundColor,
          borderRadius: 2,
          fontSize: 5,
          font: this._data.details.font,
          decorations: [],
          alignement: this._data.details.textAlignment || 'left',
          color: Config.player.widgets.themes[this._data.details.theme].color || '#fff',
          margin,
        },
        elements: elements,
        width: (this._data.details.size && this._data.details.size.width ? this._data.details.size.width : 2000)
      });
      this._view._mesh.renderOrder = this.renderOrder + this.getRenderOrderInc();
      if (!this._state.opened && !this._state.opening) {
        this._view._mesh.scale.set(0.001, 0.001, 0.001);
      }
      this._meshContent = this._view._mesh;
      this._mesh.add(this._view._mesh);

      if (this._view.videoObject) {
        let renderOrder = this.renderOrder + this.getRenderOrderInc();
        this._view.videoObject.$.renderOrder = renderOrder;
        this._view.videoObject.progressMesh.renderOrder = renderOrder;

        this._intersectArray.push(this._view.videoObject.$);

        if (this._state.opening || this._state.opened || this._state.closing) {
          this.open();
        }
      }
      if (this._view._link) {
        this._view._link.$.renderOrder = this.renderOrder + this.getRenderOrderInc();
        this._intersectArray.push(this._view._link.$);
      }

      this.createHighlightMesh();
      this._intersectArray.push(this._meshContent);
    };

    return new Promise((resolve, reject) => {
      if (this._data.details.resourceId) {
        const isVideo = this._data.details.type === 'video';
        const resourceOptions = isVideo ? {id: this._data.details.resourceId, size: 'thumbnail'} : {id: this._data.details.resourceId};

        this._project.getResource(resourceOptions).then((resource) => {
          if (this._data.details.type === 'video') {
            this.video = resource;
          } else {
            this.img = resource;
          }
          create();
          resolve();
        }, (e) => {
          const resourceId = this._data.details.type === 'video' ? 'video-broken' : 'image-broken';
          let error = "Resource with id " + this._data.details.resourceId + " not found. Requesting video-broken.";
          this.$l.warn(error);

          this._project.getResource({id: resourceId}).then((img) => {
            this.img = img;
            create();
            resolve();
          }, e => {
            this.$l.warn(`${resourceId} not found.`);
            delete this.img;
            create();
            resolve();
          });
        });
      } else {
        delete this.img;
        create();
        resolve();
      }
    });
  }
  remove() {
    super.remove();
    if (this.video) {
      this._view.stopVideo();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
      this.isAudioPlaying = false;
    }
    this.removeContent();
  }
  removeContent() {
    let meshIndex = this._intersectArray.indexOf(this._meshContent);
    if (meshIndex > -1) {
      this._intersectArray.splice(meshIndex, 1);
    }

    this.animations.break('link');

    this._mesh.remove(this._meshContent);
    this._meshContent = null;

    this._view.remove();

    if (this.video) {
      delete this.video;
    }

    if (this.img) {
      delete this.img;
    }
  }
  intersectObject(options) {
    if (!options.raycaster) {
      this.$l.warn('Intersect object requires raycaster');
      return false;
    }
    let intersects = options.raycaster.intersectObjects(this._intersectArray);
    let linkClicked = this._view && this._view._link && intersects.find(intersect => intersect.object.name === 'Link');
    let videoClicked = this._view && this._view.videoObject && intersects.find(intersect => intersect.object.name === 'VideoObject');

    if (linkClicked) {
      this.onLinkOpen();
    } else if (this.onLinkClose) {
      this.onLinkClose();
    }
    if (videoClicked) {
      if(this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }

      this.videoClicks.push(videoClicked);

      this.clickTimeout = setTimeout(() => {
        if(this.videoClicks.length > 1) {
          this.onVideoFullscreen();
        }
        this.videoClicks = [];
      }, 800);
    }

    return {
      intersects: !!intersects.length,
      effective: !!(linkClicked || videoClicked),
      action: null,
    };
  }
  openLink(link) {
    this.runAction({
      type: 'openLink',
      ...link,
    });
  }
  onLinkOpen() {
    if (this._view && this._view._link && this.linksVisible && this.linksEnabled && !this.linkOpenAnimation) {
      this.animations.break('link');
      this.emit('openLinkStart');

      const isSafari = Utils.Browser.safari();
      const { action } = this._data.details;
      const eventObject = this._data.details.link ? {
        url: this._data.details.link.url,
        text: this._data.details.link.text,
      } : {
        text: this._view._link.text,
      };

      if(!isSafari) {
        this.linkOpenAnimation = this.animations.create({
          id: 'link',
          length: 1000,
          update: (progress) => {
            this.linkOpenAnimationProgress = progress;
            this._view.drawLink(progress);
          }
        });
        this.linkOpenAnimation.promise.then(() => {
          this.linkOpenAnimation = null;
          this._view.drawLink(0);
          if (action) {
            this.runAction(action);
          } else {
            this.openLink(eventObject);
          }
        }, () => {});
      } else {
        if (action) {
          this.runAction(action);
        } else {
          this.openLink(eventObject);
        }
      }
    }
  }

  onVideoFullscreen() {
    if (!this._editor && this._view && this._view.videoObject) {
      const video = this._view.videoObject.video;
      video.pause();
      this._view._fullscreenVideo = new FullscreenVideo({
        src: video.src,
        currentTime: video.currentTime,
        onClose: currentTime => {
          video.currentTime = currentTime;
          this._view._fullscreenVideo.remove();
          this._view._fullscreenVideo = null;
          video.play();
        }
      });
    }
  }

  onLinkClose() {
    if (this._view && this._view._link) {
      if (this.linkOpenAnimation) {
        this.animations.break('link');
        this.emit('openLinkEnd');
        this.linkOpenAnimation = null;

        this.linkCloseAnimation = this.animations.create({
          id: 'link',
          length: this.linkOpenAnimationProgress * 1000,
          update: (progress) => {
            let parsedProgress = (1 - progress) * this.linkOpenAnimationProgress;
            this._view.drawLink(parsedProgress);
          }
        });

        this.linkCloseAnimation.promise.then(() => {
          this.linkCloseAnimation = null;

        });
      }
    }
  }
  removeLink() {
    this.removeContent();
    this.linksVisible = false;
    this.createContent();
  }
}

