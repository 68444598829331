/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Widget from './Widget';
import GalleryView from './GalleryView';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import FullscreenVideo from "../Ui/FullscreenVideo";
import retry from 'Utils/Retry';

// reuse material for better performance
let lineMaterial;

export default class GalleryWidget extends Widget {
  constructor(props) {
    super(props);

    this._meshContent; // This will be assigned in the createContent method
    this._state.totalAnimationTime = Config.player.widgets.infopointAnimationTime;
    this.animated = true;
    this.renderOrder = Config.player.renderOrder.infopoint;
    this.getRenderOrderInc = props.getRenderOrderInc;
    this.audio = null;
    this.isAudioPlaying = false;
    this.audioEnabled = props.audioEnabled;
    this.linksEnabled = props.linksEnabled;
    this.linksVisible = true;
    this.linkOpenAnimation = null;
    this.linkCloseAnimation = null;
    this.linkOpenAnimationProgress = 0;

    this._state.isReady = this.createContent();
  }
  animate(options) {
    if (this._state.initialized) {
      if (this._state.opening || this._state.closing) {
        let delta = options.delta,
          scaledProgress,
          progress = this._state.animationProgress,
          totalAnimationTime = this._state.totalAnimationTime;

        let setProgress = (progress) => {
          if (!this._data.details.animationType
            || this._data.details.animationType === "SCALE"
            || this._data.details.animationType === "NONE") {
            this._meshContent.scale.set(progress, progress, progress);
            this._mesh.getObjectByName('WidgetIcon').scale.set(1 - progress, 1 - progress, 1 - progress);

            // reset icon opacity, because it may have been opened with different animation type
            // See https://freamdev.atlassian.net/browse/WEBVR-4045
            this._icon.iconMesh.material.opacity = 1 - progress;
          } else if (this._data.details.animationType === "OPACITY") {
            this._meshContent.material.opacity = progress;
            if (this._icon) {
              this._icon.iconMesh.material.opacity = 1 - progress;

              // reset icon scale, because it may have been opened with different animation type
              // See https://freamdev.atlassian.net/browse/WEBVR-4045
              this._mesh.getObjectByName('WidgetIcon').scale.set(1, 1, 1);
            }

            if (progress < 0.05) {
              this._meshContent.scale.set(0.001, 0.001, 0.001);
            } else {
              this._meshContent.scale.set(1, 1, 1);
            }
          }
        };

        if (this._data.details.animationType === "NONE") {
          delta = Config.player.widgets.infopointAnimationTime;
        }

        if (this._state.opening) {
          progress += delta;
        } else {
          progress -= delta;
        }

        progress = (progress < 0 ? 0 : (progress > totalAnimationTime ? totalAnimationTime : progress));
        scaledProgress = Utils.easeInOutCubic(+(progress / totalAnimationTime).toFixed(2));

        if (scaledProgress === 0) {
          this._state.closed = true;
          this._state.opened = false;
          this._state.closing = false;
          scaledProgress = 0.001;
        }
        if (scaledProgress === 1) {
          this._state.closed = false;
          this._state.opened = true;
          this._state.opening = false;
          scaledProgress = 0.999;
        }
        setProgress(scaledProgress);
        this._state.animationProgress = progress;
      }
    }
  }
  open(animate, context = {}) {
    const { opened } = this._state;
    let { slideId } = context;
    let promise = Promise.resolve();
    slideId = slideId || this._data.details.slides[0].id;
    if (this.slideId !== slideId) {
      this.close();
      this.removeContent();
      promise = this.createContent(slideId);
    }
    super.open(opened ? false : animate, context);
    return promise.then(() => {
      if (this._data.details && this._data.details.accessibilityText) {
        this._speaker && this._speaker.speak(this._data.details.accessibilityText);
      }

      if (this.video) {
        retry(() => this.video.play(), 10).catch((err) => {
          if (!err || err.code !== 20) {
            this.$l.warn("Video resource with id " + this._data.details.resourceId + " is broken");
          }
        });
      }

      if (this.audio && !this.isAudioPlaying && this.audioEnabled) {
        this.audio.play();
        this.isAudioPlaying = true;
      }
    });
  }
  close(animate) {
    super.close(animate);
    if (this._data.details && this._data.details.accessibilityText) {
      this._speaker && this._speaker.cancel();
    }

    if (this.video) {
      this._view.stopVideo();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.isAudioPlaying = false;
    }
  }
  createHighlightMesh() {
    if (this.getHighlightBounds) {
      let visible = false;

      if (this.highlightMesh) {
        visible = this.highlightMesh.visible;
        this._mesh.remove(this.highlightMesh);
      }

      this.highlightMesh = new THREE.Group();
      this.highlightMesh.name = 'HighlightContainer';
      var bounds = this.getHighlightBounds();

      this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

      if (!lineMaterial) {
        lineMaterial = new THREE.LineBasicMaterial({
          color: Config.player.widgets.highlightColor,
          linewidth: 2,
          transparent: true,
          depthTest: false
        });
      }

      const vertices = new Float32Array([
        bounds.min.x, bounds.min.y, 0,
        bounds.max.x, bounds.min.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.min.x, bounds.max.y, 0,
        bounds.min.x, bounds.min.y, 0,
      ]);
      const lineGeometry = new THREE.BufferGeometry();
      lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

      this._disposable.push(lineGeometry);
      this._disposable.push(lineMaterial);

      var line = new THREE.Line(lineGeometry, lineMaterial);
      line.renderOrder = Config.player.renderOrder.highlight;
      this.highlightMesh.add(line);
      this.highlightMesh.visible = visible;
      this._mesh.add(this.highlightMesh);
    }
  }
  getHighlightBounds() {
    if (this._state.opening || this._state.opened) {
      this._meshContent.geometry.computeBoundingBox();
      return this._meshContent.geometry.boundingBox;
    } else if (this._icon && this._icon.backgroundMesh) {
      this._icon.backgroundMesh.geometry.computeBoundingBox();
      return this._icon.backgroundMesh.geometry.boundingBox;
    }
  }
  updateHighlightMesh() {
    this.createHighlightMesh();
  }
  createContent(slideId) {
    slideId = slideId || this._state.slideId;
    const { slides, hidePagination, size, font, fontSize, fontColor, textAlign } = this._data.details;
    const data = slides.find(s => s.id === slideId) || slides[0];
    const slideIndex = slides.indexOf(data);
    const { width, height } = size;
    const { columnWeights } = data;
    const columns = [];
    const backgroundColor = (this._data.details.background && this._data.details.backgroundColor)
      || Config.player.widgets.themes[this._data.details.theme].background;
    const margin = {
      top: 2,
      left: 2,
      right: 2,
      bottom: 2,
      betweenItems: 2,
      betweenColumns: 4,
      ...this._data.details.margin,
    };

    if (this._meshContent) {
      this._mesh.remove(this._meshContent);
    }

    this.slideId = data.id;
    const sectionsPromises = data.sections.map((section) => {
      if (section.type === 'text') {
        return Promise.resolve({
          ...section,
          textAlign: section.textAlign || textAlign || 'left',
          font: section.font || font,
          fontSize: section.fontSize || fontSize || 8,
          color: section.color || fontColor || Config.player.widgets.themes[this._data.details.theme].color || '#fff',
        });
      } else if (section.type === 'media') {
        if (!section.resourceId) {
          return this._project.getResource({ id: 'image-add' }).then((image) => ({
            type: 'image',
            column: section.column,
            height: section.height,
            image,
          }));
        } else if (section.resourceType === 'video') {
          return this._project.getResource({ id: section.resourceId, size: 'thumbnail' }).then((video) => {
            this.video = video;
            return {
              type: 'video',
              column: section.column,
              height: section.height,
              ratio: section.ratio,
              color: this._data.details.color || '#ff0000',
              video,
            };
          }, () => this._project.getResource({ id: 'video-broken' }).then((image) => ({
            type: 'image',
            column: section.column,
            height: section.height,
            image,
          })));
        } else if (section.resourceType === 'picture') {
          return this._project.getResource({ id: section.resourceId })
            .catch(() => this._project.getResource({ id: 'image-broken' }))
            .then((image) => ({
              type: 'image',
              column: section.column,
              height: section.height || 0,
              correction: section.correction,
              ratio: section.ratio,
              image,
            }));
        }
      } else if (section.type === 'button') {
        return (section.action.type !== '' || this.linksVisible)
          ? Promise.resolve({
            ...section,
            secondaryColor: backgroundColor,
          })
          : Promise.resolve(false);
      }
    });
    if (!hidePagination && slides.length) {
      sectionsPromises.push(Promise.all([
        this._project.getResource({ id: 'chevron-circle-left' }),
        this._project.getResource({ id: 'chevron-circle-right' }),
      ]).then((arrows) => ({
        type: 'pagination',
        arrows,
        slideIndex,
        slides,
        previousId: slides[slideIndex - 1] ?  slides[slideIndex - 1].id : null,
        nextId: slides[slideIndex + 1] ?  slides[slideIndex + 1].id : null,
        widgetId: this._data.id,
      })));
    }
    return Promise.all(sectionsPromises).then((sections) => {
      const pagination = sections.find(s => s && s.type === 'pagination');
      if (data.columns === 1) {
        columns[0] = sections.filter(s => s);
      } else {
        let i = 0;
        while (i < data.columns) {
          columns.push(sections.filter(s => s && s.column === i));
          i++;
        }
      }

      const { audio } = data;
      if (audio && audio.resourceId) {
        this._project.getResource({ id: audio.resourceId, variant: 'AUDIO' }).then(audioUrl => {
          this.audio = new Audio(audioUrl);
        }, () => {
          this.$l.warn(`Couldn\'t find audio with resource id: ${audio.resourceId}`);
        });
      }

      this._view = new GalleryView({
        columns,
        columnWeights: data.columns > 1
          ? columnWeights
          : [1],
        style: {
          background: backgroundColor,
          borderRadius: 2,
          font: this._data.details.font,
          fontSize: this._data.details.fontSize,
          fontWeight: this._data.details.fontWeight,
          color: Config.player.widgets.themes[this._data.details.theme].color || '#fff',
          accentColor: this._data.details.color,
          margin,
        },
        width: width || 2000,
        height: height || null,
        pagination,
      });
      this._view._mesh.renderOrder = this.renderOrder + this.getRenderOrderInc();
      if (!this._state.opened && !this._state.opening) {
        this._view._mesh.scale.set(0.001, 0.001, 0.001);
      }
      this._meshContent = this._view._mesh;
      this._mesh.add(this._view._mesh);

      if (this._view.videoObject) {
        let renderOrder = this.renderOrder + this.getRenderOrderInc();
        this._view.videoObject.$.renderOrder = renderOrder;
        this._view.videoObject.progressMesh.renderOrder = renderOrder;

        this.addToIntersecting(this._view.videoObject.$);

        // if (this._state.opening || this._state.opened || this._state.closing) {
        //   this.open();
        // }
      }
      this._view.buttons.forEach((button) => {
        button.$.renderOrder = this.renderOrder + this.getRenderOrderInc();
        this.addToIntersecting(button.$);
      });
      this.createHighlightMesh();
      this.addToIntersecting(this._meshContent);
      this._state.slideId = slideId;
    });
  }
  remove() {
    super.remove();
    if (this.video) {
      this._view.stopVideo();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
      this.isAudioPlaying = false;
    }
    this.removeContent();
  }
  removeContent() {
    const { buttons, videoObject } = this._view;
    this.removeFromIntersecting(this._meshContent);
    buttons.forEach(button => this.removeFromIntersecting(button.$));
    if (videoObject) {
      this.removeFromIntersecting(videoObject.$);
    }
    this._mesh.remove(this._meshContent);
    this._meshContent = null;
    this._view.remove();
    if (this.video) {
      delete this.video;
    }
    if (this.audio) {
      delete this.audio;
    }
  }

  intersectObject(options) {
    if (!options.raycaster) {
      this.$l.warn('Intersect object requires raycaster');
      return false;
    }
    let intersects = options.raycaster.intersectObjects(this._intersectArray);
    let buttonClicked = this._view && intersects.find(intersect => intersect.object.action);
    let videoClicked = this._view && this._view.videoObject && intersects.find(intersect => intersect.object.name === 'VideoObject');

    if (videoClicked) {
      if(this.video.paused) {
        this.video.play();
      } else {
        this.video.pause();
      }

      this.videoClicks.push(videoClicked);

      this.clickTimeout = setTimeout(() => {
        if(this.videoClicks.length > 1) {
          this.onVideoFullscreen();
        }
        this.videoClicks = [];
      }, 800);
    }

    return {
      intersects: !!intersects.length,
      effective: !!(buttonClicked || videoClicked),
      action: buttonClicked && buttonClicked.object.action,
    };
  }

  onVideoFullscreen() {
    if (!this._editor && this._view && this._view.videoObject) {
      const video = this._view.videoObject.video;
      video.pause();
      this._view._fullscreenVideo = new FullscreenVideo({
        src: video.src,
        currentTime: video.currentTime,
        onClose: currentTime => {
          video.currentTime = currentTime;
          this._view._fullscreenVideo.remove();
          this._view._fullscreenVideo = null;
          video.play();
        }
      });
    }
  }
}

