/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Logger from 'Utils/Logger';
import { Config } from 'evr';
import Utils from './Utils/Utils';

function offloadVideo(video) {
  const src = video.src;
  video.removeAttribute('src');
  video.load();
  video.setAttribute('src', src);
}

const vertexShader = `
  varying vec3 texCoords;
  void main() {
    texCoords = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;
const fragmentShader = `
  precision mediump float;
  uniform sampler2D sphereTexture;
  uniform float brightness;
  uniform float contrast;
  uniform float gamma;
  uniform float saturation;
  varying vec3 texCoords;

  void main() {
    vec2 longitudeLatitude = vec2((atan(texCoords.x, texCoords.z) / 3.14159265358979323844 + 1.0) * 0.5, (asin(texCoords.y) / 3.14159265358979323844 + 0.5));
    float t = (1.0 - contrast) / 2.0;
    vec4 color = texture2D(sphereTexture, longitudeLatitude);
    mat4 brightnessMatrix = mat4(1, 0, 0, 0,
                                0, 1, 0, 0,
                                0, 0, 1, 0,
                                brightness, brightness, brightness, 1);
    mat4 contrastMatrix = mat4(contrast, 0, 0, 0,
                                0, contrast, 0, 0,
                                0, 0, contrast, 0,
                                t, t, t, 1);
    vec3 luminance = vec3(0.3086, 0.6094, 0.0820);
    float oneMinusSat = 1.0 - saturation;
    vec3 red = vec3(luminance.x * oneMinusSat);
    red += vec3(saturation, 0, 0);
    vec3 green = vec3(luminance.y * oneMinusSat);
    green += vec3(0, saturation, 0);
    vec3 blue = vec3(luminance.z * oneMinusSat);
    blue += vec3(0, 0, saturation);
    mat4 saturationMatrix = mat4(red,     0,
                                green,   0,
                                blue,    0,
                                0, 0, 0, 1);
    vec4 newcolor = vec4(pow(color.r,gamma), pow(color.g,gamma), pow(color.b,gamma), 1.0);
    gl_FragColor = brightnessMatrix * contrastMatrix * saturationMatrix * newcolor;
  }
`;

export default class Sphere {
  constructor(settings) {
    this.$l = new Logger('Sphere');
    this._project = settings.project;
    this.videoSphericalEnabled = settings.videoSphericalEnabled;
    this.$ = new THREE.Group();
    this.$.name = 'SphereContainer';
    this._state = settings.state;
    this._player = settings.project._player;
    const scene = settings.project._data.scenes && settings.state && settings.project._data.scenes.find(s => s.id === settings.state.sceneId);

    const uniforms = {
      sphereTexture: { type: "t", value: undefined },
      brightness: { type: "f", value: (scene && scene.correction && !isNaN(scene.correction.brightness)) ? scene.correction.gamma : 0.0 },
      contrast: { type: "f", value: (scene && scene.correction && !isNaN(scene.correction.contrast)) ? scene.correction.gamma : 1.0 },
      gamma: { type: "f", value: (scene && scene.correction && !isNaN(scene.correction.gamma)) ? scene.correction.gamma : 1.0 },
      saturation: { type: "f", value: (scene && scene.correction && !isNaN(scene.correction.saturation)) ? scene.correction.saturation : 1.0 }
    };

    let geometry = new THREE.SphereGeometry(1, 60, 60);
    let material = null;

    const useBasicMaterial = Config.player.sphereBasicMaterial || Utils.Browser.shaderMaterialUnsupported;

    if (useBasicMaterial) {
      material = new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
        side: THREE.BackSide,
      });
    } else {
      material = new THREE.ShaderMaterial({
        uniforms,
        vertexShader,
        fragmentShader,
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
        side: THREE.BackSide,
      });
    }

    this._sphereMesh = new THREE.Mesh(geometry, material);
    const scaleFactor = 1000;
    this._sphereMesh.scale.x = -1 * scaleFactor;
    this._sphereMesh.scale.y = scaleFactor;
    this._sphereMesh.scale.z = scaleFactor;
    this._sphereMesh.name = "Sphere";
    this._sphereMesh.renderOrder = 0;
    this._sphereMesh.layers.set(1);

    let geometry2 = new THREE.SphereGeometry(1, 60, 60);
    let material2 = null;
    if (useBasicMaterial) {
      material2 = new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
        side: THREE.BackSide,
      });
    } else {
      material2 = new THREE.ShaderMaterial({
        uniforms,
        vertexShader,
        fragmentShader,
        transparent: true,
        opacity: 1,
        depthWrite: false,
        depthTest: false,
        side: THREE.BackSide,
      });
    }
    this._stereoSphereMesh = new THREE.Mesh(geometry2, material2);
    this._stereoSphereMesh.scale.x = -1 * scaleFactor;
    this._stereoSphereMesh.scale.y = scaleFactor;
    this._stereoSphereMesh.scale.z = scaleFactor;
    this._stereoSphereMesh.name = "StereoSphere";
    this._stereoSphereMesh.renderOrder = 0;
    this._stereoSphereMesh.layers.set(3);

    // Rotate sphere if it is a basic material
    if (useBasicMaterial) {
      this._sphereMesh.rotation.set(0, Math.PI / 2, 0);
      this._stereoSphereMesh.rotation.set(0, Math.PI / 2, 0);
    }

    //Create overlay mesh
    var overlayGeometry = new THREE.SphereGeometry(1, 60, 60),
      overlayMaterial = new THREE.MeshBasicMaterial({
        color: 0xFFFFFF,
        opacity: 0,
        transparent: true,
        depthWrite: false,
        depthTest: false,
        side: THREE.BackSide,
      });

    this._overlayMesh = new THREE.Mesh(overlayGeometry, overlayMaterial);
    this._overlayMesh.name = 'Overlay';
    this._overlayMesh.renderOrder = Config.player.renderOrder.overlay;

    this.$.add(this._overlayMesh);
    this.$.add(this._stereoSphereMesh);
    this.$.add(this._sphereMesh);

    this._currentSphere = {
      resourceId: null,
      stereoResourceId: null
    };
  }
  updateVideoTexture(texture) {
    const video = texture.image;
    const canPlayThrough = video.readyState === 4 || video.readyState === 3; // 'HAVE_ENOUGH_DATA' || 'can watch something'

    video.muted = !this.videoSphericalEnabled;

    const play = () => {
      video.play().then(()=> {
        const eventListener = () => {
          video.pause();

          video.removeEventListener('progress', eventListener);
        };

        if (!this.videoSphericalEnabled) {
          video.addEventListener('progress', eventListener);
        }
      }, () => {
        this.$l.error('Cannot play video sphere');
      });
    };

    if (canPlayThrough) {
      play();
      return;
    }

    const onCanPlay = () => {
      video.oncanplaythrough = () => {};
      video.onloadedmetadata = () => {};
      video.oncanplay = () => {};
      play();
    };

    video.oncanplaythrough = onCanPlay;
    video.onloadedmetadata = onCanPlay;
    video.oncanplay = onCanPlay;

    video.setAttribute('preload', 'auto');
  }
  updateTexture(type, texture, resourceId) {
    const isVideoTexture = texture && texture.image instanceof HTMLVideoElement;
    texture.minFilter = THREE.LinearFilter;
    let mesh = (type=='MESH'?this._sphereMesh:this._stereoSphereMesh);

    if (
      (type=='MESH' && this._currentSphere.resourceId!==resourceId) ||
      (type=='STEREOMESH' && this._currentSphere.stereoResourceId!==resourceId)
    ) {
      return;
    }

    if (mesh.material.uniforms && mesh.material.uniforms.sphereTexture && mesh.material.uniforms.sphereTexture.value instanceof THREE.Texture) {
      mesh.material.uniforms.sphereTexture.value.dispose();
    } else {
      if (mesh.material.map) {
        mesh.material.map.dispose();
      }
    }
    if (mesh.material.uniforms) {
      mesh.material.uniforms.sphereTexture.value = texture;
    } else {
      mesh.material.map = texture;
    }
    mesh.material.needsUpdate = true;

    if (!isVideoTexture) { return; }

    return this.updateVideoTexture(texture);
  }
  updateSphere(type, resourceId, isVideoSphere = false) {
    let fullsizeSwitched = false;
    let thumbnailSwitched = false;

    this._project.getResource({id: resourceId, size: 'thumbnail'}).then((resource) => {
      if (!fullsizeSwitched) {
        thumbnailSwitched = true;
        this.updateTexture(type, resource, resourceId);
      }
    }, () => {
      this.$l.warn('Thumbnail didn\'t load');
    });

    if (!isVideoSphere) { // For video sphere just use thumbnail size. In other cases fetch fullsize too
      this._project.getResource({id: resourceId, size: 'fullsize'}).then((resource) => {
        fullsizeSwitched = true;
        this.updateTexture(type, resource, resourceId);
      }, () => {
        this.$l.warn('Sphere didn\'t load');
        if (!thumbnailSwitched) {
          const resourceId = 'sphereBroken';
          this._project.getResource({id: resourceId, size: 'fullsize'})
            .then((resource) => {
              this._currentSphere.resourceId = resourceId;
              this.updateTexture(type, resource, resourceId);
            }, () => {
              this.$l.warn('SphereBroken didn\'t load');
            });
        }
      });
    }
  }
  setSphere(sphereResourceIds) {
    const canSetSphere = sphereResourceIds && sphereResourceIds.length;

    if (!canSetSphere) { return; }

    const isVideoSphere = sphereResourceIds[0].type === '@ContentVideoSphere';
    // offload previous video sphere
    if (this._sphereMesh.material.uniforms &&
      this._sphereMesh.material.uniforms.sphereTexture.value &&
      this._sphereMesh.material.uniforms.sphereTexture.value.image instanceof HTMLVideoElement) {
      const video = this._sphereMesh.material.uniforms.sphereTexture.value.image;
      video.pause();
      offloadVideo(video);
    }
    // offload previous video sphere
    if (this._sphereMesh.material.map &&
      this._sphereMesh.material.map.image instanceof HTMLVideoElement) {
      const video = this._sphereMesh.material.map.image;
      video.pause();
      offloadVideo(video);
    }

    const scene = this._project._data.scenes && this._state.sceneId && this._project._data.scenes.find(s => s.id === this._state.sceneId);
    if (scene) {
      const brightness = scene.correction && !isNaN(scene.correction.brightness) ? scene.correction.brightness : 0;
      const contrast = scene.correction && !isNaN(scene.correction.contrast) ? scene.correction.contrast : 1;
      const gamma = scene.correction && !isNaN(scene.correction.gamma) ? scene.correction.gamma : 1;
      const saturation = scene.correction && !isNaN(scene.correction.saturation) ? scene.correction.saturation : 1;

      if (this._stereoSphereMesh.material.uniforms) {
        this._stereoSphereMesh.material.uniforms.brightness.value = brightness;
        this._stereoSphereMesh.material.uniforms.contrast.value = contrast;
        this._stereoSphereMesh.material.uniforms.gamma.value = gamma;
        this._stereoSphereMesh.material.uniforms.saturation.value = saturation;
        this._stereoSphereMesh.material.needsUpdate = true;
      }
      if (this._sphereMesh.material.uniforms) {
        this._sphereMesh.material.uniforms.brightness.value = brightness;
        this._sphereMesh.material.uniforms.contrast.value = contrast;
        this._sphereMesh.material.uniforms.gamma.value = gamma;
        this._sphereMesh.material.uniforms.saturation.value = saturation;
        this._sphereMesh.material.needsUpdate = true;
      }
    }

    this._currentSphere.resourceId = sphereResourceIds[0].id;

    this.updateSphere('MESH', this._currentSphere.resourceId, isVideoSphere);

    if (sphereResourceIds.length > 1) {
      this._currentSphere.stereoResourceId = sphereResourceIds[1].id;
      this._player.isVRCompatible().then((isCompatible) => {
        if (isCompatible) {
          this.updateSphere('STEREOMESH', this._currentSphere.stereoResourceId);
        }
      });

      this._stereoSphereMesh.layers.set(2);
    } else {
      this._currentSphere.stereoResourceId = null;
      this._sphereMesh.layers.set( 0 );
      this._stereoSphereMesh.layers.set(3);
    }
  }
  remove() {
    this._overlayMesh.material.dispose();
    this._overlayMesh.geometry.dispose();

    this._stereoSphereMesh.material.dispose();
    this._stereoSphereMesh.geometry.dispose();
    this._stereoSphereMesh.texture && this._stereoSphereMesh.texture.dispose();

    if (
      this._sphereMesh.material.uniforms &&
      this._sphereMesh.material.uniforms.sphereTexture.value &&
      this._sphereMesh.material.uniforms.sphereTexture.value.image instanceof HTMLVideoElement) {
      this._sphereMesh.material.uniforms.sphereTexture.value.image.pause();
      this._sphereMesh.material.uniforms.sphereTexture.value.image.src = '';
    } else if (this._sphereMesh.material.map && this._sphereMesh.material.map.dispose) {
      this._sphereMesh.material.map.dispose();
    }

    this._sphereMesh.material.dispose();
    this._sphereMesh.geometry.dispose();
    this._sphereMesh.texture && this._sphereMesh.texture.dispose();
  }
}

