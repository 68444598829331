/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import Host, {Tools as _Tools} from "./Controller/Host";
import _Logger from "./Utils/Logger";
import _Client from "./Controller/Client";
import _Utils from "./Utils/Utils";
import _Player from "./player";
import { Controller as _Controller } from "./Controller/Controller";

/**
 * Viewing namespace
 *
 * @namespace Viewing
 */
export const Viewing = {
  Tools: _Tools,
  Host: Host,
  Client: _Client,
  Controller: _Controller,
};

export const Utils = _Utils;

export const Player = _Player;

export const Logger = _Logger;

export const Config = __CONFIG__; // CFG is replaced with /configs/[cfg].config.js

