/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

const resolveQueuedItems = function(items, resolveItemPromise, simultaneousItems, options) {
  return new Promise((resolve, reject) => {
    const { initialize, onFinish } = options;
    let lastQueuedIndex = simultaneousItems - 1;

    if (initialize) {
      initialize();
    }

    const resolveNextFunction = () => {
      if (lastQueuedIndex < Object.keys(items).length) {
        resolveQueuedLoad(++lastQueuedIndex);
      } else {
        if (onFinish) {
          onFinish(resolve, reject);
        }
      }
    };

    const resolveQueuedLoad = (index) => {
      if (index < items.length) {
        const promise = resolveItemPromise(index);
        if (items[index] && promise) {
          promise.then(() => {
            resolveNextFunction();
          }).catch(() => {
            resolveNextFunction();
          });
        } else {
          resolveNextFunction();
        }
      }
    };

    for (let i = 0; i < simultaneousItems; i++) {
      resolveQueuedLoad(i);
    }
  });
};

export default resolveQueuedItems;

