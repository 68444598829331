/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import Icon from './Icon';
import Label from './Label';
import {Config} from 'evr';
import Logger from 'Utils/Logger';
import Utils from 'Utils/Utils';

// reuse material for better performance
let lineMaterial;

export default class Widget extends EventsEmitter {
  constructor(props) {
    super(props);
    this.$l = new Logger('Widget');
    this._project = props.project;
    this._data = this._project.getWidget(props.data.id);
    this.$ = new THREE.Group(); //container - add only widget body
    this.$.name = "WidgetContainer";
    this.animated = false;
    this._mesh = new THREE.Group(); //widget body container - add all widget elements here
    this._mesh.name = "WidgetBody";
    this._state = {
      initialized: false,
      updating: false,
      closed: true,
      opened: false,
      opening: false,
      closing: false,
      animationProgress: 0,
      iconAnimationProgress: 0,
      mouseOver: false,
      backgroundAnimationProgress: 0
    };
    this._editor = props.editor;
    this._intersectArray = [];
    this._disposable = [];
    this.animations = props.animations;
    this.videoClicks = [];
    this.clickTimeout = null;

    this.$.add(this._mesh);

    if (this._data.details.icon) {
      this._icon = new Icon({
        resourceId: this._data.details.icon,
        project: this._project,
        style: {
          color: this._data.details.color,
          theme: this._data.details.theme
        }
      });

      this._icon.isReady.then(() => {
        if (!this._editor) {
          this._icon.$.getObjectByName('WidgetIconBackground').material.opacity = 0;
        }

        this.createHighlightMesh();
        this._mesh.add(this._icon.$);

        this._intersectArray.push(this._icon.backgroundMesh);
      });
    }

    if (this._data.label) {
      this.createLabel();
    }
  }
  createLabel() {
    if (this._label || !this._data.label) {
      return;
    }

    this._label = new Label({
      text: this._data.label,
      position: this._data.details.labelPosition,
      font: this._data.details.labelFont,
      size: this._data.details.labelSize,
      style: {
        color: this._data.details.labelColor || this._data.details.color,
        theme: this._data.details.theme
      }
    });

    if (!this._editor) {
      this._label.$.getObjectByName('WidgetLabel').material.opacity = 0;
    }
    this._mesh.add(this._label.$);
  }
  removeLabel() {
    if (!this._label) return;
    this._mesh.remove(this._label.$);
    this._label.remove();
    this._label = null;
  }
  createHighlightMesh() {
    if (this.getHighlightBounds) {
      if (this.highlightMesh) {
        this._mesh.remove(this.highlightMesh);
      }
      this.highlightMesh = new THREE.Group();
      this.highlightMesh.name = 'Highlight';
      var bounds = this.getHighlightBounds();

      this.highlightMesh.renderOrder = Config.player.renderOrder.highlight;

      if (!lineMaterial) {
        lineMaterial = new THREE.LineBasicMaterial({
          color: Config.player.widgets.highlightColor,
          linewidth: 2,
          transparent: true,
          depthTest: false
        });
      }

      const vertices = new Float32Array([
        bounds.min.x, bounds.min.y, 0,
        bounds.max.x, bounds.min.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.max.x, bounds.max.y, 0,
        bounds.min.x, bounds.max.y, 0,
        bounds.min.x, bounds.min.y, 0,
      ]);
      const lineGeometry = new THREE.BufferGeometry();
      lineGeometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

      const line = new THREE.Line(lineGeometry, lineMaterial);
      line.renderOrder = Config.player.renderOrder.highlight;
      this.highlightMesh.add(line);
      this.highlightMesh.visible = false;
      this._mesh.add(this.highlightMesh);
      this._disposable.push(lineGeometry);
      this._disposable.push(lineMaterial);
    }
  }
  getHighlightBounds() {
    if (this._icon && this._icon.backgroundMesh) {
      this._icon.backgroundMesh.geometry.computeBoundingBox();
      return this._icon.backgroundMesh.geometry.boundingBox;
    }
  }
  getState() {
    return this._state;
  }
  updateRotationPosition() {
    this.$.setRotationFromQuaternion(new THREE.Quaternion(0, 0, 0, 1));
    this.$.rotateY(this._data.rotation.y);
    this.$.rotateX(-this._data.rotation.x);
    this.$.rotateZ(this._data.rotation.z);
    this.$.updateMatrixWorld(true);
  }
  updateTilt() {
    this._mesh.setRotationFromEuler(Utils.parseXYZToRadiansEuler(this._data.details.tilt || {}));
  }
  updateDistance() {
    var distance = Config.player.sphereRadius - (this._data.details.distance || 0);
    this._mesh.position.set(0, 0, -distance);
    var scale = distance / Config.player.sphereRadius;
    //this._mesh.scale.set(scale, scale, scale);
  }
  update(diff) {
    return new Promise((resolve, reject) => {
      if (this._state.updating) {
        reject('Widget is updating already.');
        return;
      }

      this._state.updating = true;
      if (diff.indexOf('details.color') > -1) {
        if (this._icon) {
          this._icon.style.color = this._data.details.color;
          this._icon.drawIcon();
        }
      }

      if (diff.indexOf('details.theme') > -1) {
        if (this._icon) {
          this._icon.setTheme(this._data.details.theme);
        }
        if (this._label) {
          this._label.style.theme = this._data.details.theme;
          this._label.update();
        }
      }

      if (diff.indexOf('details.icon') > -1 && this._icon) {
        this._icon.resourceId = this._data.details.icon;
        this._icon.update();
      }

      if (this._data.details.icon && this._icon) {
        this._icon.isReady.then(() => {
          this._intersectArray.push(this._icon.backgroundMesh);
        });
      }

      if (diff.indexOf('label') > -1 ||
        diff.indexOf('details.labelPosition') > -1 ||
        diff.indexOf('details.labelFont') > -1 ||
        diff.indexOf('details.labelSize') > -1 ||
        diff.indexOf('details.color') > -1 ||
        diff.indexOf('details.labelColor') > -1
      ) {
        if (this._label) {
          this._label.text = this._data.label;
          this._label.position = this._data.details.labelPosition;
          this._label.font = this._data.details.labelFont;
          this._label.size = this._data.details.labelSize;
          this._label.style.color = this._data.details.labelColor || this._data.details.color;
          if (this._label.text) {
            this._label.update();
          } else {
            this.removeLabel();
          }
        } else {
          this.createLabel();
        }
      }

      if (diff.indexOf('details.distance') > -1) {
        this.updateDistance();
      }

      if (this.createContent) {
        this._intersectArray = [];
        if (this.removeContent) {
          this.removeContent();
        }
        this.createContent().then(() => {
          this.createHighlightMesh();
          this._intersectArray.push(this._meshContent);
          this._state.updating = false;
          resolve();
        }, () => {
          this._state.updating = false;
          reject();
        });
      } else {
        this._state.updating = false;
        resolve();
      }
    });
  }

  clearClick() {
    if (this.clickTimeout) {
      clearInterval(this.clickTimeout);
      this.clickTimeout = null;
    }
  }

  addToIntersecting(element) {
    this._intersectArray.push(element);
  }

  removeFromIntersecting(element) {
    let index = this._intersectArray.indexOf(element);
    if (index > -1) {
      this._intersectArray.splice(index, 1);
    }
  }

  intersectObject(options) {
    if (!options.raycaster) {
      this.$l.warn('Intersect object requires raycaster');
      return { intersects: false };
    }
    let intersects = options.raycaster.intersectObjects(this._intersectArray);
    return {
      intersects: !!intersects.length,
      effective: !!this._data.action,
      action: this._data.action || null,
    };
  }
  handleMove(mousePosition) {
    if (this._icon || this._label) {
      let meshPosition = new THREE.Vector3();
      meshPosition.setFromMatrixPosition(this._icon.$.matrixWorld);

      if (mousePosition.distanceTo(meshPosition) < Config.player.widgets.icon.size) {
        this._state.mouseOver = true;
      } else {
        this._state.mouseOver = false;
      }
    }
  }
  init() {
    if (!this._state.initialized) {
      this.updateTilt();
      this.updateRotationPosition(this._data.rotation);
      this.updateDistance();
      this._state.initialized = true;
    }
  }
  open(animate, context) {
    if (!animate) {
      this._state.animationProgress = this._state.totalAnimationTime;
      this._state.opened = true;
    }
    this._state.opening = true;
    this._state.closing = false;
    this.emit('open', { widgetId: this._data.id, context });
    const { action } = this._data;
    if ((!this.animated || !animate) && action && !this._editor) {
      this.runAction(action);
      this.close();
    }
  }
  close(animate) {
    if (!this._state.closed || !this._state.closing) {
      this.emit('close', {widgetId: this._data.id});
    }

    if (animate == false) {
      this._state.animationProgress = 0;
      this._state.closed = true;
    }
    this._state.opened = false;
    this._state.opening = false;
    this._state.closing = true;
  }
  animate() {
  }
  animateIcon(options) {
    if (this._icon && this._icon.iconMesh) {
      let delta = options.delta,
        progress = this._state.iconAnimationProgress,
        totalAnimationTime = Config.player.widgets.iconScaleAnimationTime,
        normalizedProgress,
        iconScale;

      if (delta < 0) {
        delta = 0;
      }
      progress += delta;

      if (progress >= totalAnimationTime) {
        progress = 0;
      }

      normalizedProgress = (+(progress / totalAnimationTime)).toFixed(2);

      iconScale = normalizedProgress * 0.4;
      if (normalizedProgress > 0.5) {
        iconScale = 0.4 - iconScale;
      }
      iconScale += 1;

      this._icon.iconMesh.scale.set(iconScale, iconScale, iconScale);
      this._state.iconAnimationProgress = progress;

      let backgroundProgress = this._state.backgroundAnimationProgress;

      if (this._state.mouseOver && !this._state.opened && !this._state.opening && !this._state.closing) {
        backgroundProgress += options.delta;
      } else {
        backgroundProgress -= options.delta;
      }

      if (this._state.backgroundAnimationProgress != backgroundProgress) {
        if (backgroundProgress > 0) {
          if (backgroundProgress > Config.player.widgets.transitionLabelAnimationTime) {
            backgroundProgress = Config.player.widgets.transitionLabelAnimationTime;
          }
        } else {
          backgroundProgress = 0;
        }

        this._icon.$.getObjectByName('WidgetIconBackground').material.opacity = ((backgroundProgress / Config.player.widgets.transitionLabelAnimationTime) * 0.6).toFixed(2);

        if (this._label) {
          this._label.$.getObjectByName('WidgetLabel').material.opacity = ((backgroundProgress / Config.player.widgets.transitionLabelAnimationTime)).toFixed(2);
        }

        this._state.backgroundAnimationProgress = backgroundProgress;
      }
    }
  }
  remove() {
    this.removeLabel();
    if (this._icon) {
      this._icon.remove();
    }
    if(this.clickTimeout) {
      this.clearClick();
    }
    while (this._disposable.length) {
      let el = this._disposable.shift();

      el.dispose();
    }
    this.$.remove(this._mesh);
  }
  isHighlighted() {
    return this.highlightMesh && this.highlightMesh.visible;
  }
  setHighlight(visible) {
    let isVisible = !!visible;

    if (this.highlightMesh) {
      this.highlightMesh.visible = isVisible;
    } else if (this._icon) {
      this._icon.isReady.then(() => {
        if (this.highlightMesh) {
          this.highlightMesh.visible = isVisible;
        }
      });
    }
  }
  runAction(action) {
    this.emit('action', action);
    if (action.type === 'openWidget' && action.widgetId !== this._data.id) {
      this.close(true);
    }
  }
}

