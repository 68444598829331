/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from '../Utils/EventsEmitter';
import { Config } from 'evr';
import Utils from 'Utils/Utils';
import Logger from 'Utils/Logger';
import VideoObject from './VideoObject';
import Link from './Link';
import PaginationButton from './PaginationButton';

const PAGINATION_BUTTON_SIZE = 48;
const PAGINATION_DOT_SIZE = 12;
const PAGINATION_DOT_OFFSET = 10;


export default class GalleryView extends EventsEmitter {
  constructor(props) {
    super(props);

    this._disposable = [];
    this._scaleFactor = Config.player.scaleFactor.view;

    const style = Utils.extend({
      font: Config.player.style.font,
      fontSize: Config.player.style.fontSize,
      margin: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        betweenItems: 0,
        betweenColumns: 0
      },
      borderRadius: 0,
      accentColor: '#ff0',
    },(props.style || {}));
    style.margin.top *= this._scaleFactor;
    style.margin.right *= this._scaleFactor;
    style.margin.left *= this._scaleFactor;
    style.margin.bottom *= this._scaleFactor;
    style.margin.betweenItems *= this._scaleFactor;
    style.margin.betweenColumns *= this._scaleFactor;
    style.borderRadius *= this._scaleFactor;

    this.$l = new Logger('View');
    this._canvas = Utils.$({element: 'canvas'});
    this._ctx = this._canvas.getContext('2d');
    this._fontFamily = style.font || Config.player.style.font;
    this._fontSize = (style.fontSize || Config.player.style.fontSize);
    this._videoProgress = -1; // -1 if it wasn't draw, 0-1 progress of the loader

    const width = Math.max(props.width || 2000, 1) * Config.player.ultimateMagicScaleFactor;
    this._width = width * 0.2 * this._scaleFactor + style.margin.left + style.margin.right;
    this._canvas.width = this._width;

    //Prepare for drawning
    const drawText = ({
      text,
      textAlign = 'center',
      textDecoration = style.decorations,
      font = this._fontFamily,
      fontSize = this._fontSize,
      color = style.color || '#ffffff',
    }, x, y, maxWidth) => {
      const scaledFontSize = (fontSize || this._fontSize) * this._scaleFactor * Config.player.ultimateMagicScaleFactor;
      const lineHeight = scaledFontSize * 1.2;
      const underlined = textDecoration.indexOf('underlined') > -1;
      const fontParams = [];
      if (textDecoration.indexOf('italic') > -1) {
        fontParams.push('italic');
      }
      if (textDecoration.indexOf('bold') > -1) {
        fontParams.push('bold');
      }
      fontParams.push(scaledFontSize + 'px');
      fontParams.push(font);

      this._ctx.textBaseline = "top";
      this._ctx.textAlign = textAlign;
      this._ctx.font = fontParams.join(' ');
      this._ctx.fillStyle = color;

      let targetX;
      switch (textAlign) {
      case 'center':
        targetX = x + maxWidth / 2;
        break;
      case 'left':
        targetX = x;
        break;
      case 'right':
        targetX = x + maxWidth;
        break;
      }

      return Utils.Canvas.wrapText({
        context: this._ctx,
        align: textAlign,
        color,
        text,
        lineHeight,
        underlined,
        maxWidth,
        x: targetX,
        y,
      });
    };
    const calcHeightAndDraw = () => {
      this.videoElement = null;
      this.buttons = [];
      const { columns, columnWeights } = props;
      let x = style.margin.left;
      let height = 1;
      if (columns) {
        const avaiableWidth = (this._width - style.margin.left - style.margin.right) - (style.margin.betweenColumns * (columns.length - 1));
        const columnWidths = Array.isArray(columnWeights)
          ? columnWeights.map(weight => weight * avaiableWidth)
          : columns.map(() => avaiableWidth / columns.length);
        columns.forEach((columnElements, i) => {
          const columnWidth = columnWidths[i];
          let y = style.margin.top;
          columnElements.forEach((element, j) => {
            if (j) {
              y += style.margin.betweenItems;
            }
            if (element.type === 'text') {
              y += drawText(element, x, y, columnWidth);
            } else if (element.type === 'image') {
              const { image, correction } = element;
              let height = element.height ? element.height * this._scaleFactor : 0;
              const ratio = element.ratio || image.width / image.height;
              let imageWidth = height ? height * ratio : columnWidth;
              let imageHeight = height || (imageWidth / ratio);
              if (imageWidth > columnWidth) {
                imageWidth = columnWidth;
                imageHeight = imageWidth / ratio;
              }
              const imageX = height ? x + (columnWidth - imageWidth) / 2 : x;
              const imageY = height ? y + (height - imageHeight) / 2 : y;
              this._ctx.drawImage(image, 0, 0, image.width, image.height, imageX, imageY, imageWidth, imageHeight);
              if (correction) {
                const imageData = this._ctx.getImageData(Math.round(imageX), Math.round(imageY), Math.round(imageWidth), Math.round(imageHeight));
                Utils.Canvas.useShadersOnImgData(imageData.data, correction);
                this._ctx.putImageData(imageData, Math.round(imageX), Math.round(imageY));
              }
              y += height || imageHeight;
            } else if (element.type === 'video') {
              const { ratio } = element;
              let height = element.height ? element.height * this._scaleFactor : 0;
              let videoWidth = height ? height * ratio : columnWidth;
              let videoHeight = height || (videoWidth / ratio);
              if (videoWidth > columnWidth) {
                videoWidth = columnWidth;
                videoHeight = videoWidth / ratio;
              }
              this.videoX = height ? x + (columnWidth - videoWidth) / 2 : x;
              this.videoY = height ? y + (height - videoHeight) / 2 : y;
              this.videoWidth = videoWidth;
              this.videoHeight = videoHeight;
              this.videoElement = element;
              y += (height || videoHeight) + 4;
            } else if (element.type === 'button') {
              const button = new Link({
                x, y, columnWidth,
                align: element.align || 'center',
                canvas: this._canvas,
                text: element.action.text,
                font: element.font || this._fontFamily,
                fontSize: element.fontSize || this._fontSize,
                color: element.color || '#fff',
                secondaryColor: element.secondaryColor,
                backgroundColor: element.backgroundColor || style.background,
                variant: element.variant,
                action: element.action,
              });
              this.buttons.push(button);
              y += button.height;
            } else if (element.type !== 'pagination') {
              this.$l.error('Unknown element type: ' + element.type);
            }
          });
          x += columnWidth + style.margin.betweenColumns;
          const columnHeight = y + style.margin.bottom;
          if (columnHeight > height) {
            height = columnHeight;
          }
        });
      }
      if (props.pagination) {
        const { pagination } = props;
        const y = Math.max(height, props.height);
        let x = (this._width - PAGINATION_DOT_SIZE * pagination.slides.length - PAGINATION_DOT_OFFSET * (pagination.slides.length - 1)) / 2;
        pagination.slides.forEach((slide, i) => this.buttons.push(new PaginationButton({
          x: x + (i - (i > pagination.slideIndex ? 0 : 0.5)) * PAGINATION_DOT_SIZE + i * PAGINATION_DOT_OFFSET,
          y: y + PAGINATION_BUTTON_SIZE / 2 - PAGINATION_DOT_SIZE * (i === pagination.slideIndex ? 1.5 : 1) / 2,
          width: PAGINATION_DOT_SIZE * (i === pagination.slideIndex ? 1.5 : 1),
          height: PAGINATION_DOT_SIZE * (i === pagination.slideIndex ? 1.5 : 1),
          color: i === pagination.slideIndex ? style.accentColor : style.color,
          backgroundColor: style.background,
          canvas: this._canvas,
          action: {
            type: 'openWidget',
            widgetId: pagination.widgetId,
            context: { slideId: slide.id },
          },
        })));
        this._ctx.fillStyle = style.color;
        if (pagination.previousId) {
          this.buttons.push(new PaginationButton({
            x: style.margin.left, y,
            width: PAGINATION_BUTTON_SIZE,
            height: PAGINATION_BUTTON_SIZE,
            color: style.color,
            backgroundColor: style.background,
            image: pagination.arrows[0],
            canvas: this._canvas,
            action: {
              type: 'openWidget',
              widgetId: pagination.widgetId,
              context: { slideId: pagination.previousId },
            },
          }));
        }
        if (pagination.nextId) {
          this.buttons.push(new PaginationButton({
            x: this._width - style.margin.right - PAGINATION_BUTTON_SIZE, y,
            width: PAGINATION_BUTTON_SIZE,
            height: PAGINATION_BUTTON_SIZE,
            color: style.color,
            backgroundColor: style.background,
            image: pagination.arrows[1],
            canvas: this._canvas,
            action: {
              type: 'openWidget',
              widgetId: pagination.widgetId,
              context: { slideId: pagination.nextId },
            },
          }));
        }
        height = y + PAGINATION_BUTTON_SIZE;
      }
      return height + style.margin.bottom;
    };

    let height = calcHeightAndDraw();
    this._height = height;
    this._canvas.height = this._height;

    // Draw elements
    if (style.background) {
      this._ctx.fillStyle = style.background;
      Utils.Canvas.roundedPath(
        this._ctx,
        0, // x
        0, // y
        this._canvas.width, // width
        this._canvas.height, // height
        style.borderRadius // r
      );
      this._ctx.fill();
    }

    calcHeightAndDraw();

    this._geometry = new THREE.PlaneGeometry(this._width / this._scaleFactor, this._height / this._scaleFactor);
    this._texture = new THREE.Texture(this._canvas);
    this._material = new THREE.MeshBasicMaterial({
      map: this._texture,
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false,
      side: THREE.DoubleSide,
    });
    this._disposable.push(this._geometry);
    this._disposable.push(this._texture);
    this._disposable.push(this._material);

    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.name = "View";
    this._texture.needsUpdate = true;

    if (this.videoElement) {
      const { color, video } = this.videoElement;
      this.videoObject = new VideoObject(video, this.videoWidth / this._scaleFactor, this.videoHeight / this._scaleFactor, color);
      const videoYOffset = -((this.videoHeight / 2) - (this._height / 2) + this.videoY) / this._scaleFactor;
      const videoXOffset = ((this.videoWidth /2) - (this._width / 2) + this.videoX) / this._scaleFactor;
      this.videoObject.$.position.set(videoXOffset, videoYOffset, 0);
      this._mesh.add(this.videoObject.$);
    }

    this.buttons.forEach((button) => {
      const buttonYOffset = -(button.y + (button.height / 2) - (this._height / 2)) / this._scaleFactor;
      const buttonXOffset = (button.x  + (button.width / 2) - (this._width / 2)) / this._scaleFactor;
      button.$.position.set(buttonXOffset, buttonYOffset, 0);
      this._mesh.add(button.$);
      button.draw();
    });
  }
  stopVideo () {
    if (!this.videoObject) return;
    this.videoObject.stop();
  }
  remove() {
    while (this._disposable.length) {
      let el = this._disposable.shift();
      el.dispose();
    }
    if (this.videoObject) {
      this.videoObject.remove();
      delete this.videoObject;
    }
    this.buttons.forEach(button => button.remove());
    delete this.buttons;
    this._canvas.width = 0;
    this._canvas.height = 0;
    delete this._canvas;
  }
}

