/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import {Controller} from './Controller';
import Utils from 'Utils/Utils';

/**
 * Class representing a viewing client controller.
 * It controlls player in two modes: 'guide' and 'follow'.
 * In 'guide' mode controls are disabled.
 * In 'follow' mode controls works normally.
 * The mode is controller by the stream host.
 *
 * @memberof  Viewing
 *
 * @example
 * //Prepare player
 * this.player.instance.load(projectData);
 * var presentationController = new evr.Viewing.Client({
 *   player: this.player.instance
 * });
 * presentationController.setConfig({
 *   socketHost: 'https://gamma.evryplace.com:3001',
 * });
 * presentationController.setName('Forfiter');
 * presentationController.on('hostConnected', function() {
 *  console.log('host connected');
 * });
 * presentationController.connect({
 *   hash: '123abc',
 * });
 *
 * @extends EventsEmitter
 * @emits Viewing.Client#hostConnected
 * @emits Viewing.Client#hostDisconnected
 */
class Client extends EventsEmitter {
  /**
   * Create viewing presentation controller instance
   *
   * @param {Object} options
   * @param {Player} options.player - player instance
   *
   * @emits Player#changeScene
   */
  constructor({ player, debug = false }) {
    super();

    if (!player) {
      throw new Error('Missing player parameter');
    }

    this.$l = new evr.Logger('Viewing.Client');
    this.player = player;
    this.controller = null;
    this.localMedia = null;
    this.vr = null;
    this.debug = debug;

    Utils.Browser.vr().then(vr => this.vr = vr);

    this.toggleMic = this.toggleMic.bind(this);
    this.toggleSpeaker = this.toggleSpeaker.bind(this);

    this._onChangeMode = this._onChangeMode.bind(this);
    this._packViewportData = this._packViewportData.bind(this);
    this._onPlayerCameraRotate = this._onPlayerCameraRotate.bind(this);
    this._onPlayerCameraViewportChanged = this._onPlayerCameraViewportChanged.bind(this);
    this._onPlayerChangeScene = this._onPlayerChangeScene.bind(this);
    this._onPlayerOpenWidget = this._onPlayerOpenWidget.bind(this);
    this._onPlayerCloseWidget = this._onPlayerCloseWidget.bind(this);
    this._onControllerConnection = this._onControllerConnection.bind(this);
    this._onControllerConnected = this._onControllerConnected.bind(this);
    this._onControllerDisconnection = this._onControllerDisconnection.bind(this);
    this._onControllerDisconnected = this._onControllerDisconnected.bind(this);
    this._onControllerStreamAdded = this._onControllerStreamAdded.bind(this);
    this._onControllerStreamRemoved = this._onControllerStreamRemoved.bind(this);
    this._onControllerPeerDisconnected = this._onControllerPeerDisconnected.bind(this);
    this._onControllerData = this._onControllerData.bind(this);
    this._onControllerPeerRemoved = this._onControllerPeerRemoved.bind(this);

    if (this.debug) {
      const clientEvents = [
        'audioConnected', 'audioDisconnected', 'hostConnected', 'presentationChanged',
        'hostDisconnected', 'micAccessFailed', 'modeChanged', 'peerMicMuted'
      ];
      clientEvents.forEach(event => {
        this.on(event, (data) => { console.log('[Client]:', event, data);});
      });
    }
  }
  /**
   * Starts viewing with current player and config.
   *
   * @param {Object} options
   * @param {String} options.hash - stream id
   */
  connect({hash}) {
    const config = this.config,
      player = this.player;

    if (this.controller) {
      this.$l.error('Viewing already connected');
      return false;
    }

    this._mode = 'guide';

    if (!config) {
      console.error("config is undefined. Use setConfig() first.");
      return;
    }
    if (!hash) {
      console.error("Undefined viewing hash");
      return;
    }
    if (!config.socketHost) {
      console.error("config.socketHost is undefined. Use setConfig() first.");
      return;
    }
    if (!player) {
      console.error("config.socketHost is undefined. Use setConfig() first.");
      return;
    }

    this.controller = new Controller({
      socket: {
        server: config.socketHost
      },
      hash: hash,
      client: true
    });

    this.player._ui.$.appendChild(this.controller.$audio);

    this.player.on('cameraRotate', this._onPlayerCameraRotate);
    this.player.on('cameraViewportChanged', this._onPlayerCameraViewportChanged);
    this.player.on('changeScene', this._onPlayerChangeScene);
    this.player.on('openWidget', this._onPlayerOpenWidget);
    this.player.on('closeWidget', this._onPlayerCloseWidget);
    // when data channel is open
    this.controller.on('connection', this._onControllerConnection);
    this.controller.on('connected', this._onControllerConnected);
    // when room is closed, you lost connection, your host/client lost connection
    this.controller.on('disconnection', this._onControllerDisconnection);
    this.controller.on('disconnected', this._onControllerDisconnected);
    // send voice stream when host starts voice call
    this.controller.on('streamAdded', this._onControllerStreamAdded);
    // stop sending stream when host ends voice call or disconnects from channel
    this.controller.on('streamRemoved', this._onControllerStreamRemoved);
    this.controller.on('peerDisconnected', this._onControllerPeerDisconnected);
    this.controller.on('data', this._onControllerData);
    // Creates stream with given hash
    this.controller.connect();
    // Send - sends json to saved stream.
    this.controller.send({msg: 'client says hi'});
    this.controller.on('peerRemoved', this._onControllerPeerRemoved);
    this._onChangeMode();

    this._onOnline = () => {
      if (this.debug) {
        console.log("online");
      }
      window.removeEventListener('online', this._onOnline);
      this.connect({ hash });
    };

    this._onOffline = () => {
      if (this.debug) {
        console.log("offline");
      }
      this.disconnect();
      window.addEventListener('online', this._onOnline);
    };

    window.addEventListener('offline', this._onOffline);
  }
  /**
   * Stops viewing
   */
  disconnect() {
    this.controller.disconnect();
    this.player.off('cameraRotate', this._onPlayerCameraRotate);
    this.player.off('cameraViewportChanged', this._onPlayerCameraViewportChanged);
    this.player.off('changeScene', this._onPlayerChangeScene);
    this.player.off('openWidget', this._onPlayerOpenWidget);
    this.player.off('closeWidget', this._onPlayerCloseWidget);
    this.controller.off('connection', this._onControllerConnection);
    this.controller.off('connected', this._onControllerConnected);
    this.controller.off('disconnection', this._onControllerDisconnection);
    this.controller.off('disconnected', this._onControllerDisconnected);
    this.controller.off('streamAdded', this._onControllerStreamAdded);
    this.controller.off('streamRemoved', this._onControllerStreamRemoved);
    this.controller.off('peerDisconnected', this._onControllerPeerDisconnected);
    this.controller.off('data', this._onControllerData);
    this.controller.off('peerRemoved', this._onControllerPeerRemoved);
    this.controller = null;
    window.removeEventListener('offline', this._onOffline);
  }
  /**
   * Set client options
   *
   * @param {Object} options
   * @param {String} options.socketHost - socket host
   */
  setConfig(options) {
    this.config = options;
  }
  /**
   * Set client name
   *
   * @param {String} name
   */
  setName(name) {
    this.name = name;
  }
  /**
   * Toggle microphone
   */
  toggleMic() {
    if (this.localMedia) {
      this.micMuted = !this.micMuted;
      this.localMedia.enable('audio', !this.micMuted);
      this.controller.send({c: [{t: this.controller.commands.showMicMuted, d: this.micMuted}]});
    }
  }
  /**
   * Toggle speakers
   */
  toggleSpeaker() {
    if (this.controller.$audio) {
      this.speakerMuted = !this.speakerMuted;
      this.controller.$audio.muted = this.speakerMuted;
    }
  }
  /**
   * Set watching true/false - sends watching value to host.
   * It's used only on android apps.
   */
  setWatching(value) {
    this.controller.send({c: [{t: this.controller.commands.setWatching, d: value}]});
  }
  /** Player and controller event handlers */
  _onChangeMode() {
    if (this._mode === this.controller.modes.guide) {
      this.player.disableWidgets();
    }
    if (this._mode === this.controller.modes.follow) {
      this.player.enableWidgets();
    }
  }
  _packViewportData(viewportData) {
    var fields = ['lt', 'lb', 'rt', 'rb', 'c'];
    var valueKeys = ['x', 'y', 'z'];
    var arr = [];
    for (var i = 0; i < fields.length; i++) {
      for (var j = 0; j < valueKeys.length; j++) {
        var value = viewportData[fields[i]][valueKeys[j]];
        arr.push(this.controller.packNormFloat(value));
      }
    }
    return arr.join(';');
  }
  _onPlayerCameraRotate(data) {
    this.controller.send({c: [{t: this.controller.commands.rotateCamera, d: data}]});
  }
  _onPlayerCameraViewportChanged(data) {
    this.controller.send({c: [{t: this.controller.commands.cameraViewportChanged, d: this._packViewportData(data)}]});
  }
  _onPlayerChangeScene(data) {
    if (this._mode === this.controller.modes.follow) {
      this.controller.send({c: [{t: this.controller.commands.changeScene, d: data}]});
    }
  }
  _onPlayerOpenWidget(data) {
    if (this._mode === this.controller.modes.follow) {
      this.controller.send({c: [{t: this.controller.commands.openWidget, d: data.widgetId, c: data.options && data.options.context}]});
    }
  }
  _onPlayerCloseWidget(data) {
    if (this._mode === this.controller.modes.follow) {
      this.controller.send({c: [{t: this.controller.commands.closeWidget, d: data.widgetId}]});
    }
  }
  _onControllerConnection() {
    if (this.name) {
      this.controller.send({
        name: this.name,
      });
    }
  }
  _onControllerConnected() {
    this.controller.send({
      c: [
        {t: this.controller.commands.cameraViewportChanged, d: this._packViewportData(this.player._stage.getCameraViewport()) }
      ],
      name: this.name,
    });
    this.emit('hostConnected', {});
  }
  _onControllerDisconnection(e) {
    this.emit('hostDisconnected', e);
    this._onControllerStreamRemoved();
  }
  _onControllerDisconnected(e) {
    this._onControllerDisconnection(e);
  }
  _onControllerStreamAdded(e) {
    e.client.connection.localMedia.start({audio: true})
      .then(media =>{
        this.localMedia = media;
        this.controller.$audio.srcObject = e.stream;
        this.emit('audioConnected', e);
      }, err => {
        this.emit('micAccessFailed', err);
        //TODO: disconnect (?)
        // We are unable to use the mediaDevices API due to the requirement that the page must be loaded with https
        // and there is no possibility to override this requirement on iOS - at least in iOS 14.3 or lower.
        // See https://bugs.webkit.org/show_bug.cgi?id=208667
      });
  }
  _onControllerStreamRemoved(e) {
    if (this.localMedia) {
      this.localMedia.stop();
      this.localMedia = null;
      this.emit('audioDisconnected', e);
    }
  }
  _onControllerPeerDisconnected(peer) {
    if (peer.connection) {
      peer.connection.localMedia.stop();
    }
  }
  _onControllerData(payload) {
    var data = payload.data;
    if (data && data.c && Array.isArray(data.c)) {
      for (var i = 0; i < data.c.length; i++) {
        var command = data.c[i];
        var type = command.t;
        if (type === this.controller.commands.reload) {
          var name = '';
          this.emit('presentationChanged', name);
        }
        if (type === this.controller.commands.showPlumker) {
          this.player.addPointer(command.d);
        }
        else if (type === this.controller.commands.pointerPosition) {
          const arr = command.d.split(';');
          const pos = {
            x: this.controller.unpackNormFloat(arr[0]),
            y: this.controller.unpackNormFloat(arr[1]),
            z: this.controller.unpackNormFloat(arr[2])
          };
          this.player.moveLaserPointer(pos);
        }
        else if (type === this.controller.commands.closePointer) {
          this.player.closeLaserPointer();
        }
        else if (type === this.controller.commands.addLineSegment) {
          const arr = command.d.split(';');
          const pos = {
            x: this.controller.unpackNormFloat(arr[0]),
            y: this.controller.unpackNormFloat(arr[1]),
            z: this.controller.unpackNormFloat(arr[2])
          };
          this.player.addLineSegment(pos);
        }
        else if (type === this.controller.commands.closeLine) {
          this.player.closeLine();
        }
        else if (type === this.controller.commands.clearAllLines) {
          this.player.clearAllLines();
        }
        else if (type === this.controller.commands.changeMode) {
          this._mode = command.d;
          this._onChangeMode();
          this.emit('modeChanged', command.d);
        }
        else if (type === this.controller.commands.openWidget) {
          this.player.openWidget({widgetId: command.d, animating: true, context: command.c});
        }
        else if (type === this.controller.commands.closeWidget) {
          this.player.closeWidget({widgetId: command.d, animating: true});
        }
        else if (type === this.controller.commands.changeScene) {
          this.player.changeScene(command.d);
        }
        else if(type === this.controller.commands.showMicMuted){
          this.emit('peerMicMuted', command.d);
        }
        else if (type === this.controller.commands.cameraViewportChanged) {
          this.player.isGyroscopeEnabled().then(function (gyroscopeEnabled) {
            if (this._mode === this.controller.modes.guide && !(gyroscopeEnabled || (this.vr && !Utils.Browser.mobile))) {
              var viewport = this.controller.unpackViewportData(command.d);
              var cameraDirection = viewport.c;
              var angleY = Math.atan2(-cameraDirection.x, -cameraDirection.z);
              var cameraRotation = new THREE.Vector3(-Math.asin(cameraDirection.y) * 180.0 / Math.PI, angleY * 180.0 / Math.PI, 0);
              this.player.rotateCamera({rotation: cameraRotation});
            }
          }.bind(this));
        }
        else if (type === this.controller.commands.setDrawings) {
          this.player.setDrawings(command.d);
        }
        else if (type === this.controller.commands.openLinkStart) {
          this.player.openCurrentLink();
        }
        else if (type === this.controller.commands.openLinkEnd) {
          this.player.closeCurrentLink();
        }
      }
    }
  }

  _onControllerPeerRemoved(event) {
    this.emit('peerRemoved', event);
  }
}

/**
 * Host connected to socket server.
 *
 * @event Viewing.Client#hostConnected
 * @type {object}
 */

/**
 * Host disconnected to socket server.
 *
 * @event Viewing.Client#hostDisconnected
 * @type {object}
 */

/**
 * Audio connection to host opened.
 *
 * @event Viewing.Client#audioConnected
 * @type {object}
 */

/**
 * Audio connection to host closed.
 *
 * @event Viewing.Client#audioDisconnected
 * @type {object}
 */

/**
 * Peer removed from presentation
 *
 * @event Viewing.Host#peerRemoved
 * @type {object}
 */

export default Client;

