/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

const retry = function (fun, tries, timeout, options) {
  const { onSuccess, onError } = options || {};
  return new Promise((retryResolve, retryReject) => {
    const wait = (time) => new Promise(resolve => setTimeout(resolve, time || 0));
    const retryLoad = (fn, count, delay) => fn().then(data => {
      retryResolve(data);
      if (onSuccess) {
        onSuccess(data);
      }
    }).catch(err => {
      if (count > 0) {
        wait(delay).then(() => retryLoad(fn,count - 1, delay));
      } else {
        retryReject(err);
        if (onError) {
          onError(err);
        }
      }
    });

    retryLoad(fun, tries, timeout);
  });
};

export default retry;

