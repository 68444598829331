/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

export default class Speaker {
  constructor() {
    // Search for voices: Alex (OSX), David (Windows), any 'en', else take first.
    const preferredVoices = ['Alex', 'Microsoft David - English (United States)'],
      setVoice = () => {
        const voices = this._speechSynthesis.getVoices();
    
        this._voice = voices.find(
          voice => preferredVoices.includes(voice.name)
        ) || voices.find(
          voice => voice.lang.includes('en')
        );
      };
    
    if (!window.SpeechSynthesisUtterance || !window.speechSynthesis) {
      throw new Error('Speech Synthesis is not supported.');
    }
    
    this._SpeechSynthesisUtterance = window.SpeechSynthesisUtterance;
    this._speechSynthesis = window.speechSynthesis;
    this._voice = null;
    this._pitch = .8;
    this._rate = 0.9;
    this._volume = 1;
    this._canceled = false;
    this._message = null;
    this._speechSynthesis.volume = this._volume;
    setVoice();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = setVoice;
    }
  }
  _splitTextIntoLines (text) {
    const MAX_ROW_LENGTH = 100;

    let words = text.split(' '),
      lines = [''],
      lastLineIndex,
      lastLineLength,
      wordLength;

    words.forEach(word => {
      wordLength = word.length;

      lastLineIndex = lines.length - 1;
      lastLineLength = lines[lastLineIndex].length;

      if (lastLineLength + wordLength <= MAX_ROW_LENGTH) {
        lines[lastLineIndex] += ` ${word}`;
      } else {
        lines.push(word);
      }
    });

    return(lines);
  }
  _waitForSpeechEnd (callback) {
    if (this._canceled) return;

    if (!this._speechSynthesis.speaking) {
      callback();
      return;
    }

    setTimeout(this._waitForSpeechEnd.bind(this, callback), 0);
  }
  _speakLine(text) {
    let message = new this._SpeechSynthesisUtterance();

    message.text = text;
    message.pitch = this._pitch;
    message.rate = this._rate;
    message.voice = this._voice;

    this._speechSynthesis.speak(message);
    this._message = message;
  }
  speak(text) {
    const textSplitted = Array.isArray(text) ? text : this._splitTextIntoLines(text);
    this._canceled = false;

    if (!this._voice) { return; }

    this._speakLine(textSplitted.shift());

    this._waitForSpeechEnd(() => {
      if (textSplitted.length) {
        this.speak(textSplitted);
      }
    });
  }
  cancel() {
    this._canceled = true;
    this._speechSynthesis.cancel();
  }
}

