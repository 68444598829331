/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from '../Utils/EventsEmitter';
import { Config } from 'evr';
import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory.js";

export default class VRControls extends EventsEmitter {
  constructor( { renderer, scene } ) {
    super();

    this._renderer = renderer;
    this.onSelectStart = this.onSelectStart.bind(this);
    this.onSelectEnd = this.onSelectEnd.bind(this);
    this.$ = new THREE.Group();

    this._controller1 = this._renderer.xr.getController( 0 );
    this._controller1.addEventListener( 'selectstart', this.onSelectStart );
    this._controller1.addEventListener( 'selectend', this.onSelectEnd );
    this.$.add( this._controller1 );

    this._controller2 = this._renderer.xr.getController( 1 );
    this.$.add( this._controller2 );

    const controllerModelFactory = new XRControllerModelFactory(null, onModalAdded.bind(this));

    this._controllerGrip1 = this._renderer.xr.getControllerGrip( 0 );
    this._controllerGrip1.add( controllerModelFactory.createControllerModel( this._controllerGrip1 ) );
    this.$.add( this._controllerGrip1 );

    this._controllerGrip2 = this._renderer.xr.getControllerGrip( 1 );
    this._controllerGrip2.add( controllerModelFactory.createControllerModel( this._controllerGrip2 ) );
    this.$.add( this._controllerGrip2 );

    // const geometry = new THREE.BufferGeometry().setFromPoints( [ new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, - 1 ) ] );
    const lineLength = 12;
    const geometry = new THREE.CylinderGeometry(.003, .002, lineLength, 12);
    const arrow = new THREE.ArrowHelper(new THREE.Vector3(0, 0, - 1), new THREE.Vector3(0, 0, 0));
    const material1 = new THREE.MeshBasicMaterial({
      color: 0xffff00,
      opacity: 0.5,
      transparent: true,
    });
    const line1 = new THREE.Mesh( geometry.clone(), material1.clone() );
    line1.rotation.copy(arrow.rotation);
    line1.name = 'TargetRay1';
    line1.position.z = -.01 - lineLength / 2;
    const line2 = new THREE.Mesh( geometry.clone(), material1.clone() );
    line2.rotation.copy(arrow.rotation);
    line2.name = 'TargetRay2';
    line2.position.z = -.01 - lineLength / 2;

    this._controller1.add(line1);
    this._controller2.add(line2);

    const light = new THREE.PointLight(0xffffff, 20, 100);
    light.position.set(0, 10, 0);
    this.$.add(light);

    let order = 1;
    function setOrder(obj) {
      obj.renderOrder = 9999999 + (order++);

      obj.traverse(o => {
        if (o.material) {
          o.material.transparent = true;
          o.material.needsUpdate = true;
        }
        o.renderOrder = 2000
      })
    }

    function onModalAdded() {
      setOrder(this._controller1);
      setOrder(this._controller2);
      setOrder(this._controllerGrip1);
      setOrder(this._controllerGrip2);
      setOrder(line1);
      setOrder(line2);
    }
  }
  init({ vrSession }) {
    this._vrSession = vrSession;
  }
  getController() {
    if (!this._controller1) return;

    if (this._controller1.visible) {
      return this._controller1;
    } else if (this._controller2.visible) {
      return this._controller2;
    }
  }
  onSelectStart(event) {
    const controller = event.target;

    if (controller) {
      const params = { controller };

      this.emit('controllerDown', params);
    }
  }
  onSelectEnd(event) {
    const controller = event.target;

    if (controller) {
      const params = { controller };

      this.emit('controllerUp', params);
    }
  }
}

