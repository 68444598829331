/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import { Config } from 'evr';

class PaginationButton {
  constructor(props) {
    const {
      canvas,
      x, y,
      image,
      color,
      backgroundColor,
      action,
      width = 128,
      height = 128,
    } = props;

    this.canvas = canvas;
    this.color = color;
    this.backgroundColor = backgroundColor;
    this.image = image;
    this.x = x;
    this.y = y;
    this._disposable = [];
    this._scaleFactor = Config.player.scaleFactor.view * Config.player.ultimateMagicScaleFactor;
    this.action = action;

    this._ctx = canvas.getContext('2d');
    this.width = width;
    this.height = height;

    this._geometry = new THREE.PlaneGeometry(this.width / this._scaleFactor, this.height / this._scaleFactor);
    this._material = new THREE.MeshBasicMaterial({
      color: '#000',
      transparent: true,
      opacity: 0,
      depthTest: false,
      depthWrite: false,
    });
    this._disposable.push(this._geometry);
    this._disposable.push(this._material);

    this.$ = new THREE.Mesh(this._geometry, this._material);
    this.$.name = 'Link';
    if (action) {
      this.$.action = action;
    }
  }
  draw() {
    this._ctx.fillStyle = this.color;
    if (this.image) {
      this._ctx.clearRect(this.x, this.y, this.width, this.height);
      this._ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
      this._ctx.globalCompositeOperation = 'source-atop';
      this._ctx.fillRect(this.x, this.y, this.width, this.height);
      this._ctx.globalCompositeOperation = 'destination-over';
      this._ctx.fillStyle = this.backgroundColor;
      this._ctx.fillRect(this.x, this.y, this.width, this.height);
    } else {
      this._ctx.beginPath();
      this._ctx.ellipse(this.x + this.width / 2, this.y + this.width / 2, this.width / 2, this.height / 2, Math.PI / 4, 0, 2 * Math.PI);
      this._ctx.fill();
    }
  }
  remove() {
    while (this._disposable.length) {
      let el = this._disposable.shift();

      el.dispose();
    }
    this.canvas.width = 0;
    this.canvas.height = 0;
    delete this.canvas;
  }
}

export default PaginationButton;

