/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

export default class Logger {
  constructor(className) {
    this._className = className;
  }
  error(msg = '') {
    console.error('[' + this._className + ']: ', msg);
  }
  log(msg = '', msg2 = '') {
    console.warn('[' + this._className + ']: ', msg, msg2);
  }
  warn(msg = '') {
    console.warn('[' + this._className + ']: ', msg);
  }
}

