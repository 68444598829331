/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './../Utils/EventsEmitter';
import Utils from 'Utils/Utils.js';
import _Logger from "../Utils/Logger";

export default class Spaceplans extends EventsEmitter {
  constructor(ui, containerParent) {
    super();
    this.ui = ui;
    this.containerParent = containerParent;
    this.$ = Utils.$({cssClass: 'ui-spaceplans'});
    this.$l = new _Logger('Spaceplans');
    this._state = {
      sceneId: null,
      sceneName: null,
      spaceplanIndex: 0,
      spaceplanName: ''
    };
    this.spaceplanContainer = Utils.$({cssClass: 'spaceplans-content'});
    this.menuContainer = Utils.$({cssClass: 'spaceplans-menu'});
    this.xButton = Utils.$({cssClass: 'spaceplans-close'});

    this.$.appendChild(this.spaceplanContainer);
    this.$.appendChild(this.menuContainer);
    this.$.appendChild(this.xButton);
    this.containerParent.appendChild(this.$);

    this.xButton.addEventListener('click', () => {
      this.hide();
    });

    this.ui.on('resize', () => {
      this.updateSpaceplan();
    });
  }
  _updateDescription(newDescription) {
    const descriptionContainer = this.$.querySelector('.spaceplans-description');

    if (!descriptionContainer) return;

    descriptionContainer.innerHTML = newDescription || (this._state.spaceplanName + ' / ' + this._state.sceneName);
  }
  update(spaceplans, resources, sceneId, sceneName) {
    this._state.sceneId = sceneId;
    this._state.sceneName = sceneName;
    this.menuContainer.innerHTML = '';
    this.spaceplanContainer.innerHTML = '';
    this._resources = resources;
    this._spaceplans = spaceplans;

    if(this._spaceplans.length > 3) {
      this.menuContainer.style.height = '80%';
      this.menuContainer.style.overflowY = 'scroll';
    }

    for(let i = 0; i < spaceplans.length; i++) {
      let menuItem = Utils.$({cssClass: 'menu-item'});
      this._spaceplans[i].$ = menuItem;

      if (resources[spaceplans[i].resourceId] && resources[spaceplans[i].resourceId].data) {
        let menuBackground = Utils.$({cssClass: 'item-background'});
        menuBackground.appendChild(resources[spaceplans[i].resourceId].data.cloneNode(true));
        menuItem.appendChild(menuBackground);
      }

      let menuName = Utils.$({cssClass: 'item-name', innerHTML: spaceplans[i].name});
      menuItem.appendChild(menuName);

      menuItem.addEventListener('click', function(i) {
        return function(e) {
          this.updateSpaceplan(i);
          e.preventDefault();
          e.stopPropagation();

          return false;
        }.bind(this);
      }.bind(this)(i));

      this.menuContainer.appendChild(menuItem);
    }

    this._state.spaceplanName = this._spaceplans[0].name;
    this.updateSpaceplan();
  }
  updateSpaceplan(newIndex) {
    let data,
      titleContainer,
      descriptionContainer,
      sceneContainer,
      background,
      width,
      height,
      marginTop,
      marginLeft,
      backgroundWidth,
      backgroundHeight;

    if (!this._spaceplans) return;
    if (!isNaN(newIndex) && this._state.spaceplanIndex !== newIndex) {
      this._spaceplans[this._state.spaceplanIndex].$.classList.remove('isSelected');
      this._state.spaceplanIndex = newIndex;
    }

    this.spaceplanContainer.innerHTML = '';
    data = this._spaceplans[this._state.spaceplanIndex];

    if (!data.$.querySelector('.item-background img')) {
      this.$l.warn('Invalid spaceplan images.');
      return;
    }

    titleContainer = Utils.$({cssClass: 'spaceplans-title'});
    titleContainer.innerHTML = data.name;
    this.spaceplanContainer.appendChild(titleContainer);

    descriptionContainer = Utils.$({cssClass: 'spaceplans-description'});
    this.spaceplanContainer.appendChild(descriptionContainer);

    this._updateDescription();

    sceneContainer = Utils.$({cssClass: 'spaceplans-scene'});
    this.spaceplanContainer.appendChild(sceneContainer);

    data.$.classList.add('isSelected');
    background = data.$.querySelector('.item-background img').cloneNode(true);
    background.classList.add('spaceplans-background');

    width = this.spaceplanContainer.clientWidth;
    height = this.spaceplanContainer.clientHeight;
    marginTop = 0;
    marginLeft = 0;

    if ((width/height) > (data.width/data.height)) {
      backgroundWidth = height * (data.width/data.height);
      backgroundHeight = height;
      marginLeft = (width - backgroundWidth) / 2;
    } else {
      backgroundWidth = width;
      backgroundHeight = width * (data.height / data.width);
      marginTop = (height - backgroundHeight) / 2;
    }
    background.style.width = backgroundWidth + 'px';
    background.style.height = backgroundHeight + 'px';
    this.spaceplanContainer.appendChild(background);

    for (let i = 0; i < data.points.length; i++) {
      let pin = Utils.$({cssClass: 'spaceplans-pin'}),
        pinData = data.points[i],
        img;

      pin.style.left = pinData.x * backgroundWidth + marginLeft + 'px';
      pin.style.top = pinData.y * backgroundHeight + marginTop + 'px';

      if (pinData.hash === this._state.sceneId) { pin.classList.add('isSelected'); }

      if (this._resources[pinData.icon]) {
        img = this._resources[pinData.icon].data.cloneNode(true);
        pin.appendChild(img);
      }
      if (this._resources[pinData.icon + '_active']) {
        img = this._resources[pinData.icon + '_active'].data.cloneNode(true);
        img.classList.add('active');
        pin.appendChild(img);
      }

      pinData.$ = pin;

      pin.addEventListener('click', () => {
        this.emit('changeScene', pinData.hash);
      });

      pin.addEventListener('mouseover', () => {
        this._updateDescription(data.name + ' / ' + pinData.name);
        pin.classList.add('isSelected');
      });

      pin.addEventListener('mouseleave', () => {
        this._updateDescription();
        if (pinData.hash !== this._state.sceneId) {
          pin.classList.remove('isSelected');
        }
      });

      this.spaceplanContainer.appendChild(pin);
    }
  }
  setCurrentScene(sceneId, sceneName) {
    this._state.sceneId = sceneId;
    this._state.sceneName = sceneName;
    let newIndex;

    if (this._spaceplans) {
      for(let i = 0; i < this._spaceplans.length; i++) {
        for(let j = 0; j < this._spaceplans[i].points.length; j++) {
          if (this._spaceplans[i].points[j].hash == sceneId) {
            newIndex = i;
            break;
          }
        }
        if(newIndex) break;
      }
    }

    if (newIndex) {
      this._state.spaceplanName = this._spaceplans[newIndex].name;
    }
    this.updateSpaceplan(newIndex);
  }
  show() {
    if (!this._spaceplans || !this._spaceplans.length) return;

    this.$.classList.remove('isHidden');
    this.updateSpaceplan();
  }
  hide() {
    this.$.classList.add('isHidden');
  }
  remove() {
    this.ui.off('resize');
    this.containerParent.removeChild(this.$);
  }
}

