/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

class VideoObject {
  constructor(video, width, height, color) {
    this._disposable = [];

    this.texture = new THREE.VideoTexture(video);
    this.texture.minFilter = THREE.LinearFilter;
    this.texture.magFilter = THREE.LinearFilter;
    this.texture.format = THREE.RGBFormat;

    this.video = video;
    this.geometry = new THREE.PlaneGeometry(width, height);
    this.material = new THREE.MeshBasicMaterial({
      map: this.texture,
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false,
      side: THREE.DoubleSide,
    });
    this.mesh = new THREE.Mesh(this.geometry, this.material);

    this.progressGeometry = new THREE.PlaneGeometry(width, 1);
    this.progressMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 1,
      depthTest: true,
      depthWrite: true,
      side: THREE.DoubleSide,
      color
    });
    this.progressMesh = new THREE.Mesh(this.progressGeometry, this.progressMaterial);
    this.progressMesh.position.set(0, -height/2-0.5, 0);
    this.mesh.add(this.progressMesh);
    this.reportProgress = e => {
      const video = e.currentTarget;
      if(video.duration) {
        const progress = video.currentTime / video.duration;
        this.progressMesh.scale.set(progress, 1, 1);
        this.progressMesh.position.set(-width/2 + progress*width/2, -height/2-0.5, 0);
      }
    };
    video.addEventListener('timeupdate', this.reportProgress);

    this.$ = this.mesh;
    this.$.name = 'VideoObject';

    this._disposable.push(this.texture);
    this._disposable.push(this.geometry);
    this._disposable.push(this.material);
    this._disposable.push(this.progressGeometry);
    this._disposable.push(this.progressMaterial);
  }
  stop() {
    this.video.pause();
    this.video.currentTime = 0;
    this.texture.needsUpdate = false;
  }
  remove() {
    if (this.$ && this.$.parent) {
      this.$.parent.remove(this.$);
    }

    this.video.removeEventListener('timeupdate', this.reportProgress);

    while(this._disposable.length) {
      const disposable = this._disposable.pop();
      disposable.dispose();
    }

    delete this.video;
    delete this.$;
    delete this.geometry;
    delete this.material;
    delete this.texture;
    delete this.progressMesh;
    delete this.progressMaterial;
    delete this.progressGeometry;
    delete this.reportProgress;
  }
}

export default VideoObject;

