/*** IMPORTS FROM imports-loader ***/
var THREE = require("three");

import EventsEmitter from './EventsEmitter';

export default class LocationManager extends EventsEmitter {
	constructor(manageUrl) {
		super();

		this._manageUrl = manageUrl || false;

		window.onpopstate = function (event) {
			this.emit('popstate', event);
		}.bind(this);
	}
	replace(newState, newUrl) {
    history.replaceState(newState, "", newUrl || this.createUrlFromState(newState));
  }
  createUrlFromState(state) {
	  let newUrl = location.protocol + '//' + location.host + location.pathname + (location.search ? location.search : "");

		if (this._manageUrl && state.sceneId) {
      newUrl = "#"+state.sceneId;
		}

		return newUrl;
	}
	push(newState) {
    history.pushState(newState, "test " + newState.sceneId, this.createUrlFromState(newState));
  }
  getHash() {
	  return window.location.hash.slice(1) || undefined;
  }
  removeHash () {
    history.pushState("", document.title, window.location.pathname + window.location.search);
  }
}

